import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Logo from '../../assets/images/logo-white.png'
import useAuthContext from '../../hooks/useAuthContext'
import { useEffect } from 'react'

const Splash = () => {
  const { token } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (token) {
        navigate('/start', {
          replace: true
        })
      } else {
        navigate('/onboarding', {
          replace: true
        })
      }
    }, 2500)

    return () => clearTimeout(timer)
  }, [token])

  return (
    <div className="app-container">
      <section className="Splash">
        <motion.div
          className='d-flex flex-column justify-content-center align-items-center gap-3'
          initial={{ opacity: 0, scale: 0.75 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.25 }}
        >
          <img
            className="Splash__Logo"
            src={Logo}
            alt="Qaixa"
            title='Qaixa'
          />

          <span className="h5 text-white fw-normal">¡Bienvenido/a!</span>
        </motion.div>
      </section>
    </div>
  )
}

export default Splash