import { memo, SVGProps } from 'react'

const HidenPasswordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#10203E">
      <path d="m15.277 8-.636-.34c-.061.114-.124.227-.189.338a9.467 9.467 0 0 1 .176.317l.004.007.646-.322Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.775 3.568c.4-.094.811-.14 1.223-.14H8c2.027 0 3.67 1.16 4.849 2.419a12.298 12.298 0 0 1 1.603 2.151c.065-.111.128-.224.189-.338l.636.34.646-.324-.001-.002-.003-.004-.007-.015a12.358 12.358 0 0 0-.514-.873 13.74 13.74 0 0 0-1.496-1.922c-1.301-1.388-3.298-2.875-5.901-2.875a6.747 6.747 0 0 0-1.555.178.722.722 0 1 0 .329 1.405ZM8 1.985H8v.722L8 1.985ZM4.508 4.643a.722.722 0 0 0-.877-1.147A12.932 12.932 0 0 0 .086 7.658a.722.722 0 0 0-.01.665L.722 8c-.646.323-.645.323-.645.324v.001l.003.005.008.015a4.984 4.984 0 0 0 .127.236 13.739 13.739 0 0 0 1.882 2.56C3.4 12.527 5.396 14.014 8 14.013h.011a7.383 7.383 0 0 0 4.356-1.51.722.722 0 0 0-.875-1.148 5.94 5.94 0 0 1-3.498 1.215c-2.025-.002-3.665-1.16-4.843-2.418a12.298 12.298 0 0 1-1.604-2.151 11.489 11.489 0 0 1 2.96-3.359ZM7.083 7.13a.722.722 0 1 0-.975-1.064A2.707 2.707 0 1 0 9.93 9.894a.722.722 0 1 0-1.056-.984 1.264 1.264 0 1 1-1.79-1.78Z"
      />
      <path d="M14.452 7.998a11.505 11.505 0 0 1-1.156 1.647.722.722 0 1 0 1.104.93c.582-.69 1.089-1.44 1.514-2.235a.722.722 0 0 0 .01-.663L15.277 8l-.646.323-.004-.008a3.9 3.9 0 0 0-.102-.189 9.467 9.467 0 0 0-.074-.128Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.211.211a.722.722 0 0 1 1.021 0L15.79 14.768a.722.722 0 1 1-1.021 1.02L.21 1.233a.722.722 0 0 1 0-1.02Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(HidenPasswordIcon)