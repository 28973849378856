import { ReactNode, useState, memo } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import ArrowDownIcon from '../assets/icons/ArrowDownIcon'

interface Props {
  title: string
  className?: string
  children: ReactNode
}

const contentVariants = {
  open: { opacity: 1, height: 'auto', paddingBottom: '8px' },
  closed: { opacity: 0, height: 0, paddingBottom: 0 }
}

const arrowVariants = {
  open: { rotateX: 180 },
  closed: { rotateX: 0 }
}

const Dropdown = ({ title, className, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={classNames(className, 'Dropdown')}>
      <button className="Dropdown__Title" onClick={() => setIsOpen(!isOpen)}>
        <span className="text-1 fw-semibold">{ title }</span>
        <motion.div
          animate={isOpen ? 'open' : 'closed'}
          initial={arrowVariants.closed}
          variants={arrowVariants}
        >
          <ArrowDownIcon/>
        </motion.div>
      </button>

      <motion.div
        className="Dropdown__Content"
        animate={isOpen ? 'open' : 'closed'}
        initial={contentVariants.closed}
        variants={contentVariants}
      >
        { children }
      </motion.div>
    </div>
  )
}

export default memo(Dropdown)