import { SVGProps, memo } from 'react'

const CardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <mask
        id="mask0_67_15"
        style={{ maskType: 'luminance' }}
        width="16"
        height="12"
        x="0"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M16 2H0v12h16V2z"></path>
      </mask>
      <g fill="currentColor" mask="url(#mask0_67_15)">
        <path d="M7.985 14H1.83a1.798 1.798 0 01-1.349-.551A1.76 1.76 0 010 12.086V3.91a1.74 1.74 0 01.48-1.363A1.778 1.778 0 011.829 2h12.334a1.798 1.798 0 011.358.55A1.759 1.759 0 0116 3.922v8.156a1.748 1.748 0 01-.48 1.37 1.786 1.786 0 01-1.357.552H7.985zm6.872-7.19H1.143V12.107c0 .476.21.694.67.694h12.37c.465 0 .674-.22.674-.71V6.81zM1.143 5.586h13.714v-1.66c0-.517-.199-.727-.686-.727H1.686a.557.557 0 00-.38.162c-.101.101-.159.236-.16.378-.013.611 0 1.223 0 1.847"></path>
        <path d="M12.004 11H10.88a.609.609 0 01-.426-.179.596.596 0 01.008-.847.61.61 0 01.43-.172h2.209a.61.61 0 01.43.173.596.596 0 01.004.848.609.609 0 01-.427.178h-1.105z"></path>
      </g>
    </svg>
  )
}

export default memo(CardIcon)