import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { login } from '../../api/auth'
import useAuthContext from '../../hooks/useAuthContext'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Logo from '../../assets/images/logo-blue.png'

const Login = () => {
  const { setToken } = useAuthContext()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().trim().email('Ingrese un correo valido').required('El correo electrónico es requerido'),
      password: Yup.string().trim().required('La contraseña es requerida')
    }),
    onSubmit: async ({ email, password }, { setFieldError }) => {
      const response = await login(email, password)

      if(response?.non_field_errors?.length) {
        if(response?.non_field_errors[0]?.includes('Unable to log in with provided credentials')) {
          setFieldError('email', 'Email o contraseña incorrectos')
          setFieldError('password', 'Email o contraseña incorrecta')
          return
        }

        if(response?.non_field_errors[0]?.includes('E-mail is not verified')) {
          setFieldError('email', 'Debes verificar tu correo electrónico')
          return
        }
      } else if(response?.key) {
        setToken(response.key)
        navigate('/start', {
          replace: true
        })
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Qaixa: Inicio de Sesión</title>
      </Helmet>

      <section className="Login">
        <div className="Login__Image">
          <img
            className='Login__Logo'
            src={Logo}
            alt="Qaixa: Inicio de Sesión"
            title="Qaixa: Inicio de Sesión"
          />
          <span className="text-2">Iniciar Sesión</span>
        </div>

        <div className="w-100 d-flex flex-column gap-2">
          <Input
            name='email'
            type='email'
            placeholder='Correo electrónico'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />

          <Input
            name='password'
            type='password'
            placeholder='Contraseña'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.errors.password}
          />

          <div className="d-flex justify-content-end">
            <Link className='link-primary text-4 py-2 text-end' to='/restore-password'>
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </div>

        <div className="w-100 d-flex flex-column gap-4">
          <Button
            onClick={() => formik.handleSubmit()}
            disabled={formik.isSubmitting}
          >
              Iniciar sesión
          </Button>

          <div className="w-100 d-flex flex-column gap-2">
            <span className='text-3 text-center'>¿Aún no tenés cuenta?</span>
            <Link
              to="/register"
              className='button-white'
            >
              Registrate
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login