import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import Button from '../../../components/Button'
import CodeInputs from '../../../components/CodeInputs'
import AuthHeader from 'containers/AuthHeader'
import AuthCard from 'containers/AuthCard'
import { checkCode } from 'api/auth'

const ValidateCode = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const email = location.state.email || ''

  const formik = useFormik({
    initialValues: {
      value_one: '',
      value_two: '',
      value_three: '',
      value_four: '',
      value_five: '',
      value_six: '',
      code: ''
    },
    validateOnChange: false,
    validate: (values) => {
      const errors = {
        code: ''
      }
      const Regex = /^[0-9a-zA-Z]+$/
      const valuesArray = Object.values(values)
      const valuesArrayWithoutCode = valuesArray.slice(0, valuesArray.length - 1)

      // Check if all values exists
      for(let i = 0; i < 6; i++) {
        if(!valuesArrayWithoutCode[i]) {
          errors.code = 'Completá el código'
          return errors
        }
      }

      // Check if all values are valid
      for(let i = 0; i < 6; i++) {
        if(Regex.test(valuesArrayWithoutCode[i]) === false) {
          errors.code = 'Código inválido'
          return errors
        }
      }
    },
    onSubmit: async (values) => {
      const valuesArray = Object.values(values)
      valuesArray.pop()
      const code = valuesArray.join('')

      const response = await checkCode(email, code)

      if(response.error) {
        formik.setFieldError('code', 'Código inválido')
      }
      if(response.message.includes('Token is valid')) {
        navigate('/restore-password/new-password', {
          replace: true,
          state: {
            email,
            code
          }
        })
      }
    }
  })

  return (
  // <>
  //   <Helmet>
  //     <title>Qaixa: Validar Código</title>
  //   </Helmet>

  //   <section className="ValidateCode">
  //     <div className="d-flex flex-column align-items-center gap-2 py-5">
  //       <img
  //         className='ValidateCode__Logo'
  //         src={Logo}
  //         alt="Qaixa: Validar Código"
  //         title="Qaixa: Validar Código"
  //       />
  //       <span className="text-3">Validación de código</span>
  //     </div>

  //     <div className="h-100 d-flex flex-column justify-content-center gap-5 text-center">
  //       <div className="d-flex flex-column gap-3">
  //         <span className="text-1 fw-semibold">Ingresá el código que<br/>recibiste en tu teléfono</span>

  //         <CodeInputs formik={formik}/>
  //       </div>

    //       <Button
    //         type='primary'
    //         onClick={formik.handleSubmit}
    //         isSubmit
    //       >
    //         Validar
    //       </Button>
    //     </div>
    //   </section>
    // </>
    <>
      <Helmet>
        <title>Qaixa: Validar Código</title>
      </Helmet>

      <section className="RestorePassword">
        <AuthHeader title='Validar código'/>

        <div className="RestorePassword__Content">
          <AuthCard className='gap-4 justify-content-evenly'>
            <div className="d-flex flex-column align-items-center gap-2">
              {/* <img
                className='RestorePassword__Image'
                src={QuestionImage}
                alt='¿Olvidaste tu contraseña?'
                title='¿Olvidaste tu contraseña?'
              /> */}

              <h1 className="h4 pt-4">Ingresa el código que te enviamos</h1>
            </div>

            <CodeInputs formik={formik}/>
            <div className="w-100 d-flex flex-column gap-2">

              <Button
                type='primary'
                onClick={formik.handleSubmit}
                isSubmit
                disabled={formik.isSubmitting}
              >
                  Recuperar contraseña
              </Button>
            </div>
          </AuthCard>
        </div>
      </section>
    </>
  )
}

export default ValidateCode