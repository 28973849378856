import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import useAuthContext from '../../../hooks/useAuthContext'
import RegisterHeader from '../../../components/RegisterHeader'
import CodeInputs from '../../../components/CodeInputs'
import Button from '../../../components/Button'
import RegisterSuccess from '../../../containers/RegisterSuccess'
import { verifySMS } from 'api/auth'
// import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon'

const ValidatePhone = () => {
  const { user, token } = useAuthContext()
  const [ responseState, setResponseState ] = useState(null)

  const formik = useFormik({
    initialValues: {
      value_one: '',
      value_two: '',
      value_three: '',
      value_four: '',
      value_five: '',
      value_six: '',
      code: ''
    },
    validateOnChange: false,
    validate: (values) => {
      const errors = {
        code: ''
      }
      const Regex = /^[0-9a-zA-Z]+$/
      const valuesArray = Object.values(values)
      const valuesArrayWithoutCode = valuesArray.slice(0, valuesArray.length - 1)

      // Check if all values exists
      for(let i = 0; i < 6; i++) {
        if(!valuesArrayWithoutCode[i]) {
          errors.code = 'Completá el código'
          return errors
        }
      }

      // Check if all values are valid
      for(let i = 0; i < 6; i++) {
        if(Regex.test(valuesArrayWithoutCode[i]) === false) {
          errors.code = 'Código inválido'
          return errors
        }
      }
    },
    onSubmit: async (values) => {
      const valuesArray = Object.values(values)
      valuesArray.pop()
      const code = valuesArray.join('')

      const response = await verifySMS(token, {
        otp: code,
        user_phone: user.phone,
      })

      if(response?.message?.includes('Phone number verified')) {
        setResponseState(true)
      }
      if(response?.error) {
        formik.setErrors({
          code: 'El código no es valido'
        })
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Qaixa: Validar Teléfono</title>
      </Helmet>

      <section className="ValidatePhone register-container">
        <RegisterHeader title="Validación" step={3}/>

        <div className="d-flex flex-column gap-3">
          <h1 className='h4 fw-semibold'>
            Ingresá el código que enviamos a tu celular
          </h1>

          <span className="text-2 text-gray-dark">
            Lo enviamos a <span className="fw-semibold">{ user.phone }</span>
          </span>

          {/* <span className='text-2 text-gray-dark'>
            Si el número no es correcto<br/>
            <button className='text-1 text-primary fw-bold'>
              modifícalo aquí
            </button>
          </span> */}

          <CodeInputs formik={formik}/>

          <span className='text-2 text-gray-dark'>
            ¿No recibiste el código?
            <button className='text-1 text-primary fw-bold ms-1'>
              Reenviar
            </button>
          </span>
        </div>

        <div className="w-100 d-flex flex-column align-items-center gap-2">
          <Button
            type='primary'
            onClick={formik.handleSubmit}
            isSubmit
            disabled={formik.isSubmitting}
          >
            Validar
          </Button>
        </div>
      </section>

      <RegisterSuccess
        show={responseState}
        message='¡Validación de número de celular exitosa!'
        nextStep='/register/name'
      >
        <div className="w-100 py-2 border-bottom text-center">
          <span className='text-2 fw-medium'>{ user.phone }</span>
        </div>

        <span className="text-3">
          Utilizaremos este número de celular si necesitas recuperar el acceso a tu cuenta y para informarte sobre temas de seguridad
        </span>
      </RegisterSuccess>
    </>
  )
}

export default ValidatePhone
