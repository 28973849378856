import { Helmet } from 'react-helmet'
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon'

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Privacidad</title>
      </Helmet>

      <div className="Privacy">
        <div className="Menu__Group">
          <h3 className='text-2 fw-medium'>Privacidad y datos personales</h3>
          <p className='text-4 text-gray-dark'>
          En esta sección podés consultar los datos personales guardados de tu cuenta y los permisos que nos das para personalizar tu experiencia.
          </p>

          <div className="Menu__List">
            <div className="Menu__Item">
              <span className='text-3'>Administrar permisos</span>
              <ArrowRightIcon/>
            </div>

            <div className="Menu__Item">
              <span className='text-3'>Administrar preferencias</span>
              <ArrowRightIcon/>
            </div>
          </div>
        </div>

        <div className="Menu__Group">
          <h3 className='text-2 fw-medium'>Acciones sobre tu cuenta</h3>

          <div className="Menu__List">
            <div className="Menu__Item">
              <span className='text-3'>Lorem Ipsum</span>
              <ArrowRightIcon/>
            </div>

            <div className="Menu__Item">
              <span className='text-3'>Lorem Ipsum</span>
              <ArrowRightIcon/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Privacy