import { SVGProps, memo } from 'react'

const CameraIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.66 2.025 4.333 3H2C.897 3 0 3.897 0 5v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2h-2.334l-.325-.975A1.496 1.496 0 0 0 9.919 1H6.08c-.647 0-1.219.413-1.422 1.025ZM8 6a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
    />
  </svg>
)

export default memo(CameraIcon)