import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { motion } from 'framer-motion'
import { getStores } from 'api/stores'
import useAuthContext from 'hooks/useAuthContext'
import useAppContext from 'hooks/useAppContext'
import StoreCategory from 'components/StoreCategory'
import Loader from 'components/Loader'
import StoreImage from 'assets/images/default-store-dropdown.png'
import ArrowRightIcon from 'assets/icons/ArrowRightIcon'
import valigLogo from 'assets/images/valig.png'
import MendiolazaLogo from 'assets/images/services/mendiolaza-logo.png'

const variants = {
  open: { height: 'auto' },
  closed: { height: 0 },
}

const StoresSlider = () => {
  const { token } = useAuthContext()
  const { currentApp } = useAppContext()
  // const { data: categories } = useQuery(
  //   'Store Categories',
  //   () => getStores(token),
  //   {
  //     enabled: token !== null,
  //   })
  const [ categoryDetail, setCategoryDetail ] = useState({
    id: null,
    show: false
  })

  const categories = [
    {
      id: 1,
      name: 'Supermercado Valig',
      image: '',
      stores: [
        {
          id: 1,
          name: 'Lorem ipsum 1',
          address: 'Dirección',
          distance: 1.5,
          image: ''
        }, {
          id: 2,
          name: 'Lorem ipsum 1',
          address: 'Dirección',
          distance: 2.8,
          image: ''
        }, {
          id: 3,
          name: 'Lorem ipsum 1',
          address: 'Dirección',
          distance: 6.3,
          image: ''
        }
      ]
    }, {
      id: 2,
      name: 'Autoservicio Familia',
      image: '',
      stores: [
        {
          id: 1,
          name: 'Lorem ipsum 2',
          address: 'Dirección',
          distance: 1.5,
          image: ''
        }, {
          id: 2,
          name: 'Lorem ipsum 2',
          address: 'Dirección',
          distance: 2.8,
          image: ''
        }, {
          id: 3,
          name: 'Lorem ipsum 2',
          address: 'Dirección',
          distance: 6.3,
          image: ''
        }
      ]
    },
    
    /*{
      id: 3,
      name: 'Cat 3',
      image: '',
      stores: [
        {
          id: 1,
          name: 'Lorem ipsum 3',
          address: 'Dirección',
          distance: 1.5,
          image: ''
        }, {
          id: 2,
          name: 'Lorem ipsum 3',
          address: 'Dirección',
          distance: 2.8,
          image: ''
        }, {
          id: 3,
          name: 'Lorem ipsum 3',
          address: 'Dirección',
          distance: 6.3,
          image: ''
        }
      ]
    }, {
      id: 4,
      name: 'Cat 4',
      image: '',
      stores: [
        {
          id: 1,
          name: 'Lorem ipsum 4',
          address: 'Dirección',
          distance: 1.5,
          image: ''
        }, {
          id: 2,
          name: 'Lorem ipsum 4',
          address: 'Dirección',
          distance: 2.8,
          image: ''
        }, {
          id: 3,
          name: 'Lorem ipsum 4',
          address: 'Dirección',
          distance: 6.3,
          image: ''
        }
      ]
    }, {
      id: 5,
      name: 'Cat 5',
      image: '',
      stores: [
        {
          id: 1,
          name: 'Lorem ipsum 5',
          address: 'Dirección',
          distance: 1.5,
          image: ''
        }, {
          id: 2,
          name: 'Lorem ipsum 5',
          address: 'Dirección',
          distance: 2.8,
          image: ''
        }, {
          id: 3,
          name: 'Lorem ipsum 5',
          address: 'Dirección',
          distance: 6.3,
          image: ''
        }
      ]
    }, {
      id: 6,
      name: 'Cat 6',
      image: '',
      stores: [
        {
          id: 1,
          name: 'Lorem ipsum 6',
          address: 'Dirección',
          distance: 1.5,
          image: ''
        }, {
          id: 2,
          name: 'Lorem ipsum 6',
          address: 'Dirección',
          distance: 2.8,
          image: ''
        }, {
          id: 3,
          name: 'Lorem ipsum 6',
          address: 'Dirección',
          distance: 6.3,
          image: ''
        }
      ]
    }, {
      id: 7,
      name: 'Cat 7',
      image: '',
      stores: [
        {
          id: 1,
          name: 'Lorem ipsum 7',
          address: 'Dirección',
          distance: 1.5,
          image: ''
        }, {
          id: 2,
          name: 'Lorem ipsum 7',
          address: 'Dirección',
          distance: 2.8,
          image: ''
        }, {
          id: 3,
          name: 'Lorem ipsum 7',
          address: 'Dirección',
          distance: 6.3,
          image: ''
        }
      ]
    }, {
      id: 8,
      name: 'Cat 8',
      image: '',
      stores: [
        {
          id: 1,
          name: 'Lorem ipsum 8',
          address: 'Dirección',
          distance: 1.5,
          image: ''
        }, {
          id: 2,
          name: 'Lorem ipsum 8',
          address: 'Dirección',
          distance: 2.8,
          image: ''
        }, {
          id: 3,
          name: 'Lorem ipsum 8',
          address: 'Dirección',
          distance: 6.3,
          image: ''
        }
      ]
    }*/
  ]

  return (
    <div className="StoresSlider card">
      <h3 className="text-2 fw-semibold">Red de comercios</h3>

      {!categories?.length ? (
        <Loader/>
      ) : (
        <>
          <div className="StoresSlider__Slider">
            {categories?.map((item) => (
              <StoreCategory
                key={`Store-Dropdown-${item.id}`}
                id={item.id}
                name={item.name}
                image={item.image}
                categoryDetail={categoryDetail}
                setCategoryDetail={setCategoryDetail}
              />
            )
            )}
          </div>

          <motion.div
            initial={variants.closed}
            animate={categoryDetail.show ? 'open' : 'closed'}
            transition={{ duration: 0.05, ease: 'linear' }}
            style={{ transformStyle: 'preserve-3d' }}
            variants={variants}
            className="StoresSlider__Detail"
          >
            {categories?.find((item) => item.id === categoryDetail.id)?.stores?.map((item) => (
              <div className="StoresSlider__Detail-Item" key={`Store-Detail-Item-${item.id}`}>
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={item.image ? `${process.env.REACT_APP_BACK_URL}${item.image}` : StoreImage}
                    alt={item.name}
                    title={item.name}
                  />

                  <div className="d-flex flex-column">
                    <h4 className="text-3 fw-medium text-black">{ item.name }</h4>
                    <span className="text-4 text-secondary font-jakarta">{ item.address }</span>
                  </div>
                </div>

                <span className="text-2 text-gray-dark">a { item.distance || '1' } km</span>
              </div>
            ))}
          </motion.div>

          <div className="d-flex justify-content-center pt-2">
            <Link className="link-primary fw-medium text-4" to={`/${currentApp}/stores`}>
              Ver todo
              <ArrowRightIcon className='ms-1'/>
            </Link>
          </div>
        </>
      )}
    </div>
  )
}

export default StoresSlider