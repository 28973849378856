import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Error: Página no encontrada</title>
      </Helmet>

      <section className='app-container'>
        <div className="h-100 d-flex flex-column justify-content-center align-items-center text-center gap-5 p-3">
          <h1 className='title'>Página no encontrada</h1>
          <Link to="/" className="button-primary">
            Volver al inicio
          </Link>
        </div>
      </section>
    </>
  )
}

export default NotFound