import HomePoints from '../components/HomePoints'

const HomeTemplate = ({ children }) => {
  return (
    <section className='HomeTemplate'>
      <HomePoints/>

      <div className="HomeTemplate__Content">
        { children }
      </div>
    </section>
  )
}

export default HomeTemplate