import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Swiper, SwiperSlide } from 'swiper/react'
import useAppContext from 'hooks/useAppContext'
import Button from 'components/Button'
import InputPoints from 'components/InputPoints'
import SwiperHook from 'components/SwiperHook'
import RequestTokensIcon from 'assets/icons/RequestTokensIcon'
import CoinsImage from 'assets/images/coins.png'
import BubbleImage from 'assets/images/info-bubble.png'
import DownloadIcon from 'assets/icons/DownloadIcon'

const RequestPoints = () => {
  const { currentApp } = useAppContext()
  const inputRef = useRef(null)
  const [ swiper, setSwiper ] = useState(null)
  const navigate = useNavigate()

  // Focus on input when component is mounted
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  const pointsFormik = useFormik({
    initialValues: {
      points: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      points: Yup.number().nullable().typeError('Ingreso solo números').min(0.5, 'La cantidad mínima es 0.5 PTS').required('La cantidad de puntos es requerida')
    }),
    onSubmit: async () => {
      swiper.slideNext()
    }
  })

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <>
      <Helmet>
        <title>Qaixa: Solicitar Puntos</title>
      </Helmet>

      <section className="RequestPoints app-background">
        <Swiper
          className='RequestPoints__Swiper w-100 d-flex flex-column align-items-start gap-2'
          spaceBetween={32}
          slidesPerView={1}
          allowTouchMove={false}
        >
          <SwiperHook setSwiper={setSwiper}/>

          <SwiperSlide className='d-flex flex-column justify-content-center gap-2'>
            <div className="h-100 card text-center">
              <span className="text-4">Paso 1</span>

              <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-5">
                <RequestTokensIcon className='RequestPoints__Icon'/>

                <h2 className='text-2 fw-medium'>Ingresá la cantidad de puntos a solicitar</h2>

                <div className="w-100 d-flex flex-column align-items-center gap-2">
                  <InputPoints
                    name='points'
                    inputRef={inputRef}
                    value={pointsFormik.values.points}
                    onChange={pointsFormik.handleChange}
                    error={pointsFormik.errors.points}
                  />

                  <span className="text-5">
                    Límite diario: 1000
                  </span>
                </div>

              </div>

              <div className="d-flex flex-column gap-1">
                <Button type='secondary' onClick={() => swiper.slideNext()}>
                  Continuar
                </Button>
                <button className="link-primary text-4" onClick={handleGoBack}>
                  Cancelar
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='d-flex flex-column justify-content-center gap-2'>
            <div className="h-100 card text-center">
              <span className="text-4">Paso 2</span>

              <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-3">
                <img
                  className='RequestPoints__Coins'
                  src={CoinsImage}
                  alt="Solicitar puntos"
                  title='Solicitar puntos'
                />

                <h2 className='text-2 fw-medium'>Vas a solicitar la cantidad de:</h2>

                <h1 className="h1 text-end">PTS { pointsFormik.values.points }</h1>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="text-4">¿Desea confirmar la operación?</span>
                <Button type='secondary' onClick={() => swiper.slideNext()}>
                  Continuar
                </Button>
                <button className="link-primary text-4" onClick={handleGoBack}>
                  Cancelar
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='d-flex flex-column justify-content-center gap-2'>
            <div className="h-100 card justify-content-between text-center">
              <span className="text-4">Comprobante de operación</span>

              <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                <img
                  className='RequestPoints__Bubble'
                  src={BubbleImage}
                  alt="La solicitud de puntos ha sido realizada"
                  title='La solicitud de puntos ha sido realizada'
                />

                <h2 className='text-2 fw-medium mt-2'>
                  Tu solicitud de puntos ha sido realizada.
                </h2>

                <h2 className='text-2 fw-medium'>
                  Te enviaremos una notificación cuando
                  el receptor responda a tu solicitud.
                </h2>
              </div>

              <span className="text-5">Número de solicitud: 0004</span>

              <div className="d-flex flex-column align-items-center gap-2">
                <Link
                  to={`/${currentApp}/movements/receipt/1`}
                  replace
                  className='RequestPoints__Download link-primary'
                >
                  <DownloadIcon className='me-2'/>
                  Descargar
                </Link>
                <Link to={`/${currentApp}`} className='button-secondary'>
                  Volver al inicio
                </Link>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  )
}

export default RequestPoints