import { createContext, useState, Dispatch, SetStateAction, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getCard } from '../api/transport-app'
import useAuthContext from '../hooks/useAuthContext'
import { useLocation } from 'react-router-dom'
import themes from 'utils/themes'

interface AppContextProps {
  showMenu: boolean
  setShowMenu: Dispatch<SetStateAction<boolean>>

  currentApp: string

  card: any
  // refetchCard: any
  // cardIsFetching: boolean

  points: number
  // setPoints: any
}

export const AppContext = createContext<AppContextProps>(null)

const AppContextProvider = ({ children }) => {
  const { token } = useAuthContext()
  const [ showMenu, setShowMenu ] = useState<boolean>(false)
  // const [ points, setPoints ] = useState(localStorage.getItem('points') ? parseInt(localStorage.getItem('points')) : 0)
  const [ currentApp, setCurrentApp ] = useState<string>('')
  const { pathname } = useLocation()
  // const { data: card, refetch: refetchCard, isFetching: cardIsFetching } = useQuery(
  //   ['getCard'],
  //   () => getCard(token),
  //   {
  //     enabled: token !== null,
  //     refetchOnWindowFocus: false,
  //   }
  // )

  // Set themes based on app
  useEffect(() => {
    const currentTheme = themes.find(theme => pathname.includes(theme.app))

    if(currentTheme) {
      Object.keys(currentTheme).forEach(key => {
        if(key === 'app') return
        document.documentElement.style.setProperty(`--${key}`, currentTheme[key])
        setCurrentApp(currentTheme.app)
      })
    }
  }, [pathname])

  const cardData = localStorage.getItem('card')
  const card = cardData ? JSON.parse(cardData) : null
  const pointsData = localStorage.getItem('points')
  const points = pointsData ? parseInt(pointsData) : 0

  const values = {
    showMenu,
    setShowMenu,

    currentApp,

    card,
    // refetchCard,
    // cardIsFetching,

    points,
    // setPoints
  }

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export default AppContextProvider
