import { useState, useEffect, createContext, Dispatch, SetStateAction } from 'react'
import { useQuery } from 'react-query'
import { NormalizedUserType } from '../api/types'
import { getUser } from '../api/users'

interface AuthContextInterface {
  token: string;
  setToken: Dispatch<SetStateAction<string>>;

  firstCharge: boolean;

  user: NormalizedUserType;
  // user: any;
  refetchUser: () => void;
  userIsLoading: boolean;
  removeUser: () => void;
}

export const AuthContext = createContext<AuthContextInterface | null>(null)

const AuthContextProvider = ({ children }) => {
  // State to avoid deleting token from local storage on page reload
  const [token, setToken] = useState(null)
  const [firstCharge, setFirstCharge] = useState(true)
  const {
    data: user,
    refetch: refetchUser,
    isLoading: userIsLoading,
    remove: removeUser,
  } = useQuery(
    ['getUser'],
    () => getUser(token),
    {
      initialData: {
        id: null,
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        date_joined: '',
        user: null,
        address: '',
        alias: '',
        birthdate: '',
        card: '',
        country: '',
        province: '',
        document_number: '',
        phone: '',
        postal_code: '',
        profile_image: '',
        sex: '',
        validate_document: false,
      },
      enabled: token !== null,
    }
  )

  // const user = JSON.parse(localStorage.getItem('user'))

  // Save or remove token in local storage
  useEffect(() => {
    const setTokenInLocalStorage = async () => {
      if (token) {
        localStorage.setItem('token', token)
        checkToken()
        await refetchUser()
      } else {
        localStorage.removeItem('token')
        removeUser()
      }
    }

    if (!firstCharge) {
      setTokenInLocalStorage()
    }
  }, [token])

  // Capture changes in local storage and delete the token if it doesn't exist
  useEffect(() => {
    function storageEventHandler(event) {
      if (!event.isTrusted) {
        if (event.currentTarget.localStorage.token && !token) {
          setToken(event.currentTarget.localStorage.token)
        } else if (token) {
          setToken(null)
        }
      } else {
        if (event.key === 'token') {
          setToken(event.newValue)
        }
      }
    }

    window.addEventListener('storage', storageEventHandler)
  }, [])

  // Check token and get user data
  useEffect(() => {
    checkToken()

    setFirstCharge(false)
  }, [])

  const checkToken = async () => {
    const savedToken = localStorage.getItem('token')

    if (savedToken) {
      setToken(savedToken)
    } else {
      setToken(null)
    }
  }

  const values: AuthContextInterface = {
    token,
    setToken,
    firstCharge,
    user,
    refetchUser,
    userIsLoading,
    removeUser,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
