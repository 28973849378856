import { getQuery } from './apiFunctions'

export const getCoutries = async (token: string) => {
  return await getQuery<any>({
    token,
    path: '/admin_settings/countries/',
    callback: data => data.data
  })
}

export const getProvinces = async (token: string, country_id: number) => {
  return await getQuery<any>({
    token,
    path: `/admin_settings/provinces/?country_id=${country_id}`,
    callback: data => data.data
  })
}

export const getDepartments = async (token: string, province_id: number) => {
  return await getQuery<any>({
    token,
    path: `/admin_settings/department/?province_id=${province_id}`,
    callback: data => data.data
  })
}

export const getCities = async (token: string, department_id: number) => {
  return await getQuery<any>({
    token,
    path: `/admin_settings/cities/?department_id=${department_id}`,
    callback: data => data.data
  })
}