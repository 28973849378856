import { memo, SVGProps } from 'react'

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={2}
        width={16}
        height={12}
      >
        <path d="M16 2.18H0v11.636h16V2.18Z" fill='#fff' />
      </mask>
      <path
        d="M5.333 13.736a.888.888 0 0 1-.628-.26L.26 9.03a.889.889 0 1 1 1.256-1.257l3.816 3.815 9.15-9.149a.889.889 0 0 1 1.257 1.257l-9.776 9.778a.886.886 0 0 1-.629.26"
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill='#fff' d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(CheckIcon)