import { Helmet } from 'react-helmet'
import Dropdown from '../../components/Dropdown'
import Logo from '../../assets/images/logo-blue.png'
import Image from '../../assets/images/faqs.png'

const FAQS = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Preguntas Frecuentes</title>
      </Helmet>

      <div className="FAQS">
        <div className="d-flex flex-column align-items-center gap-4">
          <img
            className="FAQS__Image"
            src={Image}
            alt='Preguntas frecuentes'
            title='Preguntas frecuentes'
          />

          <div className="Menu__Group">
            <Dropdown className='border-bottom' title='¿Qué es Qaixa?'>
              <p className="text-4">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
              </p>
            </Dropdown>

            <Dropdown className='border-bottom' title='¿Cómo funciona?'>
              <p className="text-4">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
              </p>
            </Dropdown>

            <Dropdown className='border-bottom' title='¿Cuáles son los servicios disponibles?'>
              <p className="text-4">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
              </p>
            </Dropdown>
          </div>
        </div>

        <img
          className="FAQS__Logo"
          src={Logo}
          alt="Qaixa"
          title="Qaixa"
        />
      </div>
    </>
  )
}

export default FAQS