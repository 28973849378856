import { useLocation, useNavigate } from 'react-router-dom'
import ArrowLeftIcon from '../assets/icons/ArrowLeftIcon'
import { menuLinks } from '../constants'

const MenuHeader = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleGoBack = () => {
    navigate(-1)
  }

  const pathSplit = pathname.split('/')
  const section = pathSplit[pathSplit.length - 1]
  const title = menuLinks.map(group => group.items.find(item => item.section == section)?.title || '').find(Boolean)

  if(title) return (
    <header className="MenuHeader">
      <button className="MenuHeader__Button" onClick={handleGoBack}>
        <ArrowLeftIcon/>
        { !section ?  (
          <span className="text-2 fw-fw-medium">
            Menu
          </span>
        ) : (
          <span className="text-2 fw-fw-medium">
            { title }
          </span>
        )}
      </button>
    </header>
  )
}

export default MenuHeader