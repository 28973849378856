import { getQuery, patchQuery, postQuery } from './apiFunctions'
import { ChangeEmailBody, ChangePinBody, UpdateUserBody } from './types'

// User Profile
export const getUser = async (token: string) => {
  return await getQuery<any>({
    path: '/users/me/',
    token,
    callback: (data) => {
      const userData = {
        ...data.user_profile,
        ...data.user,
        profile_id: data.user_profile.id,
      }

      delete userData.user

      return (userData)
    }
  })
}

export const updateUser = async (token: string, body: UpdateUserBody) => {
  return await patchQuery<any>({
    path: '/users/me/',
    token,
    body
  })
}

export const changeAlias = async (token: string, alias: string) => {
  return await patchQuery<any>({
    path: '/users/me/alias/',
    token,
    body: {
      alias
    }
  })
}

export const changeEmail = async (token: string, body: ChangeEmailBody) => {
  return await patchQuery<any>({
    path: '/users/me/change-email/',
    token,
    body
  })
}

export const changeEmailConfirm = async (token: string) => {
  return await getQuery<any>({
    path: `/users/me/change-email/confirm/${token}`,
    token,
  })
}


// PIN
export const addPin = async (token: string, pin: string) => {
  return await postQuery<any>({
    path: '/users/me/pin/',
    token,
    body: {
      pin
    }
  })
}

export const checkPin = async (token: string, pin: string) => {
  return await postQuery<any>({
    path: '/users/me/pin/check/',
    token,
    body: {
      pin
    }
  })
}

export const changePin = async (token: string, body: ChangePinBody) => {
  return await patchQuery<any>({
    path: '/users/me/pin/',
    token,
    body
  })
}