import { Link } from 'react-router-dom'

interface Props {
  shortcuts: Array<{
    title: string,
    icon: any,
    link: string
  }>
}

const Shortcuts = ({ shortcuts }: Props) => {
  return (
    <div className="Shortcuts card">
      <h3 className="text-2 fw-semibold">Accesos directos</h3>

      <div className="Shortcuts__Content">
        {shortcuts.map((shortcut, index) => (
          <Link
            key={`Shortcut-Item-${index}`}
            className="Shortcuts__Item"
            to={shortcut.link}
            title={shortcut.title}
          >
            <div className="Shortcuts__Item-Icon">
              { shortcut.icon }
            </div>
            <span className="text-3 fw-medium">{ shortcut.title }</span>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Shortcuts