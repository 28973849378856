import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import SuccessfulImage from 'assets/images/successful-image.png'

const SuccessfulCard = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Tarjeta Asociada con Éxito</title>
      </Helmet>

      <section className="SuccessfulCard app-background">

        <img
          className='SuccessfulCard__Image'
          src={SuccessfulImage}
          alt="Tarjeta asociada con éxito"
          title="Tarjeta asociada con éxito"
        />

        <h1 className='h2 text-green'>
        ¡Tu tarjeta<br/>
        fue asociada<br/>
        con éxito!
        </h1>

        <Link
          to='/transporte-cordoba'
          className='button-secondary'
          replace
        >
          Ir a la pantalla principal
        </Link>
      </section>
    </>
  )
}

export default SuccessfulCard