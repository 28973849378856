import { Helmet } from 'react-helmet'
import ContactBookContainer from '../../containers/ContactBookContainer'

const ContactBook = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Mi Agenda</title>
      </Helmet>

      <ContactBookContainer/>
    </>
  )
}

export default ContactBook