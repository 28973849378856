import { memo, SVGProps } from 'react'

const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 5.012a1 1 0 0 1 1.706-.707L8 9.598l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6A1 1 0 0 1 1 5.012Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ArrowDownIcon)