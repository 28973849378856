import { memo, SVGProps } from 'react'

const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#10203E">
      <path d="M15.665.335c.447.446.447 1.17 0 1.616L1.951 15.665A1.143 1.143 0 0 1 .335 14.05L14.049.335a1.143 1.143 0 0 1 1.616 0Z" />
      <path d="M.335.335a1.143 1.143 0 0 1 1.616 0l13.714 13.714a1.143 1.143 0 0 1-1.616 1.616L.335 1.951a1.143 1.143 0 0 1 0-1.616Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(CrossIcon)
