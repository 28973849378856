import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import useAppContext from 'hooks/useAppContext'
import UserProfile from 'components/UserProfile'
import ArrowRightIcon from 'assets/icons/ArrowRightIcon'
import profileLinks, { profileLinksVoid } from 'utils/profileLinks'

const ServiceProfile = () => {
  const { currentApp } = useAppContext()

  const { appLinks, generalLinks } = useMemo(() => {
    const currentLinks = profileLinks.find((item) => item.app === currentApp)

    if(currentLinks) return currentLinks
    else return profileLinksVoid
  }, [currentApp])

  return (
    <>
      <Helmet>
        <title>Qaixa: Perfil</title>
      </Helmet>

      <section className='ServiceProfile app-background'>
        <div className="ServiceProfile__Container card">
          <UserProfile/>

          <div className="d-flex flex-column gap-2">
            <h3 className='text-2 fw-bold'>Datos</h3>

            <div className="d-flex flex-column">
              {appLinks.map((item) => (
                <Link
                  key={`Account-Profile-Data-Link-${item.id}`}
                  className='ServiceProfile__Link'
                  to={item.link}
                >
                  <div className="text-3 fw-medium d-flex align-items-center gap-2">
                    { <item.icon/> }
                    { item.title }
                  </div>

                  <ArrowRightIcon/>
                </Link>
              ))}
            </div>
          </div>

          <div className="d-flex flex-column gap-2">
            <h3 className='text-2 fw-bold'>Configuraciones</h3>

            <div className="d-flex flex-column">
              {generalLinks.map((item) => (
                <Link
                  key={`Account-Profile-Config-Link-${item.id}`}
                  className='ServiceProfile__Link'
                  to={item.link}
                >
                  <div className="text-3 fw-medium d-flex align-items-center gap-2">
                    { item.title }
                  </div>

                  <ArrowRightIcon/>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServiceProfile