import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addContact } from 'api/contacts'
import useAppContext from 'hooks/useAppContext'
import useAuthContext from 'hooks/useAuthContext'
import AuthHeader from 'containers/AuthHeader'
import Button from 'components/Button'
import Input from 'components/Input'
import AddContactIcon from 'assets/icons/AddContactIcon'

const AddContact = () => {
  const { token } = useAuthContext()
  const { currentApp } = useAppContext()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      alias: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      alias: Yup.string().required('El alias es requerido')
    }),
    onSubmit: async ({ alias }) => {
      const response = await addContact(token, alias)

      if(response?.error?.includes('You can\'t add yourself as a contact')) {
        formik.setErrors({
          alias: 'No puedes añadirte a ti mismo como contacto'
        })
        return
      }

      if(response?.error?.includes('Contact already exists')) {
        formik.setErrors({
          alias: 'Ya haz añadido este contacto'
        })
        return
      }

      if(response?.error) {
        formik.setErrors({
          alias: 'Este alias no es valido'
        })
        return
      }

      if(response?.data?.id) {
        const user = response?.data?.user_to

        navigate(`/menu/contact-book/add-contact/${user.id}`, {
          state: {
            contact: response.data.user_to
          }
        })
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Qaixa: Agregar Contacto</title>
      </Helmet>

      <AuthHeader title='Añadir Contacto'/>

      <section className="AddContact Menu__Content app-background">
        <div className="AddContact__Container card">
          <div className="AddContact__Icon">
            <AddContactIcon/>
            <span className="text-4 fw-medium">Añadir contacto</span>
          </div>

          <div className="AddContact__Content">
            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
              <h2 className='text-2 fw-normal text-gray'>Pedile el alias a la persona que deseas añadir y escribilo aquí</h2>

              <Input
                name="alias"
                type="text"
                mode="text"
                placeholder="Alias"
                value={formik.values.alias}
                onChange={formik.handleChange}
                error={formik.errors.alias}
              />

              <Button
                onClick={formik.handleSubmit}
                isSubmit
              >
                Validar alias
              </Button>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
              <span className='text-2 fw-semibold'>
                O escaneá el QR del contacto que deseas agregar
              </span>

              <Link to={`/${currentApp}/scan-qr`} className='button-primary'>
                Escanear
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddContact