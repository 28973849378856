import { memo, SVGProps } from 'react'

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M.636 2.179c.408-.407.96-.636 1.536-.636h5.056a.727.727 0 0 1 0 1.454H2.172a.718.718 0 0 0-.717.718v10.113a.718.718 0 0 0 .717.717h10.113a.718.718 0 0 0 .718-.717V8.77a.727.727 0 0 1 1.454 0v5.057A2.172 2.172 0 0 1 12.285 16H2.172A2.172 2.172 0 0 1 0 13.828V3.715C0 3.14.229 2.586.636 2.18Z" />
      <path d="M13.73 1.455a.815.815 0 0 0-.577.238L6.387 8.46l-.385 1.538 1.538-.385 6.766-6.766a.816.816 0 0 0-.576-1.392Zm-1.605-.79a2.27 2.27 0 1 1 3.21 3.21l-6.909 6.91a.727.727 0 0 1-.338.19l-2.909.728a.727.727 0 0 1-.882-.882l.727-2.91a.727.727 0 0 1 .192-.337l6.909-6.91Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(EditIcon)