import { useEffect } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router'
import useAuthContext from '../hooks/useAuthContext'
import StartHeader from '../containers/StartHeader'
import Menu from '../containers/Menu'

const StartLayout = () => {
  const { token, firstCharge, user } = useAuthContext()
  const location = useLocation()
  const navigate = useNavigate()

  // If user is not logged in, redirect to login page
  useEffect(() => {
    if(!firstCharge && !token) {
      navigate('/onboarding')
    }
  }, [token, firstCharge, user, location.pathname])

  // Scroll to top when page is loaded
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className='app-container'>
      <StartHeader/>
      <Menu/>
      {/* Outlet is the content of the page */}
      <Outlet/>
    </div>
  )
}

export default StartLayout