import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import RegisterHeader from '../../../components/RegisterHeader'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
// import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon'
import RegisterIcon from '../../../assets/icons/RegisterIcon'
import { getCities, getCoutries, getDepartments, getProvinces } from '../../../api/admin'
import useAuthContext from '../../../hooks/useAuthContext'
import SearchInput from '../../../components/SearchInput'
import { updateUser } from '../../../api/users'
import RegisterSuccess from '../../../containers/RegisterSuccess'

const RegisterLocation = () => {
  const [ responseState, setResponseState ] = useState(false)
  const { token } = useAuthContext()

  const formik = useFormik({
    initialValues: {
      country: null,
      state: null,
      department: null,
      city: null,
      postal_code: '',
      address: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      country: Yup.number().nullable().required('El pías es requerido'),
      state: Yup.number().nullable().required('La provincia es requerida'),
      department: Yup.number().nullable().required('El departamento es requerido'),
      city: Yup.number().nullable().required('La ciudad es requerida'),
      postal_code: Yup.string().required('Requerido'),
      address: Yup.string().required('La dirección es requerida'),
    }),
    onSubmit: async (values) => {
      // const countryValue = countries.find((country) => country.value === values.country)
      // const provinceValue = provincies.find((state) => state.value === values.state)
      // const departmentValue = states.find((department) => department.value === values.department)
      // const cityValue = cities.find((city) => city.value === values.city)

      // const user = JSON.parse(localStorage.getItem('user'))
      // user.country = countryValue.label
      // user.state = provinceValue.label
      // user.department = departmentValue.label
      // user.city = cityValue.label
      // user.postal_code = values.postal_code
      // user.address = values.address
      // localStorage.setItem('user', JSON.stringify(user)


      const response = await updateUser(token, values)

      if(response?.user?.id) {
        setResponseState(true)
      }
    }
  })

  const { data: countries } = useQuery(
    ['getCountries'],
    () => getCoutries(token),
    {
      initialData: [],
      enabled: token !== null,
      select: (data) => {
        return data?.map((country) => ({
          value: country.id,
          label: country.name,
        }))
      }
    }
  )
  const { data: provincies } = useQuery(
    ['getProvinces'],
    () => getProvinces(token, formik.values.country),
    {
      initialData: [],
      enabled: token !== null && formik.values.country !== null,
      select: (data) => {
        return data?.map((country) => ({
          value: country.id,
          label: country.name,
        }))
      }
    }
  )
  const { data: states } = useQuery(
    ['getDepartments'],
    () => getDepartments(token, formik.values.state),
    {
      initialData: [],
      enabled: token !== null && formik.values.state !== null,
      select: (data) => {
        return data?.map((country) => ({
          value: country.id,
          label: country.name,
        }))
      }
    }
  )
  const { data: cities } = useQuery(
    ['getCities'],
    () => getCities(token, formik.values.department),
    {
      initialData: [],
      enabled: token !== null && formik.values.department !== null,
      select: (data) => {
        return data?.map((country) => ({
          value: country.id,
          label: country.name,
        }))
      }
    }
  )


  return (
    <>
      <Helmet>
        <title>Qaixa: Registrar Ubicación</title>
      </Helmet>

      <section className="RegisterLocation register-container">
        <RegisterHeader title="Registro" step={5}/>

        <div className="d-flex flex-column align-items-center gap-3">
          <RegisterIcon className='RegisterLocation__Icon'/>
          <h1 className='h4 fw-semibold'>Completa con tus datos personales</h1>

          <div className="d-flex flex-column gap-1">
            <SearchInput
              name='country'
              placeholder='País'
              search={countries}
              value={formik.values.country}
              onChange={(value) => formik.setValues({
                country: value,
                state: null,
                department: null,
                city: null,
                postal_code: formik.values.postal_code,
                address: formik.values.address,
              })}
              error={formik.errors.country}
            />
            <SearchInput
              name='state'
              placeholder='Provincia'
              search={provincies}
              value={formik.values.state}
              onChange={(value) => formik.setValues({
                country: formik.values.country,
                state: value,
                department: null,
                city: null,
                postal_code: formik.values.postal_code,
                address: formik.values.address,
              })}
              error={formik.errors.state}
              disabled={!formik.values.country}
              disabledPlaceholder='Selecciona un país'
            />
            <SearchInput
              name='department'
              placeholder='Departamento'
              search={states}
              value={formik.values.department}
              onChange={(value) => formik.setValues({
                country: formik.values.country,
                state: formik.values.state,
                department: value,
                city: null,
                postal_code: formik.values.postal_code,
                address: formik.values.address,
              })}
              error={formik.errors.department}
              disabled={!formik.values.state}
              disabledPlaceholder='Selecciona una provincia'
            />
            <div className="RegisterLocation__Inputs-Container">
              <SearchInput
                name='city'
                placeholder='Ciudad'
                search={cities}
                value={formik.values.city}
                onChange={(value) => formik.setValues({
                  country: formik.values.country,
                  state: formik.values.state,
                  department: formik.values.department,
                  city: value,
                  postal_code: formik.values.postal_code,
                  address: formik.values.address,
                })}
                error={formik.errors.city}
                disabled={!formik.values.department}
                disabledPlaceholder='Selecciona un departamento'
              />
              <Input
                name='postal_code'
                placeholder='CP'
                value={formik.values.postal_code}
                onChange={formik.handleChange}
                error={formik.errors.postal_code}
              />
            </div>
            <Input
              name='address'
              placeholder='Dirección'
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.errors.address}
            />
          </div>
        </div>

        <div className="w-100 d-flex flex-column align-items-center gap-2">
          <Button
            type='primary'
            onClick={formik.handleSubmit}
            isSubmit
            disabled={formik.isSubmitting}
          >
            Continuar
          </Button>
        </div>
      </section>

      <RegisterSuccess
        show={responseState}
        message='¡Excelente!'
        nextStep='/register/document'
      >
        <span className="text-2 fw-medium">
          Ya cargaste tus datos
        </span>
      </RegisterSuccess>

      {/* <RegisterSuccess
        show={responseState}
        message='¡Gracias por llegar hasta acá!'
        nextStep='/register/document'
      >
        <span className="text-2 fw-medium">
          Solo un paso más y concluimos con la activación de tu cuenta
        </span>
      </RegisterSuccess> */}
    </>
  )
}

export default RegisterLocation