import { Helmet } from 'react-helmet'
import CheckIcon from '../../assets/icons/CheckIcon'
import Logo from '../../assets/images/logo-blue.png'
import Image from '../../assets/images/terms-and-conditions.png'

const Conditions = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Términos y Condiciones</title>
      </Helmet>

      <div className="Conditions">
        <div className="d-flex flex-column gap-4">
          <div className="Menu__Group align-items-center">
            <img
              className="Conditions__Image"
              src={Image}
              alt="Términos y condiciones"
              title="Términos y condiciones"
            />

            <h3 className='text-2 fw-medium text-center'>Términos y condiciones</h3>
          </div>

          <div className="Menu__Group">
            <p className='text-4'>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
            </p>
          </div>

          <div className="Register__Checkbox-Container mx-auto">
            <span className="Input__Placeholder text-4">
              Aceptaste los términos y condiciones
            </span>
            <div className='Register__Checkbox Register__Checkbox--Checked'>
              <CheckIcon/>
            </div>
          </div>
        </div>

        <img
          className="Conditions__Logo"
          src={Logo}
          alt="Qaixa"
          title="Qaixa"
        />
      </div>
    </>
  )
}

export default Conditions