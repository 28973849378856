import classNames from 'classnames'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
}

const AuthCard = ({ children, className }: Props) => {
  return (
    <div className={classNames('AuthCard', className)}>
      { children }
    </div>
  )
}

export default AuthCard