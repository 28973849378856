import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import useAppContext from '../hooks/useAppContext'
import useAuthContext from '../hooks/useAuthContext'
import Dropdown from '../components/Dropdown'
import ArrowLeftIcon from '../assets/icons/ArrowLeftIcon'
import ArrowRightIcon from '../assets/icons/ArrowRightIcon'
import LogoutIcon from '../assets/icons/LogoutIcon'
import { menuLinks } from '../constants'

const menuVariants = {
  open: { translateX: '0' },
  closed: { translateX: '100%' },
}

const Menu = () => {
  const { setToken } = useAuthContext()
  const { showMenu, setShowMenu } = useAppContext()
  const navigate = useNavigate()

  const handleCloseMenu = () => {
    setShowMenu(false)
  }

  const handleLogout = () => {
    setToken(null)
    setShowMenu(false)
    localStorage.removeItem('token')
  }

  const handleNavigate = (section) => {
    navigate(`/menu/${section}`)
    handleCloseMenu()
  }

  return (
    <motion.aside
      className="Menu"
      initial={showMenu ? menuVariants.open : menuVariants.closed}
      animate={showMenu ? 'open' : 'closed'}
      transition={{ duration: 0.1, ease: 'linear' }}
      variants={menuVariants}
    >
      <header className="MenuHeader">
        <button className="MenuHeader__Button" onClick={handleCloseMenu}>
          <ArrowLeftIcon/>
          <span className="text-2 fw-medium">
            Menu
          </span>
        </button>
      </header>

      <div className="Menu__Content">
        <button className='Menu__Logout text-1 fw-semibold' onClick={handleLogout}>
          <LogoutIcon/>
          Cerrar sesión
        </button>

        {menuLinks.map((group) => (
          <Dropdown title={group.title} key={`Menu-Group-${group.id}`}>
            <div className="Menu__List">
              {group.items.map(item => {
                if(item.section === 'contact-book') return (
                  <button
                    key={`Menu-Item-${item.id}`}
                    className="Menu__Item"
                    onClick={() => handleNavigate(item.section)}
                  >
                    <span className='text-2 fw-medium'>{ item.title }</span>
                    <ArrowRightIcon/>
                  </button>
                )
                else return (
                  <Link
                    key={`Menu-Item-${item.id}`}
                    className="Menu__Item"
                    to={`/menu/${item.section}`}
                  >
                    <span className='text-2 fw-medium'>{ item.title }</span>
                    <ArrowRightIcon/>
                  </Link>
                )
              })}
            </div>
          </Dropdown>
        ))}
      </div>
    </motion.aside>
  )
}

export default Menu