import CardIcon from 'assets/icons/CardIcon'
import FileIcon from 'assets/icons/FileIcon'
import PeopleIcon from 'assets/icons/PeopleIcon'
import QRIcon from 'assets/icons/QRIcon'
import { MemoExoticComponent } from 'react'

type ProfileLinks = {
  app: string
  appLinks: {
    id: number
    title: string
    icon: MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => JSX.Element>
    link: string
  }[]
  generalLinks: {
    id: number
    title: string
    link: string
  }[]
}

const profileLinks: ProfileLinks[] = [
  {
    app: 'transporte-cordoba',
    appLinks: [
      {
        id: 1,
        title: 'Mi tarjeta',
        icon: CardIcon,
        link: '/transporte-cordoba/my-card'
      }, {
        id: 2,
        title: 'Mi QR',
        icon: QRIcon,
        link: '/transporte-cordoba/my-qr'
      }, {
        id: 3,
        title: 'Mi agenda',
        icon: PeopleIcon,
        link: '/menu/contact-book'
      }, {
        id: 4,
        title: 'Soporte',
        icon: FileIcon,
        link: '/menu/support'
      }
    ],
    generalLinks: [
      {
        id: 1,
        title: 'Tipo de cuenta',
        link: '/transporte-cordoba/account/type'
      }, {
        id: 2,
        title: 'Notificaciones',
        link: '/transporte-cordoba/notifications'
      }, {
        id: 3,
        title: 'Seguridad',
        link: '/menu/security'
      }, {
        id: 4,
        title: 'Lenguaje',
        link: '/menu/settings'
      }
    ]
  },{
    app: 'intercordoba',
    appLinks: [
      {
        id: 1,
        title: 'Mi tarjeta',
        icon: CardIcon,
        link: '/intercordoba/my-card'
      }, {
        id: 2,
        title: 'Mi QR',
        icon: QRIcon,
        link: '/intercordoba/my-qr'
      }, {
        id: 3,
        title: 'Mi agenda',
        icon: PeopleIcon,
        link: '/menu/contact-book'
      }, {
        id: 4,
        title: 'Soporte',
        icon: FileIcon,
        link: '/menu/support'
      }
    ],
    generalLinks: [
      {
        id: 1,
        title: 'Tipo de cuenta',
        link: '/intercordoba/account/type'
      }, {
        id: 2,
        title: 'Notificaciones',
        link: '/intercordoba/notifications'
      }, {
        id: 3,
        title: 'Seguridad',
        link: '/menu/security'
      }, {
        id: 4,
        title: 'Lenguaje',
        link: '/menu/settings'
      }
    ]
  },{
    app: 'jesus-maria',
    appLinks: [
      // {
      //   id: 1,
      //   title: 'Mis impuestos',
      //   icon: FileIcon,
      //   link: '/jesus-maria/my-taxes'
      // },
      {
        id: 2,
        title: 'Mi QR',
        icon: QRIcon,
        link: '/jesus-maria/my-qr'
      }, {
        id: 3,
        title: 'Mi agenda',
        icon: PeopleIcon,
        link: '/menu/contact-book'
      }, {
        id: 4,
        title: 'Soporte',
        icon: FileIcon,
        link: '/menu/support'
      }
    ],
    generalLinks: [
      {
        id: 1,
        title: 'Tipo de cuenta',
        link: '/jesus-maria/account/type'
      }, {
        id: 2,
        title: 'Notificaciones',
        link: '/jesus-maria/notifications'
      }, {
        id: 3,
        title: 'Seguridad',
        link: '/menu/security'
      }, {
        id: 4,
        title: 'Lenguaje',
        link: '/menu/settings'
      }
    ]
  }, {
    app: 'mendiolaza',
    appLinks: [
      // {
      //   id: 1,
      //   title: 'Mis impuestos',
      //   icon: FileIcon,
      //   link: '/mendiolaza/my-taxes'
      // },
      {
        id: 2,
        title: 'Mi QR',
        icon: QRIcon,
        link: '/mendiolaza/my-qr'
      }, {
        id: 3,
        title: 'Mi agenda',
        icon: PeopleIcon,
        link: '/menu/contact-book'
      }, {
        id: 4,
        title: 'Soporte',
        icon: FileIcon,
        link: '/menu/support'
      }
    ],
    generalLinks: [
      {
        id: 1,
        title: 'Tipo de cuenta',
        link: '/mendiolaza/account/type'
      }, {
        id: 2,
        title: 'Notificaciones',
        link: '/mendiolaza/notifications'
      }, {
        id: 3,
        title: 'Seguridad',
        link: '/menu/security'
      }, {
        id: 4,
        title: 'Lenguaje',
        link: '/menu/settings'
      }
    ]
  }
]

export const profileLinksVoid: ProfileLinks = {
  app: '',
  appLinks: [],
  generalLinks: []
}

export default profileLinks