import axios, { isAxiosError, AxiosResponse } from 'axios'
const API_URL = process.env.REACT_APP_API_URL

interface GeneralQuery<T> {
  path: string;
  token?: string;
  params?: any;
  body?: any;
  callback?: (data: T) => T
}

// interface ApiResponse<T> {
//   data: T | null;
//   status: number;
//   error?: AxiosError;
// }

export const getQuery = async <T>({
  path,
  token = '',
  params = {},
  callback,
}: GeneralQuery<T>): Promise<T> => {
  const headers = token
    ? {
      Authorization: `Token ${token}`,
    }
    : undefined

  try {
    const response: AxiosResponse = await axios.get(`${API_URL}${path}`, {
      headers,
      params,
    })
    const data = response.data

    if (callback) {
      return callback(data)
    }
    return data
  } catch (error) {
    if (isAxiosError(error)) return handleError(error)
  }
}

export const postQuery = async <T>({
  path,
  token = '',
  body = {},
  callback,
}: GeneralQuery<T>): Promise<T> => {
  const headers = token
    ? {
      Authorization: `Token ${token}`,
    }
    : undefined

  try {
    const response: AxiosResponse = await axios.post(`${API_URL}${path}`, body, {
      headers,
    })
    const data = response.data

    if (callback) {
      return callback(data)
    }
    return data
  } catch (error) {
    if (isAxiosError(error)) return handleError(error)
  }
}

export const putQuery = async <T>({
  path,
  token = '',
  body = {},
  callback,
}: GeneralQuery<T>): Promise<T> => {
  const headers = token
    ? {
      Authorization: `Token ${token}`,
    }
    : undefined

  try {
    const response: AxiosResponse = await axios.put(`${API_URL}${path}`, body, {
      headers,
    })
    const data = response.data

    if (callback) {
      return callback(data)
    }
    return data
  } catch (error) {
    if (isAxiosError(error)) return handleError(error)
  }
}

export const patchQuery = async <T>({
  path,
  token = '',
  body = {},
  callback,
}: GeneralQuery<T>): Promise<T> => {
  const headers = token
    ? {
      Authorization: `Token ${token}`,
    // 'Content-Type': 'multipart/form-data',
    }
    : undefined

  try {
    const response: AxiosResponse = await axios.patch(`${API_URL}${path}`, body, {
      headers,
    })
    const data = response.data

    if (callback) {
      return callback(data)
    }
    return data
  } catch (error) {
    if (isAxiosError(error)) return handleError(error)
  }
}

export const deleteQuery = async <T>({
  path,
  token = '',
  body = {},
  callback,
}: GeneralQuery<T>): Promise<T> => {
  const headers = token
    ? {
      Authorization: `Token ${token}`,
    }
    : undefined

  try {
    const response: AxiosResponse = await axios.delete(`${API_URL}${path}`, {
      headers,
      data: body,
    })
    const data = response.data

    if (callback) {
      return callback(data)
    }
    return data
  } catch (error) {
    if (isAxiosError(error)) return handleError(error)
  }
}

const deleteInvalidToken = () => {
  localStorage.removeItem('token')
  window.dispatchEvent(new Event('storage'))
}

const handleError = (error) => {
  console.log(error)
  // If the server is down
  if (
    typeof error?.response?.data === 'string' &&
    error?.response?.data?.startsWith('<!DOCTYPE html>')
  )
    return error

  // If the token is invalid
  if (
    typeof error?.response?.data === 'object' &&
    error?.response?.data?.detail == 'Invalid token.'
  ) deleteInvalidToken()

  // Default error handler
  return error?.response?.data || error?.message
}
