import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
// Layouts
import AuthLayout from 'layouts/AuthLayout'
import StartLayout from 'layouts/StartLayout'
import MenuLayout from 'layouts/MenuLayout'
import Layout from 'layouts/Layout'
// Auth pages
import OnBoarding from 'pages/auth/OnBoarding'
import Login from 'pages/auth/Login'
import RestorePassword from 'pages/auth/restore-password/RestorePassword'
import ValidateCode from 'pages/auth/restore-password/ValidateCode'
import NewPassword from 'pages/auth/restore-password/NewPassword'
// Start pages
import Splash from 'pages/start/Splash'
import Start from 'pages/start/Start'
import AllServices from 'pages/start/AllServices'
// Register pages
import Register from 'pages/auth/register/Register'
import RegisterEmail from 'pages/auth/register/RegisterEmail'
import ValidateEmail from 'pages/auth/register/ValidateEmail'
import RegisterPassword from 'pages/auth/register/RegisterPassword'
import RegisterPhone from 'pages/auth/register/RegisterPhone'
import ValidatePhone from 'pages/auth/register/ValidatePhone'
import RegisterDocument from 'pages/auth/register/RegisterDocument'
import ValidateDocument from 'pages/auth/register/ValidateDocument'
import RegisterIdentity from 'pages/auth/register/RegisterIdentity'
import ValidateIdentity from 'pages/auth/register/ValidateIdentity'
import RegisterName from 'pages/auth/register/RegisterName'
import RegisterLocation from 'pages/auth/register/RegisterLocation'
import RegisterPin from 'pages/auth/register/RegisterPin'
// import RegisterDomicile from 'pages/auth/register/RegisterDomicile'
// Menu pages
import Profile from 'pages/menu/Profile'
import Settings from 'pages/menu/Settings'
import GeneralNotifications from 'pages/menu/GeneralNotifications'
import MovementsSummary from 'pages/menu/MovementsSummary'
import Services from 'pages/menu/Services'
import ContactBook from 'pages/menu/ContactBook'
import AddContact from 'pages/menu/AddContact'
import AddContactDetail from 'pages/menu/AddContactDetail'
import DeleteContact from 'pages/menu/DeleteContact'
import ContactDetail from 'pages/menu/ContactDetail'
import ContactService from 'pages/menu/ContactService'
import MyQR from 'pages/menu/MyQR'
import Support from 'pages/menu/Support'
import CreateTicket from 'pages/menu/CreateTicket'
import ClaimDetail from 'pages/menu/ClaimDetail'
import Company from 'pages/menu/Company'
import Security from 'pages/menu/Security'
import Conditions from 'pages/menu/Conditions'
import Privacy from 'pages/menu/Privacy'
import FAQS from 'pages/menu/FAQS'
// General pages
import ServiceNotifications from 'pages/general/ServiceNotifications'
import ServiceStores from 'pages/general/ServiceStores'
import ScanQR from 'pages/general/ScanQR'
import ExchangePoints from 'pages/general/ExchangePoints'
import ReceiptPoints from 'pages/general/Receipt'
import SendPoints from 'pages/general/SendPoints'
import RequestPoints from 'pages/general/RequestPoints'
import ServiceProfile from 'pages/general/ServiceProfile'
import ServiceMovements from 'pages/general/ServiceMovements'
import Receipt from 'pages/general/Receipt'
import AccountType from 'pages/menu/AccountType'
// Routers
import TransportAppRouter from './TransportAppRouter'
import InterCordobaAppRouter from './InterCordobaAppRouter'
import TaxesAppRouter from './TaxesAppRouter'
import MendiolazaAppRouter from './MendiolazaAppRouter'
// 404 error page
import NotFound from 'pages/error/NotFound'



const App = () => {

  // Set body height to window height
  useEffect(() => {
    const body = document.querySelector('body')
    body.style.height = `${window.innerHeight}px`

    window.addEventListener('resize', () => {
      body.style.height = `${window.innerHeight}px`
    })
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Splash />} />

      {/* // Agregar volver al registro */}
      {/* Auth */}
      <Route element={<AuthLayout />}>
        <Route path="/onboarding" element={<OnBoarding />} />
        <Route path="/login" element={<Login />} />

        <Route path="/restore-password" element={<RestorePassword />} />
        <Route path="/restore-password/validate-code" element={<ValidateCode />} />
        <Route path="/restore-password/new-password" element={<NewPassword />} />

        <Route path="/register" element={<Register />} />
        <Route path="/register/email" element={<RegisterEmail />} />
        <Route path="/register/validate-email" element={<ValidateEmail />} />
        <Route path="/register/password" element={<RegisterPassword />} />
        <Route path="/register/phone" element={<RegisterPhone />} />
        <Route path="/register/validate-phone" element={<ValidatePhone />} />
        <Route path="/register/name" element={<RegisterName />} />
        <Route path="/register/location" element={<RegisterLocation />} />
        <Route path="/register/document" element={<RegisterDocument />} />
        <Route path="/register/validate-document" element={<ValidateDocument />} />
        {/* <Route path="/register/identity" element={<RegisterIdentity />} />
        <Route path="/register/validate-identity" element={<ValidateIdentity />} /> */}
        <Route path="/register/pin" element={<RegisterPin />} />
        {/* <Route path="/register/domicile" element={<RegisterDomicile />} /> */}
      </Route>

      {/* Start */}
      <Route element={<StartLayout />}>
        <Route path="/start" element={<Start />} />
        <Route path="/all-services" element={<AllServices />} />
      </Route>

      {/* Menu */}
      <Route path='/menu' element={<MenuLayout/>}>
        <Route path="profile" element={<Profile />} />
        <Route path="settings" element={<Settings />} />
        <Route path="notifications" element={<GeneralNotifications />} />
        <Route path="movements-summary" element={<MovementsSummary />} />
        <Route path="services" element={<Services />} />
        <Route path="contact-book" element={<ContactBook />} />
        <Route path="contact-book/add-contact" element={<AddContact />} />
        <Route path="contact-book/add-contact/:id" element={<AddContactDetail />} />
        <Route path="contact-book/delete-contact/:id" element={<DeleteContact />} />
        <Route path="contact-book/contact/:id" element={<ContactDetail />} />
        <Route path="contact-book/:service/:id" element={<ContactService />} />
        <Route path="support" element={<Support />} />
        <Route path="support/create-ticket" element={<CreateTicket />} />
        <Route path="support/ticket/:id" element={<ClaimDetail />} />
        <Route path="company" element={<Company />} />
        <Route path="security" element={<Security />} />
        <Route path="faqs" element={<FAQS />} />
        <Route path="terms-and-conditions" element={<Conditions />} />
        <Route path="privacy" element={<Privacy />} />
      </Route>

      <Route path='/:service' element={<Layout/>}>
        <Route path="my-qr" element={<MyQR />} />
        <Route path="notifications" element={<ServiceNotifications />} />
        <Route path="stores" element={<ServiceStores />} />

        {/* Exchange flow */}
        <Route path="scan-qr" element={<ScanQR />} />
        <Route path="exchange-points" element={<ExchangePoints />} />
        <Route path="receipt-points" element={<ReceiptPoints />} />

        {/* Send and request points flow */}
        <Route path="send-points" element={<SendPoints />} />
        <Route path="request-points" element={<RequestPoints />} />

        {/* Movements */}
        <Route path="movements" element={<ServiceMovements />} />
        <Route path="movements/receipt/:id" element={<Receipt />} />

        {/* Account */}
        <Route path="account/profile" element={<ServiceProfile />} />
        <Route path="account/type" element={<AccountType />} />
      </Route>


      {/* Services */}

      {/* Transporte Córdoba */}
      <Route path="/transporte-cordoba/*" element={<TransportAppRouter/>}/>
      {/* Rio Ceballos */}
      <Route path="/intercordoba/*" element={<InterCordobaAppRouter/>}/>
      {/* Jesus María */}
      <Route path='/jesus-maria/*' element={<TaxesAppRouter/>}/>
      {/* Mendiolaza */}
      <Route path='/mendiolaza/*' element={<MendiolazaAppRouter/>}/>

      {/* 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App