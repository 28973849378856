import { useRef } from 'react'
import { motion } from 'framer-motion'

interface Props {
  formik: any
}

const variants = {
  open: { height: '18px', opacity: 1 },
  closed: { height: 0, opacity: 0 },
}

const CodeInputs = ({ formik }: Props) => {
  const inputsRef = useRef(null)

  const handleInputs = (e: any, next) => {
    formik.setFieldValue(e.target.name, e.target.value)
    if(e.target.value && next) {
      const inputs: any = Array.from(inputsRef.current.querySelectorAll('input'))
      const index = inputs.findIndex((input: any) => input.name === e.target.name)
      inputs[index + 1].focus()
    }
  }

  const handlePaste = (e: any) => {
    const clipboardData = e.clipboardData
    const pastedData = clipboardData.getData('text')
    const values = pastedData.split('')
    const inputs = inputsRef.current.getElementsByTagName('input')

    for(let i = 0; i < 6; i++) {
      if(inputs[i]) {
        formik.setFieldValue(inputs[i].name, values[i])
      }
    }
  }

  return (
    <div className="d-flex flex-column">
      <div className="ValidateCode__Inputs-Container" ref={inputsRef}>
        <input
          className='ValidateCode__Input'
          type='text'
          inputMode='numeric'
          name='value_one'
          value={formik.values.value_one}
          onChange={(e) => {
            handleInputs(e, 'value_two')
          }}
          onPaste={handlePaste}
          maxLength={1}
        />

        <input
          className='ValidateCode__Input'
          type='text'
          inputMode='numeric'
          name='value_two'
          value={formik.values.value_two}
          onChange={(e) => {
            handleInputs(e, 'value_three')
          }}
          onPaste={handlePaste}
          maxLength={1}
        />

        <input
          className='ValidateCode__Input'
          type='text'
          inputMode='numeric'
          name='value_three'
          value={formik.values.value_three}
          onChange={(e) => {
            handleInputs(e, 'value_four')
          }}
          onPaste={handlePaste}
          maxLength={1}
        />

        <input
          className='ValidateCode__Input'
          type='text'
          inputMode='numeric'
          name='value_four'
          value={formik.values.value_four}
          onChange={(e) => {
            handleInputs(e, 'value_five')
          }}
          onPaste={handlePaste}
          maxLength={1}
        />

        <input
          className='ValidateCode__Input'
          type='text'
          inputMode='numeric'
          name='value_five'
          value={formik.values.value_five}
          onChange={(e) => {
            handleInputs(e, 'value_six')
          }}
          onPaste={handlePaste}
          maxLength={1}
        />

        <input
          className='ValidateCode__Input'
          type='text'
          inputMode='numeric'
          name='value_six'
          value={formik.values.value_six}
          onChange={(e) => {
            handleInputs(e, '')
          }}
          onPaste={handlePaste}
          maxLength={1}
        />
      </div>

      <motion.div
        className="Input__Error text-5"
        initial={variants.closed}
        animate={formik.errors.code ? 'open' : 'closed'}
        transition={{ duration: 0.1, ease: 'linear' }}
        style={{ transformStyle: 'preserve-3d' }}
        variants={variants}
        dangerouslySetInnerHTML={{ __html: formik.errors.code }}
      >
      </motion.div>
    </div>
  )
}

export default CodeInputs