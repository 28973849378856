const isAdmin = false
const defaultToken = true

const menuLinks = [
  {
    id: 1,
    title: 'Perfil y ajustes del sistema',
    items: [
      {
        id: 1,
        title: 'Perfil',
        section: 'profile'
      }, {
        id: 2,
        title: 'Ajustes del sistema',
        section: 'settings'
      },
      // , {
      //   id: 3,
      //   title: 'Tipo de cuenta',
      //   section: 'account-type'
      // },
      {
        id: 4,
        title: 'Notificaciones',
        section: 'notifications'
      }
    ]
  }, {
    id: 2,
    title: 'Movimientos',
    items: [
      {
        id: 4,
        title: 'Resumen',
        section: 'movements-summary'
      }
    ]
  },
  // {
  //   id: 3,
  //   title: 'Marketplace',
  //   items: [
  //     {
  //       id: 5,
  //       title: 'Servicios',
  //       section: 'services'
  //     }
  //   ]
  // },
  {
    id: 4,
    title: 'Agenda',
    items: [
      {
        id: 6,
        title: 'Mi agenda',
        section: 'contact-book'
      }
    ]
  }, {
    id: 5,
    title: 'Área de soporte',
    items: [
      {
        id: 7,
        title: 'Soporte',
        section: 'support'
      }
    ]
  }, {
    id: 6,
    title: 'Acerca de',
    items: [
      {
        id: 8,
        title: 'Empresa',
        section: 'company'
      }, {
        id: 9,
        title: 'Seguridad',
        section: 'security'
      }, {
        id: 10,
        title: 'Preguntas frecuentes',
        section: 'faqs'
      }, {
        id: 11,
        title: 'Términos y condiciones',
        section: 'terms-and-conditions'
      }, {
        id: 12,
        title: 'Privacidad',
        section: 'privacy'
      }
    ]
  }
]

export {
  isAdmin,
  defaultToken,
  menuLinks
}
