import Modal from './Modal'
import Loader from 'components/Loader'

const CameraLoaderModal = () => {
  return (
    <Modal className="CameraLoaderModal">
      <span className="h5 fw-medium text-center">Estamos validando tu DNI</span>
      <Loader/>
    </Modal>
  )
}

export default CameraLoaderModal