import { getQuery } from './apiFunctions'

export const getStores = async (token: string) => {
  return await getQuery<any>({
    path: '/transport-app/stores/',
    token,
    callback: (data) => data.data
  })
}

export const getStoreDetail = async (token: string, id: number) => {
  return await getQuery<any>({
    path: `/transport-app/stores/${id}/`,
    token,
  })
}