import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { passwordRecovery } from 'api/auth'
import AuthCard from 'containers/AuthCard'
import AuthHeader from 'containers/AuthHeader'
import Button from 'components/Button'
import Input from 'components/Input'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon'
import QuestionImage from 'assets/images/question.png'

const RestorePassword = () => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().trim().email('Ingrese un correo valido').required('El correo electrónico es requerido')
    }),
    onSubmit: async ({ email }) => {
      const response = await passwordRecovery(email)

      if(response.error) {
        formik.setFieldError('email', 'Este correo electrónico no es válido')
      }

      if(response?.message?.includes('Email sent')) {
        navigate('/restore-password/validate-code', {
          replace: true,
          state: {
            email
          }
        })
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Qaixa: Recuperar contraseña</title>
      </Helmet>

      <section className="RestorePassword">
        <AuthHeader title='Recuperar contraseña'/>

        <div className="RestorePassword__Content">
          <AuthCard className='gap-4'>
            <div className="d-flex flex-column align-items-center gap-2">
              <img
                className='RestorePassword__Image'
                src={QuestionImage}
                alt='¿Olvidaste tu contraseña?'
                title='¿Olvidaste tu contraseña?'
              />

              <h1 className="h4 pt-4">¿Olvidaste tu contraseña?</h1>
              <span className='text-1 text-gray'>No te preocupes. Enviaremos un código a tu email para que puedas recuperarla</span>
            </div>

            <div className="w-100 d-flex flex-column gap-2">
              <Input
                name='email'
                type='email'
                mode='email'
                placeholder='Correo electrónico'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
              />

              <Button
                type='primary'
                onClick={formik.handleSubmit}
                isSubmit
                disabled={formik.isSubmitting}
              >
                Recuperar contraseña
              </Button>
            </div>

            <Link to='/login' className='link-primary d-flex align-items-center gap-2'>
              <ArrowLeftIcon/>
              Volver
            </Link>
          </AuthCard>
        </div>
      </section>
    </>
  )
}

export default RestorePassword