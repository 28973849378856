import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useFormik } from 'formik'
import classNames from 'classnames'
import * as Yup from 'yup'
import Conditions from '../../menu/Conditions'
import Button from '../../../components/Button'
import Logo from '../../../assets/images/logo-blue.png'
import CheckIcon from '../../../assets/icons/CheckIcon'
import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon'
import RegisterIcon from '../../../assets/icons/RegisterIcon'

const variants = {
  open: { translateX: 0 },
  closed: { translateX: '100%' },
}

const checkboxVariants = {
  open: { height: '18px', opacity: 1 },
  closed: { height: 0, opacity: 0 },
}

const  Register = () => {
  const [ showTerms, setShowTerms ] = useState(false)
  const registerRef = useRef(null)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      termsAndConditions: false,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      termsAndConditions: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones'),
    }),
    onSubmit: () => {
      navigate('/register/email')
    }
  })

  const openTermsAndConditions = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowTerms(true)
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  // Avoid tab navigation
  useEffect(() => {
    if(registerRef.current) {
      registerRef.current.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
          event.preventDefault()
        }
      })
    }
  }, [registerRef])

  return (
    <>
      <Helmet>
        <title>Qaixa: Registro</title>
      </Helmet>

      <section className="Register" ref={registerRef}>
        <img
          className='Register__Logo'
          src={Logo}
          alt="Qaixa"
          title="Qaixa"
        />

        <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-3">
          <RegisterIcon className='Register__Icon'/>

          <div className="d-flex flex-column gap-1 text-center">
            <h1 className="h4 fw-semibold">Para crear una cuenta necesitamos validar tus datos</h1>
            <span className="text-2">Sólo te tomará unos minutos</span>
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="Register__Checkbox-Container mx-auto">
              <span className="Input__Placeholder text-4">
                Acepto los <button className='link-secondary px-1' onClick={openTermsAndConditions} type='button'>términos y condiciones</button>
              </span>
              <button
                className="Register__Checkbox-Button"
                onClick={() => formik.setFieldValue('termsAndConditions', !formik.values.termsAndConditions)}
              >
                <div
                  className={classNames(
                    'Register__Checkbox',
                    { 'Register__Checkbox--Checked': formik.values.termsAndConditions }
                  )}
                >
                  <CheckIcon/>
                </div>
              </button>
            </div>

            <motion.div
              className="Input__Error text-5"
              initial={checkboxVariants.closed}
              animate={formik.errors.termsAndConditions ? 'open' : 'closed'}
              transition={{ duration: 0.1, ease: 'linear' }}
              style={{ transformStyle: 'preserve-3d' }}
              variants={checkboxVariants}
              dangerouslySetInnerHTML={{ __html: formik.errors.termsAndConditions }}
            >
            </motion.div>
          </div>

          <Button
            className='button-primary'
            onClick={formik.handleSubmit}
            isSubmit
            disabled={formik.isSubmitting}
          >
            Cuenta individual
          </Button>

          <Button
            type='secondary'
            disabled
          >
            Cuenta empresa
          </Button>
        </div>

        <button className="link-primary d-flex align-items-center gap-2" onClick={handleGoBack}>
          <ArrowLeftIcon/>
          Volver
        </button>
      </section>

      {/* Terms and conditions */}
      <motion.div
        className="Register__Terms"
        initial={variants.closed}
        animate={showTerms ? 'open' : 'closed'}
        exit={variants.closed}
        transition={{ duration: 0.1 }}
        variants={variants}
      >
        <header className="MenuHeader">
          <button className="MenuHeader__Button" onClick={() => setShowTerms(false)}>
            <ArrowLeftIcon/>
              Volver
          </button>
        </header>
        <Conditions/>
      </motion.div>
    </>
  )
}

export default Register