import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useAuthContext from 'hooks/useAuthContext'
import { addPin } from 'api/users'
import RegisterSuccess from 'containers/RegisterSuccess'
import Button from 'components/Button'
import Input from 'components/Input'
import RegisterHeader from 'components/RegisterHeader'
import LockIcon from 'assets/icons/LockIcon'

const RegisterPin = () => {
  const { token } = useAuthContext()
  const [ responseState, setResponseState ] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: {
      pin: '',
      repeatPin: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      pin: Yup
        .number()
        .typeError('El PIN solo debe tener números')
        .nullable()
        .test(
          'is-four-digits',
          'El número debe tener exactamente 4 dígitos',
          value => Number.isInteger(value) && value.toString().length === 4
        )
        .required('El PIN es requerido'),
      repeatPin: Yup
        .number()
        .typeError('El PIN solo debe tener números')
        .required('El PIN es requerido')
        .oneOf([Yup.ref('pin'), null], 'Los PINs no son iguales')
    }),
    onSubmit: async ({ pin }) => {
      const response = await addPin(token, pin)

      if(response?.message?.includes('Pin created successfully')) {
        setResponseState(true)
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Qaixa: Registrar PIN</title>
      </Helmet>

      <section className="RegisterPin register-container">
        <RegisterHeader title="Registro" step={7}/>

        <div className="w-100 d-flex flex-column align-items-center gap-2">
          <LockIcon className='RegisterPin__Icon'/>
          <h1 className='h4 fw-semibold'>
            Crearemos un PIN para brindar mayor seguridad a la hora de realizar tus operaciones
          </h1>
          <span className="text-2 text-gray-dark">
            Ingresá un código de 4 números que vas a utilizar para confirmar tus canjes
          </span>

          <div className="w-100 d-flex flex-column gap-1">
            <Input
              name='pin'
              type='password'
              mode='numeric'
              placeholder='PIN'
              value={formik.values.pin}
              onChange={formik.handleChange}
              error={formik.errors.pin}
            />
            <Input
              name='repeatPin'
              type='password'
              mode='numeric'
              placeholder='Repetir PIN'
              value={formik.values.repeatPin}
              onChange={formik.handleChange}
              error={formik.errors.repeatPin}
            />
          </div>
        </div>

        <div className="w-100 d-flex flex-column align-items-center gap-2">
          <Button
            type='primary'
            onClick={formik.handleSubmit}
            isSubmit
            disabled={formik.isSubmitting}
          >
            Registrar PIN
          </Button>
          {/* <Link
            to='/login'
            className='text-3 link-secondary'
          >
            Crearlo más tarde
          </Link> */}
        </div>
      </section>

      <RegisterSuccess
        show={responseState}
        message='¡Excelente!'
        nextStep='/login'
      >
        <span className="text-2 fw-medium">
          Haz creado tu PIN con éxito
        </span>

        <span className="text-3">
          Anótalo en un lugar seguro
        </span>
      </RegisterSuccess>
    </>
  )
}

export default RegisterPin