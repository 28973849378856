import { useMemo, memo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useAuthContext from 'hooks/useAuthContext'
import useAppContext from 'hooks/useAppContext'
import Logo from 'assets/images/logo-white.png'
import UserImage from 'assets/images/user.png'
import MenuIcon from 'assets/icons/MenuIcon'
import BellIcon from 'assets/icons/BellIcon'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon'
import TransportLogo from 'assets/images/services/transporte-cordoba-logo.png'
import TaxesLogo from 'assets/images/services/jesus-maria-logo-white.png'
import MendiolazaLogo from 'assets/images/services/mendiolaza-logo.png'
import InterCordobaLogo from 'assets/images/services/intercordoba-logo.png'

const Header = () => {
  const { user } = useAuthContext()
  const { showMenu, setShowMenu, currentApp } = useAppContext()
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const handleGoBack = () => {
    navigate(-1)
  }

  const currentRoute = useMemo(() => {
    const pathSplit = pathname.split('/')

    let route = pathSplit[pathSplit.length - 1]

    if (route.match(/^[0-9]+$/)) {
      route = pathSplit[pathSplit.length - 2]
    }

    return route
  }, [pathname, state])

  const logos = {
    'transporte-cordoba': TransportLogo,
    'jesus-maria': TaxesLogo,
    'mendiolaza': MendiolazaLogo,
    'intercordoba': InterCordobaLogo,
  }

  const contactBookRoutes = {
    'request-points': 'Solicitar puntos',
    'send-points': 'Enviar puntos',
  }

  const routes = {
    'notifications': 'Notificaciones',
    'profile': 'Mi perfil',
    'type': 'Tipo de cuenta',
    'stores': 'Red de comercios',
    'movements': 'Movimientos',
    'my-qr': 'Mi QR',
    'contact-book': contactBookRoutes[state] || 'Mi agenda',
    'contact': 'Contacto',
    'add-contact': 'Añadir contacto',
    'request-points': 'Solicitar puntos',
    'send-points': 'Enviar puntos',
    'exchange-points': 'Canjear puntos',
    'my-card': 'Mi tarjeta',
    'add-card': 'Asociar tarjeta',
    'successful-card': 'Se asocio tu tarjeta',
    'receipt': 'Detalle de la operación',
  }

  const goBackLink = currentRoute == 'add-card' ? '/start' : currentRoute == currentApp ? '/start' : `/${currentApp}`

  return (
    <header className="Header">
      <div className="Header__GoBack">
        <Link to={goBackLink}>
          <ArrowLeftIcon/>
          <img
            className='Header__Logo'
            src={Logo}
            alt="Qaixa"
            title='Qaixa'
          />
        </Link>
      </div>

      {routes[currentRoute] ? (
        <div className="Header__Page-GoBack">
          <button onClick={handleGoBack}>
            <ArrowLeftIcon/>
            <span className="text-2 fw-medium">{ routes[currentRoute] }</span>
          </button>
        </div>
      ) : (
        <div className="Header__Content">
          <Link className='Header__User' to={`/${currentApp}/account/profile`}>
            {/* <img src='https://picsum.photos/200' alt='User'/> */}
            <img
              className='Header__Image'
              src={user?.profile_image ? `${process.env.REACT_APP_BACK_URL}${user?.profile_image}` : UserImage}
              alt={`${user?.first_name} ${user?.last_name}`}
              title={`${user?.first_name} ${user?.last_name}`}
            />
            <span className="text-3 fw-medium">{ user?.first_name }</span>
          </Link>

          <img
            className='Header__Logo'
            src={logos[currentApp]}
            alt={`${currentApp}`}
            title={`${currentApp}`}
          />

          <div className="Header__Buttons">
            <Link to={`/${currentApp}/notifications`} className='Header__Button'>
              <BellIcon/>
            </Link>

            <button className='Header__Button' onClick={() => setShowMenu(!showMenu)}>
              <MenuIcon/>
            </button>
          </div>
        </div>
      )}
    </header>
  )
}

export default memo(Header)