import { useEffect } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router'
import useAuthContext from '../hooks/useAuthContext'

const AuthLayout = () => {
  const { token, firstCharge, user } = useAuthContext()
  const location = useLocation()
  const navigate = useNavigate()

  // // If user is logged in, redirect to home page
  // useEffect(() => {
  //   if(!firstCharge && token) {
  //     navigate('/start')
  //   }
  // }, [token, firstCharge, user, location.pathname])

  // Scroll to top when page is loaded
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className='app-container'>
      {/* Outlet is the content of the page */}
      <Outlet/>
    </div>
  )
}

export default AuthLayout