import { ReactNode } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

interface Props {
  className?: string
  closeModal?: () => void
  closeOnBlur?: boolean
  size?: 'small' | 'large'
  children: ReactNode
}

const Modal = ({ className, closeModal, closeOnBlur = false, size = 'large', children }: Props) => {
  return (
    <motion.div
      className='Modal'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: .2 }}
      onClick={closeOnBlur ? closeModal : undefined}
    >
      <div className={classNames(
        'Modal__Card',
        { 'Modal__Card--Small': size === 'small' },
        className
      )} onClick={e => e.stopPropagation()}>
        { children }
      </div>
    </motion.div>
  )
}

export default Modal