import useAuthContext from '../hooks/useAuthContext'
import useAppContext from '../hooks/useAppContext'
import LogoutIcon from '../assets/icons/LogoutIcon'
import EditIcon from '../assets/icons/EditIcon'
import UserImage from '../assets/images/user.png'

interface Props {
  isEditable?: boolean
}

const UserProfile = ({ isEditable = false }: Props) => {
  const { setToken, user } = useAuthContext()
  const { setShowMenu } = useAppContext()

  const handleLogout = () => {
    setToken(null)
    setShowMenu(false)
    localStorage.removeItem('token')
  }

  return (
    <div className="d-flex align-items-center gap-3">
      <div className="UserProfile__Avatar">
        <img
          src={user?.profile_image ? `${process.env.REACT_APP_BACK_URL}${user?.profile_image}` : UserImage}
          alt={`${user.first_name} ${user.last_name}`}
          title={`${user.first_name} ${user.last_name}`}
        />
        {/* { isEditable && (
          <button className="UserProfile__Avatar-Edit">
            <EditIcon/>
          </button>
        )} */}
      </div>

      <div className="d-flex flex-column align-items-start gap-1">
        <h1 className="h5 fw-semibold">{ user.first_name } { user.last_name }</h1>
        <h2 className="text-5 fw-normal text-gray">{ user.email }</h2>
        <button className="UserProfile__Logout" onClick={handleLogout}>
          <LogoutIcon/>
          <span className="text-2 fw-medium">Cerrar sesión</span>
        </button>
      </div>
    </div>
  )
}

export default UserProfile