import { SVGProps, memo } from 'react'

const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.667 1.75H1.333a1.328 1.328 0 0 0-1.232.827A1.345 1.345 0 0 0 0 3.09v9.82c0 .356.14.697.39.948.25.25.59.392.943.392h13.334c.353 0 .692-.141.942-.392.25-.252.39-.592.391-.947V3.09a1.345 1.345 0 0 0-.823-1.239 1.328 1.328 0 0 0-.51-.102ZM4.889 4.428A1.772 1.772 0 0 1 6.53 5.531a1.793 1.793 0 0 1-.385 1.946 1.775 1.775 0 0 1-3.035-1.263c0-.473.188-.927.521-1.262a1.775 1.775 0 0 1 1.257-.524ZM8 11.036a.586.586 0 0 1-.622.537H2.4a.581.581 0 0 1-.622-.536V10.5c0-.887.836-1.607 1.866-1.607h.138a2.85 2.85 0 0 0 2.211 0h.138c1.03 0 1.866.72 1.866 1.607l.003.536Zm6.221-1.473a.224.224 0 0 1-.222.223H10a.222.222 0 0 1-.222-.223v-.447A.224.224 0 0 1 10 8.893h4.001a.222.222 0 0 1 .223.223l-.003.447Zm0-1.786A.224.224 0 0 1 14 8H10a.222.222 0 0 1-.222-.223V7.33A.224.224 0 0 1 10 7.107h4.001a.222.222 0 0 1 .223.224l-.003.446Zm0-1.786a.224.224 0 0 1-.222.223H10a.222.222 0 0 1-.222-.223v-.446A.224.224 0 0 1 10 5.322h4.001a.222.222 0 0 1 .223.223l-.003.446Z"
    />
  </svg>
)

export default memo(DocumentIcon)
