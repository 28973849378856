import Modal from './Modal'
import ErrorBubbleImage from 'assets/images/cross-bubble.png'

interface Props {
  closeModal: () => void
  message: string
  buttonLabel: string
  handleButtonClick: () => void
}

const ErrorModal = ({ closeModal, message, buttonLabel, handleButtonClick }: Props) => {
  return (
    <Modal
      className='ErrorModal'
      closeModal={closeModal}
      size='small'
    >
      <img
        className='ErrorModal__Image'
        src={ErrorBubbleImage}
        alt="Éxito"
      />

      <h3 className='text-1 fw-medium text-center text-red'>{ message }</h3>

      <button
        className='button-primary'
        onClick={handleButtonClick}
      >
        { buttonLabel }
      </button>
    </Modal>
  )
}

export default ErrorModal