import { SVGProps, memo } from 'react'

const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M14.286.5H1.714A1.664 1.664 0 0 0 0 2.109v11.785a1.663 1.663 0 0 0 1.714 1.608h12.572A1.665 1.665 0 0 0 16 13.894V2.107A1.662 1.662 0 0 0 14.286.501ZM13.7 10.793l-.536 2.176a.53.53 0 0 1-.522.39c-5.714 0-10.357-4.343-10.357-9.71a.534.534 0 0 1 .415-.49l2.322-.502a.693.693 0 0 1 .12-.013.584.584 0 0 1 .493.304l1.071 2.344c.026.063.04.13.043.198a.554.554 0 0 1-.196.389L5.2 6.916a8.043 8.043 0 0 0 3.957 3.71l1.107-1.269a.624.624 0 0 1 .415-.184.674.674 0 0 1 .21.04l2.501 1.005a.487.487 0 0 1 .31.574Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(PhoneIcon)