import { memo, SVGProps } from 'react'

const QRIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill='currentColor'>
      <path d="M11.632 1.262h3.105v3.106h-3.105V1.262Zm-1.244 4.363h5.614V0h-5.614v5.625ZM1.257 11.633h3.105v3.105H1.257v-3.105ZM0 16h5.625v-5.611H0V16ZM16 9.218v3.031h-3.003v-1.258h1.75V9.214H16M7.997 13.497h1.245v1.246h1.259V16H8v-2.502M14.247 14.743h1.755v1.25h-1.75v-1.252M14.742 7.97V6.72h1.252v1.25h-1.252M11.748 15.999v-1.256h1.253v1.252L11.748 16ZM.008.009h5.605v5.605H.008V.01Zm4.36 1.254h-3.11v3.105h3.11V1.263ZM7.992 3.134h1.244v2.485H7.997v2.352H6.743V4.385h1.244V3.129v.005" />
      <path d="M6.751 9.212H3.128V7.963h1.248V6.719h1.246v1.243h1.135v1.254l-.006-.004ZM3.128 7.967H1.255v1.242H.008v-2.48h3.12v1.242M10.502 12.246H7.998v-1.254h1.254V9.207h1.25v3.039ZM9.252 7.975V6.734h3.746v1.24H9.244h.008ZM8.002 1.877V.012h1.237v1.86H7.998l.004.005Z" />
      <path d="M8.006 10.997h-1.25V9.216h1.25V11v-.004ZM14.751 7.966V9.22h-1.755V7.967l1.755-.001ZM7.998 1.872c0 .42-.002.841-.005 1.262H6.756V1.878h1.243" />
      <path d="M7.997 7.97h1.255v1.254H7.998V7.97ZM8.002 12.247H6.76v1.254H8v-1.254ZM12.997 12.247v1.255h-2.5v-1.255h2.504" />
      <path d="M14.25 13.497h-1.253v1.254h1.254v-1.254ZM13.806 2.2H12.57v1.228h1.236V2.201ZM3.43 12.57H2.194v1.23H3.43v-1.23ZM12.997 9.216v1.241h-1.238V9.221H13M3.426 2.2h-1.23v1.231h1.23v-1.23Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(QRIcon)