import { SVGProps, memo } from 'react'

const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.612 5.523h-.922v-1.8a3.702 3.702 0 0 0-1.064-2.622 3.687 3.687 0 0 0-5.219-.036A3.702 3.702 0 0 0 4.31 3.673v1.849h-.922A1.54 1.54 0 0 0 1.85 7.064v7.395A1.547 1.547 0 0 0 3.387 16h9.225a1.54 1.54 0 0 0 1.538-1.54V7.063a1.547 1.547 0 0 0-1.538-1.541Zm-4.074 4.806v2.651a.553.553 0 0 1-.511.553.539.539 0 0 1-.565-.54V10.33a1.231 1.231 0 0 1-.66-1.384A1.233 1.233 0 0 1 8 7.989a1.228 1.228 0 0 1 1.199.956 1.235 1.235 0 0 1-.66 1.384h-.001Zm2.075-4.806H5.386v-1.85c0-.694.275-1.36.765-1.851a2.611 2.611 0 0 1 4.462 1.852v1.849Z"
    />
  </svg>
)

export default memo(LockIcon)
