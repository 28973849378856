import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getContacts } from 'api/contacts'
import useAppContext from 'hooks/useAppContext'
import useAuthContext from 'hooks/useAuthContext'
import SearchIcon from 'assets/icons/SearchIcon'
import BubbleImage from 'assets/images/info-bubble.png'
import Loader from 'components/Loader'

const Contacts = () => {
  const { token } = useAuthContext()
  const { currentApp } = useAppContext()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { data, isLoading, isFetching } = useQuery(
    ['getContacts'],
    () => getContacts(token),
    {
      initialData: [],
      enabled: token !== null,
      select: data => {
        if(data?.data?.length) return data.data.map((contact) => ({
          id: contact.user_to.id,
          name: contact.user_to.name,
          image: contact.user_to.profile_image,
          is_recent: false,
        }))
      }
    }
  )

  const handleContactClick = (id: number) => {
    const actions = {
      'request-points': () => navigate(`/${currentApp}/request-points`, {
        state: {
          contactId: id
        }
      }),
      'send-points': () => navigate(`/${currentApp}/send-points`, {
        state: {
          contactId: id
        }
      }),
    }
    console.log(state)

    if(actions[state]) {
      actions[state]()
    } else {
      navigate(`/menu/contact-book/contact/${id}`)
    }
  }

  // const contacts = [
  //   {
  //     id: 1,
  //     name: 'Lorem ipsum 1',
  //     image: '',
  //     is_recent: true,
  //   }, {
  //     id: 2,
  //     name: 'Lorem ipsum 2',
  //     image: '',
  //     is_recent: true,
  //   }, {
  //     id: 3,
  //     name: 'Lorem ipsum 3',
  //     image: '',
  //     is_recent: true,
  //   }, {
  //     id: 4,
  //     name: 'Lorem ipsum 4',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 5,
  //     name: 'Lorem ipsum 5',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 6,
  //     name: 'Lorem ipsum 6',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 7,
  //     name: 'Lorem ipsum 7',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 8,
  //     name: 'Lorem ipsum 8',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 9,
  //     name: 'Lorem ipsum 9',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 10,
  //     name: 'Lorem ipsum 10',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 11,
  //     name: 'Lorem ipsum 11',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 12,
  //     name: 'Lorem ipsum 12',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 13,
  //     name: 'Lorem ipsum 13',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 14,
  //     name: 'Lorem ipsum 14',
  //     image: '',
  //     is_recent: false,
  //   }, {
  //     id: 15,
  //     name: 'Lorem ipsum 15',
  //     image: '',
  //     is_recent: false,
  //   }
  // ]

  if(isLoading || isFetching) return (
    <div className="Contacts card">
      <Loader/>
    </div>
  )

  return (
    <div className="Contacts card">
      {data?.length ? (
        <>
          <div className="Contacts__Search-Container">
            <SearchIcon/>
            <input
              className='Contacts__Search text-4'
              type="text"
              placeholder='Buscar por nombre ó ID'
            />
          </div>

          {/* <h2 className='text-2 fw-semibold'>Contactos frecuentes</h2>

          <div className="Contacts__Items">
            {contacts.map(contact => {
              if(contact.is_recent) return (
                <button
                  key={`Contact-Recent-${contact.id}`}
                  className="Contacts__Item"
                  onClick={() => handleContactClick(contact.id)}
                >
                  <div className="Contacts__Item-Image"></div>

                  <div className="d-flex flex-column gap-1 text-start">
                    <h6 className="text-2 fw-normal">{ contact.name }</h6>
                    <span className="text-4 text-gray">ID: { contact.id }</span>
                  </div>
                </button>
              )
            })}
          </div> */}

          <h2 className='text-2 fw-semibold'>Todos los contactos</h2>

          <div className="Contacts__Items">
            {data.map(contact => {
              if(!contact.is_recent) return (
                <button
                  key={`Contact-${contact.id}`}
                  className="Contacts__Item"
                  onClick={() => handleContactClick(contact.id)}
                >
                  <div className="Contacts__Item-Image"></div>

                  <div className="d-flex flex-column gap-1 text-start">
                    <h6 className="text-2 fw-normal">{ contact.name }</h6>
                    <span className="text-4 text-gray">ID: { contact.id }</span>
                  </div>
                </button>
              )
            })}
          </div>
        </>
      ) : (
        <div className='d-flex flex-column align-items-center gap-4 py-4'>
          <img
            src={BubbleImage}
            alt="Éxito"
            width={80}
            height={80}
          />
          <h2 className='text-2 fw-semibold'>Aun no tienes contactos</h2>

          <Link
            to='/menu/contact-book/add-contact'
            className='button-primary'
          >
            Añadir contacto
          </Link>
        </div>
      )}
    </div>
  )
}

export default Contacts