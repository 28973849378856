import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { getStores } from 'api/stores'
import useAuthContext from 'hooks/useAuthContext'
import StoreImage from 'assets/images/default-store-dropdown.png'
import MapImage from 'assets/images/stores-map.png'
import SearchIcon from 'assets/icons/SearchIcon'
import FilterIcon from 'assets/icons/FilterIcon'
import ArrowRightIcon from 'assets/icons/ArrowRightIcon'

const ServiceStores = () => {
  const { token } = useAuthContext()
  const { data: categories } = useQuery(
    'Store Categories',
    () => getStores(token),
    {
      initialData: {
        data: [],
        status: 200
      },
      enabled: token !== null,
    })

  const stores = [
    {
      id: 1,
      title: 'Lorem ipsum 1',
      address: 'Dirección',
      distance: 1.5,
      image: ''
    }, {
      id: 2,
      title: 'Lorem ipsum 2',
      address: 'Dirección',
      distance: 2.8,
      image: ''
    }, {
      id: 3,
      title: 'Lorem ipsum 3',
      address: 'Dirección',
      distance: 6.3,
      image: ''
    }, {
      id: 4,
      title: 'Lorem ipsum 4',
      address: 'Dirección',
      distance: 7.3,
    }, {
      id: 5,
      title: 'Lorem ipsum 5',
      address: 'Dirección',
      distance: 9.4,
    }, {
      id: 6,
      title: 'Lorem ipsum 6',
      address: 'Dirección',
      distance: 10.1,
    }, {
      id: 7,
      title: 'Lorem ipsum 7',
      address: 'Dirección',
      distance: 11.7,
    }, {
      id: 8,
      title: 'Lorem ipsum 8',
      address: 'Dirección',
      distance: 12.3,
    }, {
      id: 9,
      title: 'Lorem ipsum 9',
      address: 'Dirección',
      distance: 13.1,
    }, {
      id: 10,
      title: 'Lorem ipsum 10',
      address: 'Dirección',
      distance: 14.2,
    }, {
      id: 11,
      title: 'Lorem ipsum 11',
      address: 'Dirección',
      distance: 15.5,
    }, {
      id: 12,
      title: 'Lorem ipsum 12',
      address: 'Dirección',
      distance: 16.1,
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Red de comercios</title>
      </Helmet>

      <section className="ServiceStores app-background">
        <div className="ServiceStores__Container card">
          <div className="d-flex justify-content-between align-items-center gap-2">
            <div className="ServiceStores__Search-Container">
              <SearchIcon/>
              <input
                className='ServiceStores__Search text-4'
                type="text"
                placeholder='Buscar'
              />
            </div>

            <button className='ServiceStores__Button'>
              <span className="text-4 fw-medium">Filtrar</span>
              <FilterIcon/>
            </button>
          </div>

          <div className="ServiceStores__Items">
            {stores.map((item) => (
              <div className="StoresSlider__Detail-Item" key={`Store-Detail-Item-${item.id}`}>
                <div className="d-flex align-items-center gap-2">
                  <img src={item.image || StoreImage} alt={item.title} title={item.title}/>

                  <div className="d-flex flex-column">
                    <h4 className="text-3 fw-medium text-black">{ item.title }</h4>
                    <span className="text-4 text-secondary font-jakarta">{ item.address }</span>
                  </div>
                </div>

                <span className="text-2 text-gray-dark">a { item.distance } km</span>
              </div>
            ))}
          </div>

          <div className="d-flex flex-column gap-2">
            <span className='ServiceStores__Link text-4 fw-medium'>
              Ver locales cerca de mi ubicación
              <ArrowRightIcon/>
            </span>
            <img className='ServiceStores__Map' src={MapImage} alt="Map" />
          </div>
        </div>
      </section>
    </>
  )
}

export default ServiceStores