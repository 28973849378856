import { Helmet } from 'react-helmet'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import useAppContext from 'hooks/useAppContext'
import BubbleImage from 'assets/images/info-bubble.png'
import RequestTokensIcon from 'assets/icons/RequestTokensIcon'
import ArrowRightIcon from 'assets/icons/ArrowRightIcon'
import SendTokensIcon from 'assets/icons/SendTokensIcon'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon'

const ContactService = () => {
  const { currentApp } = useAppContext()
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()

  const movements = [
    {
      id: 1,
      date: '12:15',
      type: 'send',
      amount: 30,
      user: 'Name',
      transactionType: 'QR',
    }, {
      id: 2,
      date: 'Ayer',
      type: 'request',
      amount: 50,
      user: 'Name',
      transactionType: 'QR',
    }, {
      id: 3,
      date: '05/04',
      type: 'send',
      amount: 10,
      user: 'Name',
      transactionType: 'QR',
    }, {
      id: 4,
      date: '02/04',
      type: 'send',
      amount: 12,
      user: 'Name',
      transactionType: 'QR',
    }, {
      id: 5,
      date: '27/03',
      type: 'request',
      amount: 42,
      user: 'Name',
      transactionType: 'QR',
    }, {
      id: 6,
      date: '18/03',
      type: 'send',
      amount: 28,
      user: 'Name',
      transactionType: 'QR',
    }, {
      id: 7,
      date: '04/03',
      type: 'send',
      amount: 15,
      user: 'Name',
      transactionType: 'QR',
    }
  ]

  const title = location.state.service ? `Contacto - ${location.state.service}` : 'Contacto'

  return (
    <>
      <Helmet>
        <title>Qaixa: { title }</title>
      </Helmet>

      <header className="ContactService__Header">
        <button className="ContactService__Header-Button text-2 fw-medium" onClick={() => navigate(-1)}>
          <ArrowLeftIcon/>
          <span>{ title }</span>
        </button>
      </header>

      <section className="Menu__Content app-background">
        <div className="ContactService__Container card">
          <header className="ContactService__Profile">
            <img
              className='ContactService__Avatar'
              src='https://picsum.photos/20'
              alt='Name Lastname'
              title='Name Lastname'
            />
            <h1 className='text-1 fw-bold'>Name Lastname</h1>
            <span className="text-4 fw-semibold">user@email.com</span>
          </header>

          <div className="ContactService__Actions">
            <h2 className='text-2 fw-bold'>Acciones</h2>

            <div className="d-flex flex-column">
              <Link
                to={`/${currentApp}/request-points`}
                className="ContactService__Link"
                state={{ contactId: { id }}}
              >
                <div className="d-flex align-items-center gap-2">
                  <RequestTokensIcon/>
                  <span className='text-3 fw-medium'>Solicitar tokens</span>
                </div>

                <ArrowRightIcon/>
              </Link>

              <Link
                to={`/${currentApp}/send-points`}
                className="ContactService__Link"
                state={{ contactId: { id }}}
              >
                <div className="d-flex align-items-center gap-2">
                  <SendTokensIcon/>
                  <span className='text-3 fw-medium'>Enviar tokens</span>
                </div>

                <ArrowRightIcon/>
              </Link>
            </div>
          </div>

          <div className="ContactService__Items-Container">
            <h2 className='text-2 fw-bold'>Historial</h2>

            {movements.length > 0 ? (
              <div className="ContactService__Items">
                {movements.map((movement) => (
                  <div
                    key={`Contact-Detail-Movement-${movement.id}`}
                    className="ContactService__Item"
                  >
                    <div className="d-flex align-items-center gap-2">
                      <div className="ContactService__Item-Icon">
                        {movement.type === 'send' ? <SendTokensIcon/> : <RequestTokensIcon/>}
                      </div>
                      <div className="d-flex flex-column">
                        <h4 className="text-3 fw-medium">
                          {movement.type === 'send' ? 'Enviaste a' : 'Recibiste de'} {movement.user} PTS {movement.amount}
                        </h4>
                        <span className="text-4 text-gray-dark">QR</span>
                      </div>
                    </div>

                    <span className='text-4 text-gray-dark'>{movement.date}</span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="ContactService__Bubble-Container">
                <img
                  src={BubbleImage}
                  alt='No tenes ningúna transacción compartida con este contacto'
                  title='No tenes ningúna transacción compartida con este contacto'
                />
                <span className='text-4'>No tenes ningúna transacción compartida con este contacto</span>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactService