import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { addCard } from 'api/transport-app'
import useAuthContext from 'hooks/useAuthContext'
import Button from 'components/Button'
import Input from 'components/Input'
import SwiperHook from 'components/SwiperHook'
import Image from 'assets/images/women-card.png'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon'

const AddCard = () => {
  const { token } = useAuthContext()
  const [ swiper, setSwiper ] = useState(null)
  const cardRef = useRef(null)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      document_type: 'DNI',
      document_number: null,
      card_number: null
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      document_number: Yup.number().nullable().required('El DNI es requerido'),
      card_number: Yup.number().nullable().required('El número de la tarjeta es requerido')
    }),
    onSubmit: async ({ document_number, card_number }) => {
      // const data = await addCard(token, values)

      // if(data?.error) {
      //   if(data?.error?.includes('Document doen\'t appear on the list')) {
      //     formik.setFieldError('document_number', 'Este documento no esta registrado')
      //   }
      // }
      // else if(data?.success) {
      //   navigate('/intercordoba/successful-card', {
      //     replace: true,
      //   })
      // }
      const data = {
        document_number,
        card_number
      }

      localStorage.setItem('card', JSON.stringify(data))
      localStorage.setItem('points', '1000')

      navigate('/intercordoba/successful-card', {
        replace: true,
      })
    }
  })

  // Avoid tab navigation
  useEffect(() => {
    if(cardRef.current) {
      cardRef.current.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
          event.preventDefault()
        }
      })
    }
  }, [cardRef])

  return (
    <>
      <Helmet>
        <title>Qaixa: Asociar Tarjeta</title>
      </Helmet>

      <section className="AddCard app-background" ref={cardRef}>
        <Swiper
          className='w-100 d-flex flex-column align-items-start gap-3'
          modules={[ Pagination ]}
          spaceBetween={30}
          slidesPerView={1}
          allowTouchMove={false}
          pagination={{ clickable: false }}
        >
          <SwiperHook setSwiper={setSwiper}/>

          <SwiperSlide className='d-flex flex-column justify-content-center align-items-center gap-3'>
            <img
              className='AddCard__Image'
              src={Image}
              alt="Asociar tarjeta"
              title="Asociar tarjeta"
            />

            <span className='text-2 fw-medium'>
              Para comenzar a utilizar los<br/>
              beneficios es necesario<br/>
              asociar tu Tarjeta Red Bus
            </span>

            <Button
              type='secondary'
              onClick={() => swiper.slideNext()}
            >
              Asociar tarjeta
            </Button>
          </SwiperSlide>

          <SwiperSlide className='d-flex flex-column justify-content-center align-items-center gap-3'>
            <img
              className='AddCard__Image'
              src={Image}
              alt="Asociar tarjeta"
              title="Asociar tarjeta"
            />

            <Input
              name='card_number'
              type='number'
              mode='numeric'
              placeholder='Ingresa tu número de tarjeta'
              value={formik.values.card_number}
              onChange={formik.handleChange}
              error={formik.errors.card_number}
            />
            <Input
              name='document_number'
              type='number'
              mode='numeric'
              placeholder='DNI'
              value={formik.values.document_number}
              onChange={formik.handleChange}
              error={formik.errors.document_number}
            />

            <Button
              type='secondary'
              onClick={() => formik.handleSubmit()}
              isSubmit
            >
              Confirmar tarjeta
            </Button>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  )
}

export default AddCard