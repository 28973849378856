import { Link } from 'react-router-dom'
import Modal from './Modal'
import BubbleImage from '../../assets/images/check-bubble.png'

interface Props {
  service: string
}

const ConfirmDeleteCardModal = ({ service  }: Props) => {
  return (
    <Modal className='ConfirmDeleteCardModal'>
      <img
        className='ConfirmDeleteCardModal__Image'
        src={BubbleImage}
        alt="Iniciar solicitud"
        title="Iniciar solicitud"
      />

      <h3 className='h5 text-green'>¡Tu tarjeta ha sido eliminada!</h3>

      <span className='text-4 text-gray-dark my-3'>
        Tené en cuenta que para operar vas<br/>
        a necesitar tener una tarjeta asociada
      </span>

      <div className="w-100 d-flex flex-column align-items-center gap-1">
        <Link to={`/${service}/add-card`} className='button-secondary' replace>
          Volver a cargar tarjeta
        </Link>
        <Link to={`${service}/account/profile`} className="link-primary text-3" replace>
          Volver al perfil
        </Link>
      </div>
    </Modal>
  )
}

export default ConfirmDeleteCardModal