import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Swiper, SwiperSlide } from 'swiper/react'
import useAppContext from 'hooks/useAppContext'
import Button from 'components/Button'
import InputPoints from 'components/InputPoints'
import SwiperHook from 'components/SwiperHook'
import Input from 'components/Input'
import SendTokensIcon from 'assets/icons/SendTokensIcon'
import CoinsImage from 'assets/images/coins.png'
import FlowImage from 'assets/images/points-flow-image.png'

const SendPoints = () => {
  const { currentApp } = useAppContext()
  const inputRef = useRef(null)
  const [ swiper, setSwiper ] = useState(null)
  const navigate = useNavigate()

  // Focus on input when component is mounted
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  const pointsFormik = useFormik({
    initialValues: {
      points: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      points: Yup.number().nullable().typeError('Ingreso solo números').min(0.5, 'La cantidad mínima es 0.5 PTS').required('La cantidad de puntos es requerida')
    }),
    onSubmit: async () => {
      swiper.slideNext()
    }
  })

  const pinFormik = useFormik({
    initialValues: {
      pin: null
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      pin: Yup
        .number()
        .nullable()
        .test(
          'is-four-digits',
          'El número debe tener exactamente 4 dígitos',
          value => Number.isInteger(value) && value.toString().length === 4
        )
        .required('El PIN es requerido')
    }),
    onSubmit: async () => {
      navigate(`/${currentApp}/movements/receipt/1`, {
        replace: true
      })
    }
  })

  const handleGoBack = () => {
    navigate(-1)
  }

  // const handleGoToReceipt = () => {
  //   navigate(`/${currentApp}/movements/receipt/1`, {
  //     replace: true
  //   })
  // }

  return (
    <>
      <Helmet>
        <title>Qaixa: Enviar Puntos</title>
      </Helmet>

      <section className="RequestPoints app-background">
        <Swiper
          className='RequestPoints__Swiper w-100 d-flex flex-column align-items-start gap-2'
          spaceBetween={32}
          slidesPerView={1}
          allowTouchMove={false}
        >
          <SwiperHook setSwiper={setSwiper}/>

          <SwiperSlide className='d-flex flex-column justify-content-center gap-2'>
            <div className="h-100 card text-center">
              <span className="text-4">Paso 1</span>

              <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-5">
                <SendTokensIcon className='RequestPoints__Icon'/>

                <h2 className='text-2 fw-medium'>Ingresá la cantidad de puntos a enviar</h2>

                <InputPoints
                  name='points'
                  inputRef={inputRef}
                  value={pointsFormik.values.points}
                  onChange={pointsFormik.handleChange}
                  error={pointsFormik.errors.points}
                />
              </div>

              <div className="d-flex flex-column gap-1">
                <Button
                  type='secondary'
                  onClick={pointsFormik.handleSubmit}
                  disabled={pointsFormik.isSubmitting}
                  isSubmit
                >
                  Continuar
                </Button>
                <button className="link-primary text-4" onClick={handleGoBack}>
                  Cancelar
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='d-flex flex-column justify-content-center gap-2'>
            <div className="h-100 card text-center">
              <span className="text-4">Paso 2</span>

              <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-3">
                <img
                  className='RequestPoints__Coins'
                  src={CoinsImage}
                  alt="Solicitar puntos"
                  title='Solicitar puntos'
                />

                <h2 className='text-2 fw-medium'>Vas a enviar la cantidad de:</h2>

                <h1 className="h1 text-end">{pointsFormik.values.points} PTS</h1>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="text-4">¿Desea confirmar la operación?</span>
                <Button type='secondary' onClick={() => swiper.slideNext()}>
                  Continuar
                </Button>
                <button className="link-primary text-4" onClick={handleGoBack}>
                  Cancelar
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='d-flex flex-column justify-content-center gap-2'>
            <div className="h-100 card text-center">
              <span className="text-4">Paso 3</span>

              <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-4">
                <img
                  className='RequestPoints__Image'
                  src={FlowImage}
                  alt="Solicitar puntos"
                  title='Solicitar puntos'
                />

                <h2 className='text-2 fw-medium'>
                  Al ingresar tu PIN, confirmás el envío de <span className="fw-bold">{pointsFormik.values.points}</span> puntos a <span className="fw-bold">Agustín Pedano</span>
                </h2>

                <Input
                  name='pin'
                  placeholder='Ingresá tu pin'
                  type='number'
                  mode='numeric'
                  value={pinFormik.values.pin}
                  onChange={pinFormik.handleChange}
                  error={pinFormik.errors.pin}
                />

                <Link to='/menu/security' className='text-4 link-secondary'>
                  ¿Olvidaste tu pin?
                </Link>
              </div>

              <div className="d-flex flex-column gap-1">
                <Button type='secondary' onClick={pinFormik.handleSubmit}>
                  Continuar
                </Button>
                <button className="link-primary text-4" onClick={handleGoBack}>
                  Cancelar
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  )
}

export default SendPoints