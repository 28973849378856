import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import BubbleImage from 'assets/images/info-bubble.png'
import AuthHeader from '../../../containers/AuthHeader'
import { passwordRecoveryConfirm } from 'api/auth'
import RegisterSuccess from 'containers/RegisterSuccess'

const NewPassword = () => {
  const [ responseState, setResponseState ] = useState<boolean>(false)
  const location = useLocation()

  const email = location.state.email || ''
  const code = location.state.code || ''

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      password: Yup.string().trim().required('La nueva contraseña es requerida'),
      repeatPassword: Yup.string().trim().required('La nueva contraseña es requerida').oneOf([Yup.ref('password'), null], 'Las contraseñas no son iguales')
    }),
    validate: ({ password }) => {
      const errors = {
        password: '',
        repeatPassword: ''
      }

      const uppercaseRegex = RegExp(/[A-Z]/)
      const lowercaseRegex = RegExp(/[a-z]/)
      const symbolRegex = RegExp(/[\W_]/)

      if(password.length === 0) {
        errors.password = 'La contraseña es requerida'
        return errors
      } else if(password.length < 8) {
        errors.password = 'La contraseña debe contener al menos 8 caracteres'
        errors.repeatPassword = 'La contraseña debe contener al menos 8 caracteres'
        return errors
      } else if(uppercaseRegex.test(password) === false) {
        errors.password = 'La contraseña debe contener al menos una mayúscula'
        errors.repeatPassword = 'La contraseña debe contener al menos una mayúscula'
        return errors
      } else if(lowercaseRegex.test(password) === false) {
        errors.password = 'La contraseña debe contener al menos una minúscula'
        errors.repeatPassword = 'La contraseña debe contener al menos una minúscula'
        return errors
      } else if(symbolRegex.test(password) === false) {
        errors.password = 'La contraseña debe contener al menos un símbolo'
        errors.repeatPassword = 'La contraseña debe contener al menos un símbolo'
        return errors
      }
    },
    onSubmit: async ({ password }, { setErrors }) => {
      const response = await passwordRecoveryConfirm(email, code, password)

      if(
        response?.error?.length &&
        response?.error[0]?.includes('The password is too similar to the username')
      ) {
        setErrors({
          password: 'La contraseña es muy similar al nombre de usuario',
          repeatPassword: 'La contraseña es muy similar al nombre de usuario'
        })
        return
      }

      if(response?.error) {
        setErrors({
          password: 'EL tiempo ha expirado, reinicia el proceso de cambio de contraseña',
        })
        return
      }

      if(response?.message?.includes('Password reset successful')) {
        setResponseState(true)
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Qaixa: Nueva Contraseña</title>
      </Helmet>

      <section className="NewPassword">
        <AuthHeader title="Cambiar contraseña"/>

        <div className="NewPassword__Content">
          <div className="d-flex flex-column align-items-center gap-2">
            <img
              className='NewPassword__Image'
              src={BubbleImage}
              alt="Cambio de contraseña"
              title="Cambio de contraseña"
            />
            <span className="text-3">Cambio de contraseña</span>
          </div>

          <div className="w-100 d-flex flex-column gap-2">
            <Input
              name='password'
              type='password'
              placeholder='Nuvea contraseña'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
            />

            <Input
              name='repeatPassword'
              type='password'
              placeholder='Repetir nueva contraseña'
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              error={formik.errors.repeatPassword}
            />

            <Button
              className='mt-3'
              onClick={() => formik.handleSubmit()}
              disabled={formik.isSubmitting}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </section>

      <RegisterSuccess
        show={responseState}
        message='¡Cambio de contraseña exitosa!'
        nextStep='/login'
      >
        <span className="text-3">
          Ya puedes iniciar sesión con tu nueva contraseña
        </span>
      </RegisterSuccess>
    </>
  )
}

export default NewPassword