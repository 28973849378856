import { memo, SVGProps } from 'react'

const SendTokensIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={1}
      width={16}
      height={14}
    >
      <path d="M16 1H0v14h16V1Z" fill="#fff" />
    </mask>
    <g mask="url(#a)" fill="currentColor">
      <path d="M14.004 8.014a7.023 7.023 0 0 1-1.84 4.692 6.996 6.996 0 0 1-9.347.885A7.017 7.017 0 0 1 .133 9.327a7.015 7.015 0 0 1 2.18-6.516 6.983 6.983 0 0 1 6.69-1.51.51.51 0 0 1 .37.683.457.457 0 0 1-.163.198.512.512 0 0 1-.49.067 5.594 5.594 0 0 0-1.83-.254 5.885 5.885 0 0 0-5.206 3.177 5.756 5.756 0 0 0-.675 3.55 6.026 6.026 0 0 0 1.883 3.709 6.005 6.005 0 0 0 9.974-3.21c.159-.788.16-1.6.005-2.389a.5.5 0 0 1 .407-.61.485.485 0 0 1 .554.421c.067.457.115.916.17 1.374" />
      <path d="M6.46 5.005c.034-.53.18-.748.5-.746a.43.43 0 0 1 .42.25c.053.163.091.33.114.5a1.692 1.692 0 0 1 1.104.7c.176.239.28.522.303.818a.483.483 0 0 1-.411.534.494.494 0 0 1-.562-.418.735.735 0 0 0-.807-.677 3.1 3.1 0 0 0-.5.021.696.696 0 0 0-.591.66.718.718 0 0 0 .54.751c.351.094.707.173 1.059.267A1.702 1.702 0 0 1 8.914 9.34a1.708 1.708 0 0 1-1.33 1.642l-.126.032c0 .083.004.164 0 .245a.496.496 0 0 1-.674.47.494.494 0 0 1-.313-.452c-.007-.086 0-.172 0-.267a1.756 1.756 0 0 1-.967-.524 1.625 1.625 0 0 1-.449-1.01.479.479 0 0 1 .408-.522.493.493 0 0 1 .565.412.74.74 0 0 0 .818.683c.162.005.324-.003.485-.023a.7.7 0 0 0 .595-.656.715.715 0 0 0-.536-.754c-.347-.093-.698-.171-1.045-.267a1.704 1.704 0 0 1-1.298-1.663A1.71 1.71 0 0 1 6.355 5.03L6.461 5M13.231 3.616h-1.98a.49.49 0 0 1-.514-.587.475.475 0 0 1 .382-.386c.07-.01.14-.016.212-.015h1.945c-.169-.17-.313-.308-.448-.454a.492.492 0 0 1 .283-.83.447.447 0 0 1 .404.13c.437.433.873.868 1.303 1.307a.473.473 0 0 1 .004.674c-.433.445-.87.884-1.313 1.317a.487.487 0 0 1-.685-.696c.139-.146.29-.279.436-.417l-.03-.042" />
    </g>
  </svg>
)

export default memo(SendTokensIcon)