import { memo, SVGProps } from 'react'

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 14.988a1 1 0 0 1-.707-.293l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 1.414L6.414 7.988l5.293 5.293A1 1 0 0 1 11 14.988Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ArrowLeftIcon)
