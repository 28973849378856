import { memo, SVGProps } from 'react'

const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M7.096 6.354a.742.742 0 0 0-.742.742v6.678c0 .41.332.742.742.742h6.678c.41 0 .742-.332.742-.742V7.096a.742.742 0 0 0-.742-.742H7.096Zm-2.226.742c0-1.23.996-2.226 2.226-2.226h6.678c1.23 0 2.226.996 2.226 2.226v6.678c0 1.23-.997 2.226-2.226 2.226H7.096a2.226 2.226 0 0 1-2.226-2.226V7.096Z" />
      <path d="M2.226 1.484a.742.742 0 0 0-.742.742v6.678a.742.742 0 0 0 .742.742h.742a.742.742 0 1 1 0 1.484h-.742A2.226 2.226 0 0 1 0 8.904V2.226A2.226 2.226 0 0 1 2.226 0h6.678a2.226 2.226 0 0 1 2.226 2.226v.742a.742.742 0 1 1-1.484 0v-.742a.742.742 0 0 0-.742-.742H2.226Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(CopyIcon)