import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Workbox } from 'workbox-window'
import { BrowserRouter } from 'react-router-dom'
import Router from './router/Router'
import AuthContextProvider from './context/AuthContext'
import AppContextProvider from './context/AppContext'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/free-mode'

import './styles/index.sass'

const root = createRoot(document.getElementById('root') as HTMLElement)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    const workbox = new Workbox('service-worker.js')
    if(process.env.NODE_ENV !== 'development') {
      workbox.register()
    }
  })
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true
    },
  },
})

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AuthContextProvider>
        <AppContextProvider>
          <Router/>
          <ReactQueryDevtools />
        </AppContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </QueryClientProvider>
)