import { SVGProps, memo } from 'react'

const PeopleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <mask
        id="mask0_67_22"
        style={{ maskType: 'luminance' }}
        width="11"
        height="12"
        x="0"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M10.667 2H0v12h10.667V2z"></path>
      </mask>
      <g fill="currentColor" mask="url(#mask0_67_22)">
        <path d="M10.254 14.338a.593.593 0 01-.427-.18.617.617 0 01-.177-.437v-1.233c0-.492-.191-.964-.531-1.311a1.793 1.793 0 00-1.282-.544H3.015c-.48 0-.942.196-1.282.544-.34.347-.531.82-.531 1.311v1.234a.63.63 0 01-.167.453.604.604 0 01-.436.19.592.592 0 01-.436-.19.62.62 0 01-.167-.453v-1.234c0-.819.319-1.603.885-2.182a2.986 2.986 0 012.134-.903h4.826c.8 0 1.567.326 2.132.904.566.579.884 1.363.884 2.181v1.234a.628.628 0 01-.176.437.6.6 0 01-.427.18zM5.429 8.17c-.597 0-1.18-.181-1.677-.52a3.071 3.071 0 01-1.111-1.385 3.15 3.15 0 01-.172-1.783 3.107 3.107 0 01.826-1.58c.422-.431.96-.725 1.544-.844a2.954 2.954 0 011.744.176c.55.234 1.022.63 1.353 1.137a3.135 3.135 0 01-.376 3.894 2.986 2.986 0 01-2.131.904zm0-4.936c-.359 0-.71.109-1.008.312-.298.204-.53.494-.668.833A1.894 1.894 0 003.65 5.45c.07.36.243.69.497.95.253.26.576.436.928.507.352.072.716.035 1.048-.105.331-.14.614-.378.813-.683a1.885 1.885 0 00-.225-2.342 1.793 1.793 0 00-1.282-.543z"></path>
      </g>
      <mask
        id="mask1_67_22"
        style={{ maskType: 'luminance' }}
        width="8"
        height="9"
        x="8"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M16 2H8.533v8.727H16V2z"></path>
      </mask>
      <g mask="url(#mask1_67_22)">
        <path
          fill="currentColor"
          d="M15.253 11.183a.39.39 0 01-.28-.118.41.41 0 01-.116-.286v-.81c0-.322-.125-.63-.348-.858a1.175 1.175 0 00-.839-.356h-3.16c-.314 0-.615.129-.838.356a1.23 1.23 0 00-.347.858v.814a.41.41 0 01-.116.286.391.391 0 01-.56 0 .41.41 0 01-.116-.286v-.81a2.065 2.065 0 01.579-1.432 1.942 1.942 0 011.4-.595h3.164c.524.001 1.027.214 1.397.594.371.379.58.893.58 1.43v.813a.41.41 0 01-.116.286.391.391 0 01-.28.119"
        ></path>
        <path
          stroke="currentColor"
          strokeWidth="0.2"
          d="M15.253 11.183a.39.39 0 01-.28-.118.41.41 0 01-.116-.286v-.81c0-.322-.125-.63-.348-.858a1.175 1.175 0 00-.839-.356h-3.16c-.314 0-.615.129-.838.356a1.23 1.23 0 00-.347.858v.814a.41.41 0 01-.116.286.391.391 0 01-.56 0 .41.41 0 01-.116-.286v-.81a2.065 2.065 0 01.579-1.432 1.942 1.942 0 011.4-.595h3.164c.524.001 1.027.214 1.397.594.371.379.58.893.58 1.43v.813a.41.41 0 01-.116.286.391.391 0 01-.28.119"
        ></path>
        <path
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.2"
          d="M12.095 7.137c-.391 0-.774-.119-1.099-.341a2.014 2.014 0 01-.728-.908 2.065 2.065 0 01-.112-1.168c.077-.393.265-.753.541-1.036a1.964 1.964 0 011.013-.553 1.937 1.937 0 011.142.115c.361.153.67.412.888.744a2.055 2.055 0 01-.247 2.554c-.37.38-.874.593-1.398.593zm0-3.237c-.235 0-.465.072-.66.205a1.209 1.209 0 00-.437.545 1.24 1.24 0 00.258 1.323 1.163 1.163 0 001.294.263c.217-.092.402-.248.532-.448a1.233 1.233 0 00-.149-1.532 1.175 1.175 0 00-.838-.356z"
        ></path>
      </g>
    </svg>
  )
}

export default memo(PeopleIcon)