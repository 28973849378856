import { SVGProps, memo } from 'react'

const ArrowTokensIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path d="M11.762 13.336a.273.273 0 0 1-.254.254 3.8 3.8 0 0 1-1.015-.508l-1.65-.889c-.89-.508-1.778-.889-2.667-1.396l-.634-.38c-.635-.382-1.65-.89-2.285-1.27-.125 0-.254-.125-.381-.125l-1.65-.89c-.254-.124-.254-.124-.254-.38 0-.125.508-.38.634-.38.254-.125.381-.255.635-.382.508-.253 1.142-.634 1.65-.889a.466.466 0 0 1 .381-.124l1.016-.508A22.06 22.06 0 0 1 7.7 4.199c.254-.125.38-.254.634-.38.254-.128 3.047-1.651 3.174-1.651a.273.273 0 0 1 .254.253c0 .254-2.031 4.825-2.031 5.079H6.81a.511.511 0 1 0 0 1.015h2.92l.508 1.27c.124.38.38.889.508 1.27.124.253 1.015 2.412 1.015 2.539v-.258Zm1.015.125a1.072 1.072 0 0 0-.253-.889l-.508-1.27c-.125-.508-1.397-3.303-1.397-3.428 0-.124 1.016-2.412 1.143-2.666l.508-1.396c.125-.254.125-.38.254-.635.124-.508.253-.508.253-.888a1.172 1.172 0 0 0-1.269-1.142c-.253 0-.634.253-.889.253L6.176 3.813c-.125.124-.254.124-.381.253L2.491 5.843l-.761.381a3.782 3.782 0 0 0-1.397.89 1.188 1.188 0 0 0 0 1.65 8.095 8.095 0 0 0 1.778 1.015l7.49 4.065c.125.125.254.125.38.254.128.13.255.125.382.254.38.124.761.508 1.27.508a1.252 1.252 0 0 0 1.272-1.143l-.128-.256Z" />
      <path d="M15.316 10.29h-1.65c-.89 0-.762 1.015-.125 1.015h1.775c.635 0 .762-1.015-.125-1.015h.125ZM15.444 4.195h-1.777c-.635 0-.762 1.016.124 1.016h1.526c1.015 0 .761-1.016.127-1.016ZM14.809 7.877c0-.381-.125-.508-.508-.508h-1.65a.51.51 0 1 0 0 1.015h1.65c.254 0 .508-.125.508-.38v-.127Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(ArrowTokensIcon)
