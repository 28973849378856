type Theme = {
  app?: string
  background: string
  header: string
  'home-header': string
  'home-header-buttons': string
  'home-points-background': string
  'home-buttons': string
  'home-buttons-dark': string
  'icons-background': string
  'icons-background-dark': string
}

const themes: Theme[] = [
  {
    app: 'transporte-cordoba',
    background: '#DCF8FF',
    header: '#47D9FF',
    'home-header': '#47D9FF',
    'home-header-buttons': '#FFFFFF',
    'home-points-background': '#0039a4',
    'home-buttons': '#7FB7F2',
    'home-buttons-dark': '#5c8bc7',
    'icons-background': '#7FB7F2',
    'icons-background-dark': '#009EFF'
  }, {
    app: 'intercordoba',
    background: '#EFF5ED',
    header: '#ED3237',
    'home-header': '#FFFFFF',
    'home-header-buttons': '#ED3237',
    'home-points-background': '#ED3237',
    'home-buttons': '#A6A8AB',
    'home-buttons-dark': '#c69e29',
    'icons-background': '#A6A8AB',
    'icons-background-dark': '#ED3237'
  }, {
    app: 'jesus-maria',
    background: '#f7fbef',
    header: '#dd782e',
    'home-header': '#dd782e',
    'home-header-buttons': '#FFFFFF',
    'home-points-background': '#99cc33',
    'home-buttons': '#dd782e',
    'home-buttons-dark': '#b16025',
    'icons-background': '#99cc33',
    'icons-background-dark': '#57a41d'
  }, {
    app: 'mendiolaza',
    background: '#EFF5ED',
    header: '#39861C',
    'home-header': '#FFFFFF',
    'home-header-buttons': '#39861C',
    'home-points-background': '#39861C',
    'home-buttons': '#F8C633',
    'home-buttons-dark': '#c69e29',
    'icons-background': '#F8C633',
    'icons-background-dark': '#c69e29'
  }
]

export default themes