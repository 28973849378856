import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import RegisterHeader from '../../../components/RegisterHeader'
import DocumentIcon from '../../../assets/icons/DocumentIcon'

const RegisterDocument = () => {

  return (
    <>
      <Helmet>
        <title>Qaixa: Registrar Documento</title>
      </Helmet>

      <section className="RegisterDocument register-container">
        <RegisterHeader title="Registro" step={6}/>

        <div className="d-flex flex-column align-items-center gap-2">
          <DocumentIcon className='RegisterDocument__Icon'/>
          <h1 className='h4 fw-semibold'>
            Ahora vamos a validar tu identidad con los datos de tu documento
          </h1>
          <span className="text-2 text-gray-dark">
            Ten en cuenta tener tu documento de identidad, estar en un lugar con buena luz, y tener el rostro descubierto
          </span>
        </div>

        <div className="w-100 d-flex flex-column align-items-center gap-2">
          <Link
            to='/register/validate-document'
            replace
            className='button-primary'
          >
            Comenzar validación
          </Link>
          {/* <Link
            to='/login'
            replace
            className='text-3 link-secondary'
          >
            Validar más tarde
          </Link> */}
        </div>
      </section>
    </>
  )
}

export default RegisterDocument