import { memo, SVGProps } from 'react'

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#10203E">
      <path d="M9.6.8a.8.8 0 0 1 .8-.8h3.2A2.4 2.4 0 0 1 16 2.4v11.2a2.4 2.4 0 0 1-2.4 2.4h-3.2a.8.8 0 0 1 0-1.6h3.2a.8.8 0 0 0 .8-.8V2.4a.8.8 0 0 0-.8-.8h-3.2a.8.8 0 0 1-.8-.8Z" />
      <path d="M5.834 3.434a.8.8 0 0 1 1.132 0l4 4a.8.8 0 0 1 0 1.132l-4 4a.8.8 0 1 1-1.132-1.132L9.27 8 5.834 4.566a.8.8 0 0 1 0-1.132Z" />
      <path d="M0 8a.8.8 0 0 1 .8-.8h9.6a.8.8 0 0 1 0 1.6H.8A.8.8 0 0 1 0 8Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(LogoutIcon)