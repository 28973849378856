import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  className?: string
  type?: 'primary' | 'secondary' | 'white' | 'black' | 'info' | 'error'
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
  isSubmit?: boolean
}

const Button = ({
  children,
  className,
  type = 'primary',
  disabled = false,
  loading = false,
  onClick,
  isSubmit = false,
}: Props) => {
  return (
    <button
      className={classNames(
        className,
        `button-${type}`
      )}
      onClick={onClick}
      disabled={disabled || loading}
      type={isSubmit ? 'submit' : 'button'}
    >
      { children }
    </button>
  )
}

export default Button