import { memo, SVGProps } from 'react'

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.012 15.012a1 1 0 0 1-.707-1.707l5.293-5.293L4.305 2.72A1 1 0 1 1 5.72 1.305l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-.707.293Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ArrowRightIcon)