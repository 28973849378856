import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { motion } from 'framer-motion'
import { createTicket } from '../../api/claims'
import useAuthContext from '../../hooks/useAuthContext'
import AuthHeader from '../../containers/AuthHeader'
import Button from '../../components/Button'
import InputSelector from '../../components/InputSelector'
import Textarea from '../../components/Textarea'
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon'
import Image from '../../assets/images/check-bubble.png'
import Logo from '../../assets/images/logo-blue.png'

const CreateTicket = () => {
  const { token } = useAuthContext()
  const [ showSuccess, setShowSuccess ] = useState(false)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      service: '',
      title: '',
      description: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      service: Yup.string().required('Este campo es requerido'),
      title: Yup.string().required('Este campo es requerido'),
      description: Yup.string().required('Este campo es requerido')
    }),
    onSubmit: async (values) => {
      const response = await createTicket(token, values)

      if(response?.data) {
        setShowSuccess(true)
      }
    }
  })

  const handleGoBack = () => {
    navigate(-1)
  }

  const servicesList = [
    {
      label: 'Transporte Córdoba',
      value: 'transporte-cordoba'
    }, {
      label: 'Transporte Intercordoba',
      value: 'intercordoba'
    }, {
      label: 'Jesus María',
      value: 'jesus-maria'
    }, {
      label: 'Mendiolaza',
      value: 'mendiolaza'
    }
  ]

  const titleList = [
    {
      label: 'Asistencia',
      value: 'assitance'
    }, {
      label: 'Reclamo',
      value: 'claim'
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Iniciar un Ticket</title>
      </Helmet>

      <AuthHeader title='Iniciar un ticket'/>

      <div className="Menu__Content justify-content-between">
        <div className="Menu__Group gap-5">
          <h3 className='text-2 fw-semibold'>Iniciar un ticket</h3>

          <div className="d-flex flex-column gap-3">
            <InputSelector
              placeholder='Elegir servicio'
              list={servicesList}
              value={formik.values.service}
              onChange={(value) => formik.setFieldValue('service', value)}
              error={formik.errors.service}
            />

            <InputSelector
              placeholder='Elegir asunto'
              list={titleList}
              value={formik.values.title}
              onChange={(value) => formik.setFieldValue('title', value)}
              error={formik.errors.title}
            />

            <Textarea
              name='description'
              placeholder='Descripción'
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.errors.description}
            />
          </div>
        </div>

        <div className="d-flex flex-column align-items-center gap-2">
          <Button
            type='primary'
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
            isSubmit
          >
            Enviar ticket de reclamo
          </Button>

          <button className='link-primary d-flex align-items-center gap-1 text-4' onClick={handleGoBack}>
            <ArrowLeftIcon/>
            <span className="text-3">Volver</span>
          </button>
        </div>
      </div>

      <motion.div
        className="CreateTicket__Success"
        initial={{ translateY: '100%' }}
        animate={{ translateY: showSuccess ? '0%' : '100%' }}
        transition={{ duration: 0.2 }}
      >
        <img
          className='CreateTicket__Logo'
          src={Logo}
          alt='Qaixa'
          title='Qaixa'
        />

        <div className="d-flex flex-column align-items-center gap-3">
          <img
            className='CreateTicket__Bubble'
            src={Image}
            alt="Éxito"
            title="Éxito"
          />

          <span className='text-1 fw-medium text-green'>
            ¡Tu ticket de reclamo fue<br/>
            iniciado correctamente!
          </span>
        </div>

        <div className="w-100 d-flex flex-column align-items-center gap-3">
          <Link to='/menu/support' className='button-primary' replace>
            Ver mis reclamos
          </Link>
          <Link to='/manu/profile' className='link-primary d-flex align-items-center gap-1' replace>
            <ArrowLeftIcon/>
            Volver al perfil
          </Link>
        </div>
      </motion.div>
    </>
  )
}

export default CreateTicket