import { SVGProps, memo } from 'react'

const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.476 1.75H1.524A1.527 1.527 0 0 0 0 3.265v9.47a1.52 1.52 0 0 0 1.524 1.515h12.952A1.527 1.527 0 0 0 16 12.735v-9.47a1.52 1.52 0 0 0-1.524-1.515Zm-.192 3.22L8 9.137 1.714 4.97V3.455L8 7.62l6.286-4.166-.002 1.515Z"
    />
  </svg>
)

export default memo(EmailIcon)