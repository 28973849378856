import Logo from '../assets/images/logo-blue.png'

interface Props {
  title: string
  step: number
}

const RegisterHeader = ({ title, step }: Props) => {
  return (
    <header className='RegisterHeader'>
      <img
        className='RegisterHeader__Logo'
        src={Logo}
        alt="Qaixa"
        title="Qaixa"
      />

      <h1 className='text-1 text-gray-dark fw-normal text-end d-flex flex-column'>
        { title }
        <span className="text-primary">Paso { step } de 7</span>
      </h1>
    </header>
  )
}

export default RegisterHeader