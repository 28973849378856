import { Helmet } from 'react-helmet'
import Button from '../../components/Button'
import UserProfile from '../../components/UserProfile'
import CopyIcon from '../../assets/icons/CopyIcon'
import EditIcon from '../../assets/icons/EditIcon'
import UserIcon from '../../assets/icons/UserIcon'
import GenderIcon from '../../assets/icons/GenderIcon'
import CardIcon from '../../assets/icons/CardIcon'
import useAuthContext from '../../hooks/useAuthContext'
import useAppContext from 'hooks/useAppContext'

const Profile = () => {
  const { user } = useAuthContext()
  const { card } = useAppContext()

  const items = [
    {
      id: 1,
      icon: <UserIcon/>,
      data: user.first_name + ' ' + user.last_name,
      copy: false,
      edit: false
    }, {
      id: 2,
      icon: <GenderIcon/>,
      data: user.sex,
      copy: false,
      edit: false
    }, {
      id: 3,
      icon: <CardIcon/>,
      data: user.document_number,
      copy: false,
      edit: false
    }, {
      id: 4,
      icon: false,
      data: user.alias || 'Todavía no tenés un alias',
      // copy: true,
      copy: false,
      // edit: true
      edit: false
    }, {
      id: 5,
      icon: false,
      data: user.address,
      copy: false,
      // edit: true
      edit: false
    }, {
      id: 6,
      icon: false,
      data: user.email,
      copy: false,
      // edit: true
      edit: false
    }, {
      id: 7,
      icon: false,
      data: card.card_number || 'Todavía no tenés una tarjeta',
      copy: false,
      // edit: true
      edit: false
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Perfil</title>
      </Helmet>

      <div className="Menu__Content gap-5">
        <div className="d-flex flex-column gap-4">
          <UserProfile isEditable/>

          <div className="Menu__Group">
            <h3 className='text-1 fw-medium text-gray-dark'>Datos personales</h3>

            <div className="Menu__List">
              {items.map(item => {
                if(item.copy) return (
                  <div className="Profile__Item--Edit" key={`Profile-Item-Copy-${item.id}`}>
                    <span className='text-3 fw-medium text-primary'>{ item.data }</span>

                    <div className="d-flex align-items-center gap-2">
                      <button className="Profile__Edit-Button">
                        <CopyIcon/>
                      </button>
                      <button className="Profile__Edit-Button">
                        <EditIcon/>
                      </button>
                    </div>
                  </div>
                )
                else if(item.edit) return (
                  <div className="Profile__Item--Edit" key={`Profile-Item-Edit-${item.id}`}>
                    <span className='text-3 fw-medium text-primary'>{ item.data }</span>

                    <button className="Profile__Edit-Button">
                      <EditIcon/>
                    </button>
                  </div>
                )
                else return (
                  <div className="Profile__Item" key={`Profile-Item-${item.id}`}>
                    {item.icon}
                    <span className='text-3 fw-medium text-primary'>{ item.data }</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <Button disabled>
          Confirmar cambios
        </Button>
      </div>
    </>
  )
}

export default Profile