import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Logo from '../assets/images/logo-blue.png'
import CheckBubble from '../assets/images/check-bubble.png'
import Button from 'components/Button'

interface Props {
  show: boolean
  message: string
  nextStep: string
  children: React.ReactNode
}

const variants = {
  open: { translateY: 0 },
  closed: { translateY: '100%' },
}

const RegisterSuccess = ({ show, message, nextStep, children }: Props) => {
  const navigate = useNavigate()

  const handleLink = () => {
    if(nextStep.includes('login')) {
      localStorage.removeItem('token')
    }

    navigate(nextStep, {
      replace: true
    })
  }

  return (
    <motion.div
      className="RegisterSuccess"
      initial={variants.closed}
      animate={show ? 'open' : 'closed'}
      transition={{ duration: 0.3 }}
      variants={variants}
    >
      <img
        className="RegisterSuccess__Logo"
        src={Logo}
        alt="Qaixa"
        title="Qaixa"
      />

      <div className="d-flex flex-column align-items-center gap-4">
        <img
          className="RegisterSuccess__Check"
          src={CheckBubble}
          alt="Éxito"
          title="Éxito"
        />

        <h2 className='h5 fw-semibold text-green'>{ message }</h2>

        { children }
      </div>

      <Button type='primary' onClick={handleLink}>
        {nextStep.includes('login') ? 'Finalizar' : 'Continuar'}
      </Button>
    </motion.div>
  )
}

export default RegisterSuccess