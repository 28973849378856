import { SVGProps, memo } from 'react'

const CardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#clip0_67_92)">
        <mask
          id="mask0_67_92"
          style={{ maskType: 'luminance' }}
          width="16"
          height="16"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M15.5 0H.5v16h15V0z"></path>
        </mask>
        <g mask="url(#mask0_67_92)">
          <path
            fill="currentColor"
            d="M4.213 16v-1.419h-1.46v-.98h1.459c0-.423 0-.828-.006-1.232 0-.033-.077-.083-.124-.092a4.147 4.147 0 01-2.432-1.275A4.003 4.003 0 01.51 7.885a4.095 4.095 0 012.516-3.613 3.795 3.795 0 011.717-.346.223.223 0 00.22-.127 4.11 4.11 0 013.583-2.045 4.041 4.041 0 012.85 1.093l.184.168 2.155-2.04h-1.094V0h2.856v2.783h-.97V1.575l-2.322 2.197c.13.317.276.613.375.92a4.191 4.191 0 01-2.119 5.005c-.59.297-1.242.45-1.902.449a.277.277 0 00-.277.149 4.182 4.182 0 01-2.985 1.983c-.042.008-.106.061-.107.092-.008.4-.005.8-.005 1.216h1.525v.983H5.183v1.425l-.97.006zm4.578-6.85a3.217 3.217 0 002.992-2.883 3.208 3.208 0 00-2.357-3.42 3.156 3.156 0 00-3.466 1.26A4.227 4.227 0 018.79 9.15zM4.487 4.925a3.216 3.216 0 00-2.62 1.67 3.206 3.206 0 001.132 4.261 3.216 3.216 0 004.323-.889 4.222 4.222 0 01-2.835-5.043zm1.009.085a3.174 3.174 0 002.281 4.04 3.191 3.191 0 00-2.281-4.04z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_67_92">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default memo(CardIcon)