import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import Logo from '../../assets/images/logo-blue.png'
import ImageOne from '../../assets/images/onboarding-1.png'
import ImageTwo from '../../assets/images/onboarding-2.png'

const OnBoarding = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Inicio</title>
      </Helmet>

      <section className="d-flex flex-column justify-content-between align-items-center py-4 px-3 gap-3 overflow-hidden">
        <img
          className='OnBoarding__Logo'
          src={Logo}
          alt="Qaixa"
          title="Qaixa"
        />

        <Swiper
          className='OnBoarding__Scroll'
          modules={[ Pagination, Autoplay ]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true
          }}
        >
          <SwiperSlide className='OnBoarding__Scroll-Item'>
            <img
              className='OnBoarding__Image-One'
              src={ImageOne}
              alt=""
              title="Ingresa para disfrutar todas las posibilidades de Qaixa"
            />

            <h1 className='h5 fw-semibold'>Ingresa para disfrutar todas las posibilidades de Qaixa</h1>
          </SwiperSlide>
          <SwiperSlide className='OnBoarding__Scroll-Item'>
            <img
              className='OnBoarding__Image-Two'
              src={ImageTwo}
              alt=""
              title="Aprovechá tus puntos para acceder a las mejores oportunidades de la ciudad en compras y servicios"
            />

            <h1 className='h5 fw-semibold'>Aprovechá tus puntos para acceder a las mejores oportunidades de la ciudad en compras y servicios</h1>
          </SwiperSlide>
        </Swiper>

        <div className="w-100 d-flex flex-column gap-2">
          <Link
            to="/login"
            className='button-primary'
          >
            Iniciar sesión
          </Link>

          <Link
            to="/register"
            className='button-white'
          >
            Registrate
          </Link>
        </div>
      </section>
    </>
  )
}

export default OnBoarding