import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { passwordChange } from '../../../api/auth'
import useAuthContext from '../../../hooks/useAuthContext'
import RegisterHeader from '../../../components/RegisterHeader'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import RegisterSuccess from '../../../containers/RegisterSuccess'
import LockIcon from '../../../assets/icons/LockIcon'

const RegisterPassword = () => {
  const { token } = useAuthContext()
  const [ responseState, setResponseState ] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      password: Yup.string().trim().required('La contraseña es requerida'),
      repeatPassword: Yup.string().trim().required('La contraseña es requerida').oneOf([Yup.ref('password'), null], 'Las contraseñas no son iguales')
    }),
    validate: ({ password }) => {
      const errors = {
        password: '',
        repeatPassword: ''
      }

      const uppercaseRegex = RegExp(/[A-Z]/)
      const lowercaseRegex = RegExp(/[a-z]/)
      const symbolRegex = RegExp(/[\W_]/)

      if(password.length === 0) {
        errors.password = 'La contraseña es requerida'
        return errors
      } else if(password.length < 8) {
        errors.password = 'La contraseña debe contener al menos 8 caracteres'
        errors.repeatPassword = 'La contraseña debe contener al menos 8 caracteres'
        return errors
      } else if(uppercaseRegex.test(password) === false) {
        errors.password = 'La contraseña debe contener al menos una mayúscula'
        errors.repeatPassword = 'La contraseña debe contener al menos una mayúscula'
        return errors
      } else if(lowercaseRegex.test(password) === false) {
        errors.password = 'La contraseña debe contener al menos una minúscula'
        errors.repeatPassword = 'La contraseña debe contener al menos una minúscula'
        return errors
      } else if(symbolRegex.test(password) === false) {
        errors.password = 'La contraseña debe contener al menos un símbolo'
        errors.repeatPassword = 'La contraseña debe contener al menos un símbolo'
        return errors
      }
    },
    onSubmit: async (values, { setErrors }) => {
      const response = await passwordChange(token, {
        new_password1: values.password,
        new_password2: values.repeatPassword
      })

      if(
        response?.new_password2?.length &&
        response?.new_password2[0]?.includes('The password is too similar to the username')
      ) {
        setErrors({
          password: 'La contraseña es muy similar al nombre de usuario',
          repeatPassword: 'La contraseña es muy similar al nombre de usuario'
        })
      }

      if(response?.detail) {
        setResponseState(true)
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Qaixa: Crear Contraseña</title>
      </Helmet>

      <section className="RegisterPassword register-container">
        <RegisterHeader title="Registro" step={2}/>

        <div className="d-flex flex-column align-items-center gap-2">
          <LockIcon className="RegisterPassword__Icon"/>
          <h1 className='h4 fw-semibold'>Ahora vamos a crear una contraseña</h1>
          <span className="text-3">
            Por seguridad, tu contraseña debe
            contener al menos 8 digítos que incluya al menos
            1 mayúscula, 1 minúscula y 1 símbolo
          </span>
          <Input
            name='password'
            type='password'
            placeholder='Contraseña'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.errors.password}
          />
          <Input
            name='repeatPassword'
            type='password'
            placeholder='Repetir contraseña'
            value={formik.values.repeatPassword}
            onChange={formik.handleChange}
            error={formik.errors.repeatPassword}
          />
        </div>

        <div className="w-100 d-flex flex-column align-items-center gap-2">
          <Button
            type='primary'
            onClick={formik.handleSubmit}
            isSubmit
            disabled={formik.isSubmitting}
          >
            Continuar
          </Button>
        </div>
      </section>

      <RegisterSuccess
        show={responseState}
        message='Excelente'
        nextStep='/register/phone'
      >
        <span className="text-2 fw-medium">
          Haz creado tu contraseña con éxito
        </span>
      </RegisterSuccess>
    </>
  )
}

export default RegisterPassword