import Button from '../Button'
import Modal from './Modal'
import BubbleImage from '../../assets/images/info-bubble.png'

interface Props {
  closeModal: () => void
  handleOpenConfirmModal: () => void
}

const DeleteCardModal = ({ closeModal, handleOpenConfirmModal }: Props) => {
  const handleDeleteCard = () => {
    handleOpenConfirmModal()
  }

  return (
    <Modal
      className='DeleteCardModal'
      closeModal={closeModal}
    >
      <img
        className='DeleteCardModal__Image'
        src={BubbleImage}
        alt="Iniciar solicitud"
        title="Iniciar solicitud"
      />

      <h3 className='h4 my-3 fw-medium'>¿Estás seguro de eliminar tu tarjeta?</h3>

      <div className="w-100 d-flex flex-column align-items-center gap-1">
        <Button type='error' onClick={handleDeleteCard}>
          Eliminar
        </Button>
        <button className="link-primary text-3" onClick={closeModal}>
          Cancelar
        </button>
      </div>
    </Modal>
  )
}

export default DeleteCardModal