import { Link } from 'react-router-dom'
import useAuthContext from '../hooks/useAuthContext'
import useAppContext from '../hooks/useAppContext'
import UserImage from '../assets/images/user.png'
import MenuIcon from '../assets/icons/MenuIcon'
import Logo from '../assets/images/logo-white.png'
import BellIcon from '../assets/icons/BellIcon'

const StartHeader = () => {
  const { user } = useAuthContext()
  const { setShowMenu } = useAppContext()

  const handleOpenMenu = () => {
    setShowMenu(true)
  }

  return (
    <header className="StartHeader">
      <img
        className="StartHeader__Logo"
        src={Logo}
        alt='Qaixa'
        title='Qaixa'
      />

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex flex-column text-end">
          <span className='text-4'>Hola,</span>
          <span className='text-4'>{ user?.first_name }!</span>
        </div>

        <img
          className="StartHeader__Profile-Image"
          src={user?.profile_image ? `${process.env.REACT_APP_BACK_URL}${user?.profile_image}` : UserImage}
          alt={`${user?.first_name} ${user?.last_name}`}
          title={`${user?.first_name} ${user?.last_name}`}
        />

        <Link to='/menu/notifications' className="StartHeader__Button">
          <BellIcon/>
        </Link>

        <button className="StartHeader__Button" onClick={handleOpenMenu}>
          <MenuIcon/>
        </button>
      </div>
    </header>
  )
}

export default StartHeader