import { useEffect } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router'
import useAuthContext from 'hooks/useAuthContext'
import Header from 'containers/Header'
import Menu from 'containers/Menu'

const Layout = () => {
  const { token, firstCharge, user } = useAuthContext()
  const location = useLocation()
  const navigate = useNavigate()

  // If user is not logged in, redirect to login page
  useEffect(() => {
    if(!firstCharge && !token) {
      navigate('/onboarding')
    }
  }, [token, firstCharge, user, location.pathname])

  // Scroll to top when page is loaded
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className='home-container'>
      <Header/>
      <Menu/>
      {/* Outlet is the content of the page */}
      <Outlet/>
    </div>
  )
}

export default Layout