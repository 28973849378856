import Button from 'components/Button'
import AuthHeader from 'containers/AuthHeader'
import { Helmet } from 'react-helmet'

const ClaimDetail = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Detalle de ticket</title>
      </Helmet>

      <AuthHeader title='Detalle de ticket'/>

      <section className="ClaimDetail">
        <div className="Menu__Group gap-4">
          <h3 className='text-1 text-gray-dark fw-semibold text-center'>dd/mm/yy - hh:mm</h3>

          <div className="d-flex flex-column text-2">
            <div className="ClaimDetail__Item">
              <span className="fw-medium">Servicio:</span> Transporte Córdoba
            </div>

            <div className="ClaimDetail__Item">
              <span className="fw-medium">Asunto:</span> Asistencia
            </div>

            <div className="ClaimDetail__Item d-flex flex-column gap-2">
              <span className="fw-medium">Descripción:</span>
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. At, sed veritatis! Rem alias enim minima at sint quos impedit odit, nemo commodi ab culpa et veritatis iste neque sapiente dolore?
                Officia ab accusantium vitae modi quae quam excepturi necessitatibus iste voluptatibus iure vel non impedit obcaecati rem repudiandae autem at unde, delectus doloremque sunt odit hic voluptates tempora error. Modi?
              </span>
            </div>

            <div className="ClaimDetail__Item">
              <span className="fw-medium">Estado:</span> Resuelto
            </div>

            <div className="ClaimDetail__Item d-flex flex-column gap-2">
              <span className="fw-medium">Respuesta:</span>
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. At, sed veritatis! Rem alias enim minima at sint quos impedit odit, nemo commodi ab culpa et veritatis iste neque sapiente dolore?
                Officia ab accusantium vitae modi quae quam excepturi necessitatibus iste voluptatibus iure vel non impedit obcaecati rem repudiandae autem at unde, delectus doloremque sunt odit hic voluptates tempora error. Modi?
              </span>
            </div>
          </div>
        </div>

        <Button type='secondary'>Volver</Button>
      </section>
    </>
  )
}

export default ClaimDetail