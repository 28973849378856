import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import { updateUser } from '../../../api/users'
import useAuthContext from '../../../hooks/useAuthContext'
import RegisterHeader from '../../../components/RegisterHeader'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import CheckIcon from '../../../assets/icons/CheckIcon'
import RegisterIcon from '../../../assets/icons/RegisterIcon'

const variants = {
  open: { height: '18px', opacity: 1 },
  closed: { height: 0, opacity: 0 },
}

const RegisterName = () => {
  const { token } = useAuthContext()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      birthdate: '',
      document_number: '',
      sex: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      first_name: Yup.string().trim().required('El nombre es requerido'),
      last_name: Yup.string().trim().required('El apellido es requerido'),
      birthdate: Yup.string().trim().required('La fecha de nacimiento es requerida'),
      document_number: Yup.string().trim().required('El DNI es requerido'),
      sex: Yup.string().trim().required('El género es requerido'),
    }),
    onSubmit: async (values) => {
      // const user = JSON.parse(localStorage.getItem('user'))
      // user.first_name = values.first_name
      // user.last_name = values.last_name
      // user.birthdate = values.birthdate
      // user.document_number = values.document_number
      // user.sex = values.sex
      // user.alias = `${values.first_name} ${values.last_name}`
      // localStorage.setItem('user', JSON.stringify(user))


      const response = await updateUser(token, values)

      if(response?.user?.id) {
        navigate('/register/location', {
          replace: true
        })
      } else if(response?.document_number?.length) {
        if(response?.document_number[0]?.includes('User profile with this document number already exists')) {
          formik.setErrors({
            document_number: 'Este DNI no esta disponible'
          })
        }
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Qaixa: Registrar Nombre</title>
      </Helmet>

      <section className="RegisterName register-container">
        <RegisterHeader title="Registro" step={4}/>

        <div className="w-100 d-flex flex-column align-items-center gap-3">
          <RegisterIcon className='RegisterName__Icon'/>
          <h1 className='h4 fw-semibold'>Completa con tus datos personales</h1>

          <div className="w-100 d-flex flex-column gap-2">
            <Input
              name='first_name'
              placeholder='Nombre'
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={formik.errors.first_name}
            />
            <Input
              name='last_name'
              placeholder='Apellido'
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={formik.errors.last_name}
            />
            <Input
              name='birthdate'
              placeholder='Fecha de nacimiento'
              type='date'
              value={formik.values.birthdate}
              onChange={formik.handleChange}
              error={formik.errors.birthdate}
            />
            <Input
              name='document_number'
              placeholder='DNI'
              mode='numeric'
              value={formik.values.document_number}
              onChange={formik.handleChange}
              error={formik.errors.document_number}
            />
          </div>

          <div className="w-100 d-flex flex-column">
            <div className="Register__Checkboxes d-flex align-items-center gap-5">
              <span className='text-3 fw-semibold'>Sexo</span>

              <div className="d-flex gap-4">
                <button
                  className="Register__Checkbox-Container"
                  onClick={() => formik.setFieldValue('sex', 'Femenino')}
                  type='button'
                >
                  <span className="Input__Placeholder text-4">F</span>
                  <div className={classNames(
                    'Register__Checkbox',
                    { 'Register__Checkbox--Checked': formik.values.sex == 'Femenino' ? true : false }
                  )}>
                    <CheckIcon/>
                  </div>
                </button>

                <button
                  className="Register__Checkbox-Container"
                  onClick={() => formik.setFieldValue('sex', 'Masculino')}
                  type='button'
                >
                  <span className="Input__Placeholder text-4">M</span>
                  <div className={classNames(
                    'Register__Checkbox',
                    { 'Register__Checkbox--Checked': formik.values.sex == 'Masculino' ? true : false }
                  )}>
                    <CheckIcon/>
                  </div>
                </button>
              </div>
            </div>
            <motion.div
              className="Input__Error text-5"
              initial={variants.closed}
              animate={formik.errors.sex ? 'open' : 'closed'}
              transition={{ duration: 0.1, ease: 'linear' }}
              style={{ transformStyle: 'preserve-3d' }}
              variants={variants}
              dangerouslySetInnerHTML={{ __html: formik.errors.sex }}
            >
            </motion.div>
          </div>

        </div>

        <div className="w-100 d-flex flex-column align-items-center gap-2">
          <Button
            type='primary'
            onClick={formik.handleSubmit}
            isSubmit
            disabled={formik.isSubmitting}
          >
            Continuar
          </Button>
        </div>
      </section>
    </>
  )
}

export default RegisterName