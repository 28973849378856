import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet'
import useAppContext from 'hooks/useAppContext'
import HomeTemplate from 'templates/HomeTemplate'
import StoresSlider from 'containers/StoresSlider'
import Shortcuts from 'containers/Shortcuts'
import ContactBookIcon from 'assets/icons/ContactBookIcon'
import MovementsIcon from 'assets/icons/MovementsIcon'
// import useAppContext from 'hooks/useAppContext'

const Home = () => {
  // const { card } = useAppContext()
  const { currentApp } = useAppContext()
  const navigate = useNavigate()

  // Redirect to AddCard if there is no card
  useEffect(() => {
    const card = JSON.parse(localStorage.getItem('card'))

    // if(!card) {
    //   navigate('/transporte-cordoba/add-card', {
    //     replace: true
    //   })
    // }
  }, [])

  const shortcuts = [
    {
      title: 'Mis movimientos',
      icon: <MovementsIcon/>,
      link: `/${currentApp}/movements`
    }, {
      title: 'Mi agenda',
      icon: <ContactBookIcon/>,
      link: '/menu/contact-book'
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Impuestos Jesús María</title>
      </Helmet>

      <HomeTemplate>
        <StoresSlider/>

        <Shortcuts shortcuts={shortcuts}/>
      </HomeTemplate>
    </>
  )
}

export default Home