import { Route, Routes } from 'react-router'
// Layouts
import Layout from 'layouts/Layout'
// Taxes app pages
import Home from 'pages/services/jesus-maria/Home'
// 404 error page
import NotFound from 'pages/error/NotFound'

const MendiolazaAppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Home />} />
      </Route>

      {/* 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default MendiolazaAppRouter