import { Helmet } from 'react-helmet'
import QRIcon from 'assets/icons/QRIcon'
import DownloadIcon from 'assets/icons/DownloadIcon'
import CopyIcon from 'assets/icons/CopyIcon'

const MyQR = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Añadir Contacto</title>
      </Helmet>

      <section className="MyQR Menu__Content app-background">
        <div className="MyQR__Container card">
          <div className="d-flex flex-column align-items-center gap-3">
            <h2 className='h5 fw-semibold'>Mi QR</h2>

            <QRIcon className='MyQR__QR'/>

            {/* <div className="MyQR__Code">
              <h1 className='h1'>CODEID</h1>
            </div> */}
          </div>

          <span className='text-4 text-gray-dark'>
            Usá tu código QR para recibir puntos<br/>
            de tus amigos y así poder utilizar<br/>
            los beneficios de logoipsum!
          </span>

          <div className='MyQR__Code'>
            <span className="text-3 fw-medium">ABCDEFGHIJKLM12345</span>
          </div>

          <button className="MyQR__Copy">
            <CopyIcon/>
            Copiar IQAN
          </button>
        </div>

        <button className='MyQR__Button text-2 fw-medium'>
          <DownloadIcon/>
          Descargar
        </button>
      </section>
    </>
  )
}

export default MyQR