import { RefObject } from 'react'
import { motion } from 'framer-motion'
import useAppContext from '../hooks/useAppContext'

interface Props {
  inputRef?: RefObject<HTMLInputElement>
  name?: string
  value?: any
  onChange?: any
  error?: any
}

const variants = {
  open: { height: '18px', opacity: 1 },
  closed: { height: 0, opacity: 0 },
}

const InputPoints = ({ inputRef, name, value = undefined, onChange, error }: Props) => {
  const { points } = useAppContext()

  if(onChange) return (
    <div className="InputPoints">
      <div className='InputPoints__Container'>
        <h1 className="h1 text-end">PTS</h1>

        {/* <div className="InputPoints__Divisor"></div> */}

        {onChange ? (
          <input
            ref={inputRef}
            id={name}
            name={name}
            value={value}
            className='InputPoints__Input h1'
            placeholder='00,00'
            type="number"
            inputMode='numeric'
            onChange={onChange}
            max={points}
          />

        ) : (
          <div className="InputPoints__Input h1">{ value }</div>
        )}
      </div>

      {!error && <span className="Input__Tip text-4">Tenes { points } {points === 1 ? 'punto' : 'puntos'}</span>}

      <motion.div
        className="Input__Error text-4"
        initial={variants.closed}
        animate={error ? 'open' : 'closed'}
        transition={{ duration: 0.1, ease: 'linear' }}
        style={{ transformStyle: 'preserve-3d' }}
        variants={variants}
        dangerouslySetInnerHTML={{ __html: error }}
      >
      </motion.div>
    </div>
  )

  return (
    <div className="InputPoints--NoChange">
      <div className='InputPoints__Container'>
        <h1 className="h1 text-end">PTS</h1>

        <div className="InputPoints__Input h1">{ value }</div>
      </div>

      {!error && <span className="Input__Tip text-4">Tenes { points } {points === 1 ? 'punto' : 'puntos'}</span>}

      { error && <div
        className="Input__Error text-4"
        style={{ height: '18px' }}
        dangerouslySetInnerHTML={{ __html: error }}
      >
      </div> }
    </div>
  )
}

export default InputPoints