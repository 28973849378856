import { memo, SVGProps } from 'react'

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="m14.635 15.766-2.94-2.94A7.198 7.198 0 0 1 .003 7.004 7.202 7.202 0 0 1 6.997.003a7.198 7.198 0 0 1 7.376 6.596 7.203 7.203 0 0 1-1.548 5.096l2.939 2.94a.798.798 0 1 1-1.129 1.131ZM1.618 7.21a5.595 5.595 0 0 0 6.618 5.493 5.59 5.59 0 0 0 2.863-1.479.888.888 0 0 1 .124-.123A5.593 5.593 0 1 0 2.27 4.58a5.595 5.595 0 0 0-.653 2.63Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(SearchIcon)