import { Link } from 'react-router-dom'
import useAppContext from '../hooks/useAppContext'
// import SendTokensIcon from '../assets/icons/SendTokensIcon'
// import RequestTokensIcon from '../assets/icons/RequestTokensIcon'
import QRIcon from '../assets/icons/QRIcon'
import ArrowTokensIcon from '../assets/icons/ArrowTokensIcon'

const HomePoints = () => {
  const { points, currentApp } = useAppContext()

  return (
    <div className="HomePoints">
      <h4 className='text-4'>Puntos disponibles</h4>
      <h1 className='h1'>PTS { points }</h1>

      <div className="HomePoints__Buttons">
        <div className="HomePoints__Button--Secondary">
          <Link to='/menu/contact-book' state='request-points'>
            <ArrowTokensIcon/>
          </Link>
          <span className="text-1 fw-medium">Solicitar</span>
        </div>

        <div className="HomePoints__Button--Primary">
          <Link to={`/${currentApp}/scan-qr`}>
            <QRIcon/>
          </Link>
          <span className="text-1 fw-semibold">Scan QR</span>
        </div>

        <div className="HomePoints__Button--Secondary">
          <Link to='/menu/contact-book' state='send-points'>
            <ArrowTokensIcon/>
          </Link>
          <span className="text-1 fw-medium">Enviar</span>
        </div>
      </div>
    </div>
  )
}

export default HomePoints