import { memo, SVGProps } from 'react'

const ShowedPasswordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={2}
      width={16}
      height={12}
    >
      <path d="M16 2H0v12h16V2Z" fill="#fff" />
    </mask>
    <g mask="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#10203E">
      <path d="M1.497 8a12.39 12.39 0 0 0 1.616 2.17c1.19 1.268 2.844 2.437 4.887 2.437 2.043 0 3.697-1.17 4.886-2.437A12.395 12.395 0 0 0 14.503 8a12.393 12.393 0 0 0-1.617-2.17C11.696 4.564 10.042 3.395 8 3.395c-2.043 0-3.697 1.169-4.886 2.437A12.392 12.392 0 0 0 1.497 8Zm13.837 0 .65-.326-.001-.002-.003-.005-.007-.015a6.337 6.337 0 0 0-.13-.238 13.854 13.854 0 0 0-1.897-2.578c-1.31-1.398-3.323-2.896-5.946-2.896-2.624 0-4.636 1.498-5.947 2.896A13.845 13.845 0 0 0 .157 7.414a8.282 8.282 0 0 0-.13.238l-.007.015-.002.005-.001.002.65.326-.65-.325a.727.727 0 0 0 0 .65L.666 8l-.65.325v.003l.003.005.008.015a5.01 5.01 0 0 0 .128.238 13.843 13.843 0 0 0 1.896 2.579C3.365 12.562 5.377 14.06 8 14.06c2.624 0 4.636-1.498 5.947-2.897a13.845 13.845 0 0 0 1.897-2.578 8.834 8.834 0 0 0 .129-.238l.007-.015.003-.005v-.002L15.334 8Zm0 0 .65.325a.728.728 0 0 0 0-.65l-.65.325Z" />
      <path d="M8 6.727a1.273 1.273 0 1 0 0 2.546 1.273 1.273 0 0 0 0-2.546ZM5.272 8a2.727 2.727 0 1 1 5.455 0 2.727 2.727 0 0 1-5.455 0Z" />
    </g>
  </svg>
)

export default memo(ShowedPasswordIcon)