import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useAuthContext from 'hooks/useAuthContext'
import useAppContext from 'hooks/useAppContext'
import SwiperHook from 'components/SwiperHook'
import Button from 'components/Button'
import InputPoints from 'components/InputPoints'
import Input from 'components/Input'
import CoinsImage from 'assets/images/coins.png'
import { checkPin } from 'api/users'

const ExchangePoints = () => {
  const { token } = useAuthContext()
  const { points, currentApp } = useAppContext()
  const inputRef = useRef(null)
  const [ swiper, setSwiper ] = useState(null)
  const [ error, setError ] = useState(null)
  const navigate = useNavigate()

  // Focus on input when component is mounted
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  const formik = useFormik({
    initialValues: {
      pin: null
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      pin: Yup
        .number()
        .nullable()
        .test(
          'is-four-digits',
          'El número debe tener exactamente 4 dígitos',
          value => Number.isInteger(value) && value.toString().length === 4
        )
        .required('El PIN es requerido')
    }),
    onSubmit: async ({ pin }, { setFieldError }) => {
      const response = await checkPin(token, String(pin))

      if(response?.message?.includes('pin is incorrect')) {
        setFieldError('pin', 'El PIN es incorrecto')
        return
      }

      if(response?.message?.includes('pin is correct')) {
        localStorage.setItem('points', String(points - 100))
        navigate(`/${currentApp}/movements/receipt/1`, {
          replace: true
        })
      }
    }
  })

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <>
      <Helmet>
        <title>Qaixa: Intercambiar Puntos</title>
      </Helmet>

      <section className="ExchangePoints app-background">
        <Swiper
          className='ExchangePoints__Swiper w-100 d-flex flex-column align-items-start gap-2'
          spaceBetween={32}
          slidesPerView={1}
          allowTouchMove={false}
        >
          <SwiperHook setSwiper={setSwiper}/>

          <SwiperSlide className='d-flex flex-column justify-content-center gap-2'>
            <div className="h-100 card text-center">
              <span className="text-4">Paso 1</span>

              <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                <span className="text-3 text-gray-dark">Destino</span>
                <span className="text-2 fw-medium">Exchange Test</span>
                <div className="ExchangePoints__Title-Detail"></div>
              </div>

              <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-2">
                <img
                  className='ExchangePoints__Coins'
                  src={CoinsImage}
                  alt="Solicitar puntos"
                  title='Solicitar puntos'
                />

                <h2 className='text-2 fw-medium'>Se descontará de tu billetera un total de:</h2>

                <InputPoints inputRef={inputRef} value={100} error={error}/>
              </div>

              <div className="d-flex flex-column gap-1">
                <Button type='secondary' onClick={() => {
                  if(points < 100) {
                    setError('No tenés puntos suficientes')
                  } else {
                    swiper.slideNext()
                  }
                }}>
                  Continuar
                </Button>
                <button className="link-primary text-3" onClick={handleGoBack}>
                  Cancelar
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='d-flex flex-column justify-content-center gap-2'>
            <div className="h-100 card text-center">
              <span className="text-4">Paso 2</span>

              <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-4">
                <span className="text-2 fw-medium">
                  Al ingresar tu PIN, confirmás<br/>
                  el envío de <span className="fw-bold">100</span> puntos a<br/>
                  <span className="fw-bold">Exchange Test</span>
                </span>

                <div className="w-100 d-flex flex-column justify-content-center align-items-center gap-2">
                  <Input
                    name='pin'
                    placeholder='Ingresá tu pin'
                    type='number'
                    mode='numeric'
                    value={formik.values.pin}
                    onChange={formik.handleChange}
                    error={formik.errors.pin}
                  />

                  <Link to='/menu/security' className='text-4 link-secondary'>
                    ¿Olvidaste tu pin?
                  </Link>
                </div>
              </div>

              <div className="d-flex flex-column gap-1">
                <Button type='secondary' onClick={formik.handleSubmit}>
                  Continuar
                </Button>
                <button className="link-primary text-3" onClick={handleGoBack}>
                  Cancelar
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  )
}

export default ExchangePoints