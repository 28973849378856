import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import AuthHeader from 'containers/AuthHeader'
import TransportLogo from 'assets/images/services/transporte-cordoba-logo.png'
import TaxesLogo from 'assets/images/services/jesus-maria-logo.png'

const ContactDetail = () => {
  const { id } = useParams()

  const services = [
    {
      name: 'Transporte Córdoba',
      link: `/menu/contact-book/transporte-cordoba/${id}`,
      logo: TransportLogo
    }, {
      name: 'Impuestos Jesús María',
      link: `/menu/contact-book/jesus-maria/${id}`,
      logo: TaxesLogo
    }
    // , {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: `/menu/contact-book/transporte-cordoba/${id}`,
    //   logo: Logo
    // }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Contacto</title>
      </Helmet>

      <AuthHeader title='Contacto'/>

      <section className="Menu__Content app-background">
        <div className="ContactDetail__Container card gap-4">
          <header className="ContactDetail__Profile">
            <img
              className='ContactDetail__Avatar'
              src='https://picsum.photos/20'
              alt='Name Lastname'
              title='Name Lastname'
            />
            <h1 className='text-1 fw-bold'>Name Lastname</h1>
            <span className="text-4 fw-semibold">user@email.com</span>
          </header>

          <div className="h-100 text-center overflow-hidden">
            <h2 className='text-3 fw-semibold pb-4'>Estos son los servicios que se encuentran disponibles en este usuario</h2>

            <div className="ContactDetail__Services">
              {services.map((service, index) => (
                <Link
                  key={`Start-Service-${index}`}
                  to={service.link}
                  className='Start__Service text-4'
                  state={{ service: service.name }}
                >
                  <img className='Start__Service-Image' src={service.logo} alt={service.name}/>
                  <span className="text-4 fw-medium">{ service.name }</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactDetail