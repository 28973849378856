import { useState } from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import Selector from '../../components/Selector'
import CheckIcon from '../../assets/icons/CheckIcon'

const Settings = () => {
  const [selected, setSelected] = useState<'' | 'auto' | 'manual'>('auto')

  const options = [
    {
      label: 'Español',
      value: 'spanish'
    }, {
      label: 'Inglés',
      value: 'english'
    }, {
      label: 'Portugués',
      value: 'portuguese'
    }, {
      label: 'Francés',
      value: 'french'
    }, {
      label: 'Alemán',
      value: 'german'
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Ajustes del Sistema</title>
      </Helmet>

      <div className="Menu__Content">
        <div className="Menu__Group">
          <h3 className='text-1 fw-semibold'>Zona horaria</h3>

          <div className="Menu__List">
            <button
              className={classNames('Menu__Option', { 'Menu__Option--Selected': selected === 'auto' })}
              onClick={() => setSelected('auto')}
            >
              <span className='text-2 fw-medium'>Zona horaria según ubicación</span>
              <CheckIcon className='text-green'/>
            </button>

            <button
              className={classNames('Menu__Option', { 'Menu__Option--Selected': selected === 'manual' })}
              onClick={() => setSelected('manual')}
            >
              <span className='text-2 fw-medium'>Configurar manualmente</span>
              <CheckIcon className='text-green'/>
            </button>
          </div>
        </div>

        <div className="Menu__Group">
          <h3 className='text-1 fw-semibold'>Lenguaje</h3>

          <div className="Menu__List">
            <Selector list={options} value='spanish'/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings