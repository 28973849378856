import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { changePin } from 'api/users'
import useAuthContext from 'hooks/useAuthContext'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Input from 'components/Input'
import Selector from 'components/Selector'
import SuccessModal from 'components/modals/SuccessModal'
import { passwordChange } from 'api/auth'

const Security = () => {
  const { token } = useAuthContext()
  const [ showPINSuccess, setShowPINSuccess ] = useState(false)
  const [ showPasswordSuccess, setShowPasswordSuccess ] = useState(false)

  const passwordFormik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required('La contraseña actual es requerida'),
      newPassword: Yup.string().required('La nueva contraseña es requerida'),
      confirmPassword: Yup.string().required('La nueva contraseña es requerida').oneOf([Yup.ref('newPassword'), null], 'Las contraseñas no son iguales'),
    }),
    validate: ({ newPassword }) => {
      const errors = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }

      const uppercaseRegex = RegExp(/[A-Z]/)
      const lowercaseRegex = RegExp(/[a-z]/)
      const symbolRegex = RegExp(/[\W_]/)

      if(newPassword.length === 0) {
        errors.newPassword = 'La contraseña es requerida'
        return errors
      } else if(newPassword.length < 8) {
        errors.newPassword = 'La contraseña debe contener al menos 8 caracteres'
        errors.confirmPassword = 'La contraseña debe contener al menos 8 caracteres'
        return errors
      } else if(uppercaseRegex.test(newPassword) === false) {
        errors.newPassword = 'La contraseña debe contener al menos una mayúscula'
        errors.confirmPassword = 'La contraseña debe contener al menos una mayúscula'
        return errors
      } else if(lowercaseRegex.test(newPassword) === false) {
        errors.newPassword = 'La contraseña debe contener al menos una minúscula'
        errors.confirmPassword = 'La contraseña debe contener al menos una minúscula'
        return errors
      } else if(symbolRegex.test(newPassword) === false) {
        errors.newPassword = 'La contraseña debe contener al menos un símbolo'
        errors.confirmPassword = 'La contraseña debe contener al menos un símbolo'
        return errors
      }
    },
    onSubmit: async (values, { setErrors, resetForm }) => {
      const response = await passwordChange(token, {
        old_password: values.currentPassword,
        new_password1: values.newPassword,
        new_password2: values.confirmPassword
      })

      console.log(response)

      if(response?.error?.includes('Old password is incorrect')) {
        setErrors({ currentPassword: 'La contraseña actual es incorrecta' })
      }

      if(response?.error?.includes('New password must be different from old password')) {
        setErrors({
          newPassword: 'La nueva contraseña debe ser diferente a la actual',
          confirmPassword: 'La nueva contraseña debe ser diferente a la actual'
        })
      }


      if(
        response?.new_password2?.length &&
        response?.new_password2[0]?.includes('The password is too similar to the username')
      ) {
        setErrors({
          newPassword: 'La contraseña es muy similar al nombre de usuario',
          confirmPassword: 'La contraseña es muy similar al nombre de usuario'
        })
      }

      if(response?.detail) {
        setShowPasswordSuccess(true)
        resetForm()
      }
    }
  })

  const pinFormik = useFormik({
    initialValues: {
      currentPin: null,
      newPin: null,
      confirmPin: null
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      currentPin: Yup
        .number()
        .nullable()
        .typeError('Ingrese solo números')
        .required('El PIN actual es requerido'),
      newPin: Yup
        .number()
        .nullable()
        .typeError('Ingrese solo números')
        .test(
          'is-four-digits',
          'El número debe tener exactamente 4 dígitos',
          value => Number.isInteger(value) && value.toString().length === 4
        )
        .required('El nuevo PIN es requerida'),
      confirmPin: Yup
        .number()
        .nullable()
        .typeError('Ingrese solo números')
        .required('El nuevo PIN es requerida')
        .oneOf([Yup.ref('newPin'), null], 'Los PINs no son iguales'),
    }),
    onSubmit: async (values, { setFieldError, resetForm }) => {
      const response = await changePin(token, {
        old_pin: String(values.currentPin),
        new_pin: String(values.newPin)
      })

      if(response?.message?.includes('old_pin is incorrect')) {
        setFieldError('currentPin', 'El PIN actual es incorrecto')
      }

      if(response?.message?.includes('Pin updated successfully')) {
        setShowPINSuccess(true)
        resetForm()
      }
    }
  })

  const options = [
    {
      label: 'Correo electrónico',
      value: 'email'
    }, {
      label: 'SMS',
      value: 'sms'
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Seguridad</title>
      </Helmet>

      <div className="Menu__Content">
        <div className="Menu__Group">
          <Dropdown title='Cambio de contraseña'>
            <Input
              type='password'
              name='currentPassword'
              placeholder='Contraseña actual'
              value={passwordFormik.values.currentPassword}
              onChange={passwordFormik.handleChange}
              error={passwordFormik.errors.currentPassword}
            />
            <Input
              type='password'
              name='newPassword'
              placeholder='Nueva contraseña'
              value={passwordFormik.values.newPassword}
              onChange={passwordFormik.handleChange}
              error={passwordFormik.errors.newPassword}
            />
            <Input
              type='password'
              name='confirmPassword'
              placeholder='Confirmar contraseña'
              value={passwordFormik.values.confirmPassword}
              onChange={passwordFormik.handleChange}
              error={passwordFormik.errors.confirmPassword}
            />
            <Button
              className='mt-3'
              // type='secondary'
              onClick={passwordFormik.handleSubmit}
              disabled={passwordFormik.isSubmitting || !passwordFormik.dirty}
            >
              Cambiar contraseña
            </Button>
          </Dropdown>

          <Dropdown title='Cambio de PIN'>
            <Input
              type='password'
              name='currentPin'
              placeholder='PIN actual'
              value={pinFormik.values.currentPin}
              onChange={pinFormik.handleChange}
              error={pinFormik.errors.currentPin}
            />
            <Input
              type='password'
              name='newPin'
              placeholder='Nuevo PIN'
              value={pinFormik.values.newPin}
              onChange={pinFormik.handleChange}
              error={pinFormik.errors.newPin}
            />
            <Input
              type='password'
              name='confirmPin'
              placeholder='Confirmar PIN'
              value={pinFormik.values.confirmPin}
              onChange={pinFormik.handleChange}
              error={pinFormik.errors.confirmPin}
            />
            <Button
              className='mt-3'
              // type='secondary'
              onClick={pinFormik.handleSubmit}
              disabled={pinFormik.isSubmitting || !pinFormik.dirty}
            >
              Cambiar PIN
            </Button>
          </Dropdown>

          <div className="d-flex flex-column gap-3 mt-2">
            <h3 className='text-1 fw-semibold'>Preferencias de 2FA</h3>

            <Selector list={options} value='email'/>
          </div>
        </div>
      </div>

      {showPINSuccess && (
        <SuccessModal
          closeModal={() => setShowPINSuccess(false)}
          message='¡Haz cambiado tu PIN!'
          buttonLabel='Continuar'
          handleButtonClick={() => setShowPINSuccess(false)}
        />
      )}

      {showPasswordSuccess && (
        <SuccessModal
          closeModal={() => setShowPasswordSuccess(false)}
          message='¡Haz cambiado tu contraseña!'
          buttonLabel='Continuar'
          handleButtonClick={() => setShowPasswordSuccess(false)}
        />
      )}
    </>
  )
}

export default Security