import { SVGProps, memo } from 'react'

const RegisterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M13.572 1.6h-3.326a2.374 2.374 0 0 0-4.488 0H2.43A1.6 1.6 0 0 0 .84 3.2v11.2A1.6 1.6 0 0 0 2.43 16h11.141a1.6 1.6 0 0 0 1.591-1.6V3.2a1.6 1.6 0 0 0-1.59-1.6Zm-5.57 0a.8.8 0 1 1 .006 1.6.8.8 0 0 1-.006-1.6Zm1.591 11.2h-5.57v-1.6h5.57v1.6Zm2.387-3.2H4.022V8h7.956l.002 1.6Zm0-3.2H4.022V4.8h7.956l.002 1.6Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(RegisterIcon)