import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import ArrowDownIcon from '../assets/icons/ArrowDownIcon'

interface Props {
  value?: string
  placeholder?: string
  list: Array<{
    label: string
    value: string
  }>
  error?: string | any
  onChange?: any
}

const selectorVariants = {
  open: { height: 'auto', paddingTop: '11px' },
  closed: { height: 0, paddingTop: '10px' }
}

const errorVariants = {
  open: { height: 'auto', opacity: 1 },
  closed: { height: 0, opacity: 0 },
}

const Selector = ({ value, placeholder, list, error, onChange }: Props) => {
  const [ showOptions, setShowOptions ] = useState(false)
  const [ optionSelected, setOptionSelected ] = useState(value)
  const selectorRef = useRef(null)

  // Find value in list
  useEffect(() => {
    if(value) {
      const option = list.find((item) => item.value === value)
      if (option) {
        setOptionSelected(option.label)
      }
    }
  }, [value])

  // Set focus on input when show list
  useEffect(() => {
    if (selectorRef.current) {
      if (showOptions) {
        setTimeout(() => {
          selectorRef.current.focus()
        }, 200)
      }
    }
  }, [showOptions])

  // Hide list when click outside
  const handleBlur = (e) => {
    // const isMobile = window.matchMedia('(max-width: 768px)').matches
    const needClose = selectorRef.current?.contains(e.relatedTarget) ? false : true

    if(needClose) {
      setTimeout(() => {
        setShowOptions(false)
      }, 150)
    }
  }

  const handleSelectOption = (option) => {
    setOptionSelected(option)
    onChange(option)
    setShowOptions(false)
  }

  return (
    <div className='w-100 d-flex flex-column align-items-start position-relative'>
      <div
        ref={selectorRef}
        className='Selector'
        onClick={() => setShowOptions(!showOptions)}
        onBlur={handleBlur}
        tabIndex={0}
      >
        <div className="Selector__Label">
          <span className="text-3 fw-medium">{ optionSelected || placeholder }</span>

          <motion.div
            animate={{ rotateX: showOptions ? 180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ArrowDownIcon/>
          </motion.div>
        </div>

        <motion.div
          className="Selector__Options-Container"
          animate={showOptions ? 'open' : 'closed'}
          initial={selectorVariants.closed}
          transition={{ duration: 0.2 }}
          variants={selectorVariants}
        >
          <div className="Selector__Options">
            {list.map((item) => {
              if(item.label === optionSelected) return (
                <button
                  key={`Selector-Option-Selected-${item.value}`}
                  className="Selector__Option--Selected"
                  onClick={() => handleSelectOption(item.label)}
                >
                  <span className="text-3 fw-medium">{ item.label }</span>
                </button>
              )
              else return (
                <button
                  key={`Selector-Option-${item.value}`}
                  className="Selector__Option"
                  onClick={() => handleSelectOption(item.label)}
                >
                  <span className="text-3 fw-medium">{ item.label }</span>
                </button>
              )
            })}
          </div>
        </motion.div>
      </div>

      <motion.div
        className="Textarea__Error text-5"
        animate={error ? 'open' : 'closed'}
        initial={errorVariants.closed}
        transition={{ duration: 0.1, ease: 'linear' }}
        style={{ transformStyle: 'preserve-3d' }}
        variants={errorVariants}
        dangerouslySetInnerHTML={{ __html: error }}
      >
      </motion.div>
    </div>
  )
}

export default Selector