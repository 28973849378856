import { memo } from 'react'

const ContactBookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 2.5A2.5 2.5 0 0 1 4.5 0h8A2.5 2.5 0 0 1 15 2.5v11a2.5 2.5 0 0 1-2.5 2.5h-8A2.5 2.5 0 0 1 2 13.5V13H1a.5.5 0 0 1 0-1h1v-2H1a.5.5 0 0 1 0-1h1V7H1a.5.5 0 0 1 0-1h1V4H1a.5.5 0 0 1 0-1h1v-.5ZM3 13h1a.5.5 0 0 0 0-1H3v-2h1a.5.5 0 0 0 0-1H3V7h1a.5.5 0 0 0 0-1H3V4h1a.5.5 0 0 0 0-1H3v-.5A1.5 1.5 0 0 1 4.5 1h8A1.5 1.5 0 0 1 14 2.5v11a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 3 13.5V13Zm8-7a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM8.009 6A.991.991 0 1 0 9.99 6 .991.991 0 0 0 8.01 6ZM12 11.5a3 3 0 0 0-6 0v1h6v-1Zm-4.414-1.414a2 2 0 0 0-.585 1.414h3.998a1.999 1.999 0 0 0-3.413-1.414Z"
      clipRule="evenodd"
    />
  </svg>
)

export default memo(ContactBookIcon)
