import { useState } from 'react'
import Helmet from 'react-helmet'
import Button from '../../components/Button'
import Selector from '../../components/Selector'
import UserProfile from '../../components/UserProfile'
import BubbleImage from '../../assets/images/info-bubble.png'

const AccountType = () => {
  const [ accountType, setAccountType ] = useState('single')

  const options = [
    {
      label: 'Individual',
      value: 'single',
    }, {
      label: 'Empresa',
      value: 'company',
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Tipo de Cuenta</title>
      </Helmet>

      <section className="AccountType app-background">
        <div className="AccountType__Container card">
          <UserProfile/>

          <h2 className='text-2 fw-bold'>Tipo de cuenta</h2>

          <Selector
            value={accountType}
            list={options}
            placeholder='Selecciona un tipo de cuenta'
            onChange={setAccountType}
          />

          <div className="AccountType__Alert">
            <img
              src={BubbleImage}
              alt="Iniciar solicitud"
              title="Iniciar solicitud"
            />

            <span className='text-5 text-gray-dark'>
              Si deseas cambiar tu cuenta<br/>
              individual a una empresaria inicia<br/>
              tu solicitud aquí
            </span>

            <Button
              type='secondary'
              disabled={accountType === 'single'}
            >
              Iniciar solicitud
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}

export default AccountType