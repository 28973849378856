import Modal from './Modal'
import CheckBubbleImage from '../../assets/images/check-bubble.png'

interface Props {
  closeModal: () => void
  message: string
  buttonLabel: string
  handleButtonClick: () => void
}

const SuccessModal = ({ closeModal, message, buttonLabel, handleButtonClick }: Props) => {
  return (
    <Modal
      className='SuccessModal'
      closeModal={closeModal}
      size='small'
    >
      <img
        className='SuccessModal__Image'
        src={CheckBubbleImage}
        alt="Éxito"
      />

      <h3 className='text-1 fw-medium text-center'>{ message }</h3>

      <button
        className='button-primary'
        onClick={handleButtonClick}
      >
        { buttonLabel }
      </button>
    </Modal>
  )
}

export default SuccessModal