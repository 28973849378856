import { useState, memo, useRef } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import ShowPasswordIcon from '../assets/icons/ShowedPasswordIcon'
import HidePasswordIcon from '../assets/icons/HidenPasswordIcon'

interface Props {
  name: string;
  type?: string;
  mode?: 'text' | 'decimal' | 'numeric' | 'tel' | 'email' | 'url';
  placeholder?: string;
  value?: any;
  onChange?: any;
  error?: string | any;
  tip?: string;
  disabled?: boolean;
  max?: number;
}

const variants = {
  open: { height: '32px', opacity: 1 },
  closed: { height: 0, opacity: 0 },
}

const Input = ({
  name,
  type = 'text',
  mode = 'text',
  placeholder,
  value,
  onChange,
  error,
  tip,
  disabled,
  max,
}: Props) => {
  const inputRef = useRef(null)
  const [ showPassword, setShowPassword ] = useState(false)

  const toogleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className='w-100 d-flex flex-column align-items-start position-relative'>
      {/* <label htmlFor={name} className='Input__Label text-3'>
        { label }
      </label> */}

      <div className='w-100 d-flex align-items-center position-relative'>
        <input
          className={classNames(
            'Input text-2',
            { 'Input--Password': type === 'password' },
            { 'Input--Disabled': disabled },
          )}
          ref={inputRef}
          type={
            type == 'password'
              ? (showPassword
                ? 'text'
                : type)
              : type == 'date'
                ? 'text'
                : type
          }
          inputMode={mode}
          name={name}
          placeholder={placeholder}
          id={name}
          value={value === 0 ? 0 : value || ''}
          onChange={onChange}
          onFocus={() => {
            if(type == 'date') {
              inputRef.current.type = 'date'
            }
          }}
          onBlur={() => {
            if(type == 'date') {
              inputRef.current.type = 'text'
            }
          }}
          autoComplete='off'
          disabled={disabled}
          readOnly={disabled}
          max={max ? max : null}
        />

        {type === 'password' &&
          (showPassword ? (
            <button
              className="Input__Password-Icon"
              onClick={toogleShowPassword}
              title="Ocultar contraseña"
            >
              <HidePasswordIcon/>
            </button>
          ) : (
            <button
              className="Input__Password-Icon"
              onClick={toogleShowPassword}
              title="Mostrar contraseña"
            >
              <ShowPasswordIcon/>
            </button>
          ))}
      </div>

      <motion.div
        className="Input__Error text-4"
        initial={variants.closed}
        animate={error ? 'open' : 'closed'}
        transition={{ duration: 0.1, ease: 'linear' }}
        style={{ transformStyle: 'preserve-3d' }}
        variants={variants}
        dangerouslySetInnerHTML={{ __html: error || tip }}
      >
      </motion.div>
    </div>
  )
}

export default memo(Input)
