import { useEffect } from 'react'
import { useSwiper } from 'swiper/react'

const SwiperHook = ({ setSwiper }) => {
  const swiper = useSwiper()

  useEffect(() => {
    setSwiper(swiper)
  }, [])

  return (
    <></>
  )
}

export default SwiperHook