import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/services/transporte-cordoba-logo.png'
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon'
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon'

const AllServices = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Todos nuestros servicios</title>
      </Helmet>

      <section className='AllServices'>
        <div className="w-100 d-flex flex-column align-items-start gap-5">
          <h1 className="text-2 fw-medium text-start">Otros desarrollos de Qaixa</h1>

          <div className="w-100 d-flex flex-column gap-3">
            {[0, 1, 2].map((_, index) => (
              <a
                key={`AllServices-Link-${index}`}
                className="AllServices__Item"
                href="http://"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Logo} alt="Logo Ipsum" title="Logo Ipsum" />

                <span className="text-5">Lorem ipsum dolor sit amet</span>

                <ArrowRightIcon/>
              </a>
            ))}
          </div>

          <div className="w-100 d-flex justify-content-center">
            <Link to="/start" className='AllServices__Link link-primary text-4'>
              <ArrowLeftIcon/> Volver
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default AllServices