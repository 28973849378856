import Modal from './Modal'
import ErrorBubbleImage from '../../assets/images/cross-bubble.png'
import { useMemo } from 'react'

const browsers = ['Google Chrome', 'Firefox', 'Safari', 'Opera', 'Brave', 'Microsoft Edge']
const cameraSettingsPaths = {
  'Google Chrome': 'chrome://settings/content/camera',
  'Firefox': 'about:preferences#privacy',
  'Safari': 'safari://preferences/privacy',
  'Opera': 'opera://settings/content/camera',
  'Brave': 'brave://settings/content/camera',
  'Microsoft Edge': 'edge://settings/content/camera'
}

const CameraErrorModal = () => {
  const getBrowser = () => {
    if(navigator.userAgentData) {
      const brands = navigator?.userAgentData?.brands?.map(({ brand }) => brand) || []
      return browsers.find(browser => brands.includes(browser))
    }

    if(navigator.userAgent) {
      return browsers.find(browser => navigator.userAgent.includes(browser))
    }
  }

  const browser = useMemo(getBrowser, [navigator.userAgentData, navigator.userAgent])

  const handleCopyLink = (e) => {
    navigator.clipboard.writeText(cameraSettingsPaths[browser])

    e.target.innerText = '¡Copiado!'
    setTimeout(() => {
      e.target.innerText = 'Copiar link'
    }, 3000)
  }

  return (
    <Modal className="CameraErrorModal">
      <img
        className='ErrorModal__Image'
        src={ErrorBubbleImage}
        alt="Éxito"
      />

      <span className="text-2 fw-medium">
        Hubo un error al intentar acceder a la camara, activa la cámara y recarga la página
      </span>

      {cameraSettingsPaths[browser] ? (
        <>
          <span className="text-2 fw-medium">
            En este caso, puedes activar la cámara aquí: <span className="fw-semibold">{cameraSettingsPaths[browser]}</span>
          </span>
          <span className="text-2 fw-medium">
            Copia el link y pégalo en una nueva pestaña
          </span>
          <button className='button-primary' onClick={handleCopyLink}>
            Copiar link
          </button>
        </>
      ) : (
        <span className='text-2 fw-bold'>
          Por favor, ve a la configuración de tu navegador y activa la cámara
        </span>
      )}
    </Modal>
  )
}

export default CameraErrorModal