import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Button from 'components/Button'
import useAppContext from 'hooks/useAppContext'
import Image from 'assets/images/women-card.png'
import DeleteCardModal from 'components/modals/DeleteCardModal'
import ConfirmDeleteCardModal from 'components/modals/ConfirmDeleteCardModal'


const MyCard = () => {
  const { card } = useAppContext()
  const [ modal, setModal ] = useState({
    isOpen: false,
    type: 'delete'
  })
  const navigate = useNavigate()

  // Check if card number is empty
  useEffect(() => {
    if(!card && !modal.isOpen) {
      navigate('/intercordoba/add-card', {
        replace: true
      })
    }
  }, [card])

  const handleShowDeleteModal = () => {
    setModal({
      isOpen: true,
      type: 'delete'
    })
  }

  const handleShowConfirmModal = () => {
    localStorage.removeItem('card')
    setModal({
      isOpen: true,
      type: 'confirm'
    })
  }

  const handleCloseModal = () => {
    setModal({
      isOpen: false,
      type: 'delete'
    })
  }

  if(card) return (
    <>
      <Helmet>
        <title>Qaixa: Mi Tarjeta</title>
      </Helmet>

      <section className="MyCard app-background">
        <div className="MyCard__Container card">
          <h3 className='h5 fw-bold'>Número de tarjeta asociada</h3>
          <div className="MyCard__Number">
            <span className="h1">{ card.card_number }</span>
          </div>

          <img
            className='MyCard__Image pt-4'
            src={Image}
            alt="Mi tarjeta"
            title='Mi tarjeta'
          />

          <Button
            type='error'
            onClick={handleShowDeleteModal}
          >
            Eliminar
          </Button>

          <Link to='/menu/support' className='button-white'>
            Denunciar tarjeta
          </Link>
        </div>
      </section>

      { modal.isOpen && (
        <>
          { modal.type === 'delete' ? (
            <DeleteCardModal
              closeModal={handleCloseModal}
              handleOpenConfirmModal={handleShowConfirmModal}
            />
          ) : (
            <ConfirmDeleteCardModal service="intercordoba"/>
          )}
        </>
      )}
    </>
  )
}

export default MyCard