import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { getTickets } from '../../api/claims'
import useAuthContext from '../../hooks/useAuthContext'
import BubbleImage from '../../assets/images/info-bubble.png'
import InProcessImage from '../../assets/images/ticket-in-progress.png'
import SuccessImage from '../../assets/images/ticket-success.png'
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon'
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon'

const Support = () => {
  const { token } = useAuthContext()
  const navigate = useNavigate()
  // const { data: tickets } = useQuery(
  //   ['getTickets'],
  //   () => getTickets(token),
  //   {
  //     initialData: [],
  //     enabled: !!token,
  //   }
  // )

  const tickets = [
    {
      id: 1,
      summary: 'Ticket 1',
      created_at: '15:00 03/01/23',
      hour: '13: 30',
    }, {
      id: 2,
      summary: 'Ticket 2',
      created_at: '15:00 03/06/23',
      hour: '18:15',
    }, {
      id: 3,
      summary: 'Ticket 3',
      created_at: '15:00 03/13/23',
      hour: '12:00',
    }, {
      id: 4,
      summary: 'Ticket 4',
      created_at: '15:00 03/19/23',
      hour: '07:45',
    }, {
      id: 5,
      summary: 'Ticket 5',
      created_at: '15:00 03/27/23',
      hour: '21:30',
    }, {
      id: 6,
      summary: 'Ticket 6',
      created_at: '15:00 04/01/23',
      hour: '13: 30',
    }, {
      id: 7,
      summary: 'Ticket 7',
      created_at: '15:00 04/06/23',
      hour: '18:15',
    }, {
      id: 8,
      summary: 'Ticket 8',
      created_at: '15:00 04/13/23',
      hour: '12:00',
    }, {
      id: 9,
      summary: 'Ticket 9',
      created_at: '15:00 04/19/23',
      hour: '07:45',
    }, {
      id: 10,
      summary: 'Ticket 10',
      created_at: '09:00 04/27/23',
      hour: '21:30',
    }
  ]

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <>
      <Helmet>
        <title>Qaixa: Soporte</title>
      </Helmet>

      <div className="Support">
        <div className="Support__Content">
          <h3 className='text-2 fw-semibold'>Mis tickets</h3>

          {tickets.length > 0 ? (
            <div className="Support__Tickets-Container">
              <header className="Support__Header">
                <span className="text-2 fw-medium">Detalle</span>
                <span className="text-2 fw-medium">Estado</span>
              </header>

              {tickets.map(ticket => {
                const date = new Date(ticket.created_at)
                let day:any = date.getDate()
                day = day < 10 ? '0' + day : day
                let month:any = date.getMonth() + 1
                month = month < 10 ? '0' + month : month
                const year = date.getFullYear()
                let hour:any = date.getHours()
                hour = hour < 10 ? '0' + hour : hour
                let minutes:any = date.getMinutes()
                minutes = minutes < 10 ? '0' + minutes : minutes

                return (
                  <Link
                    key={`Support-Ticket-${ticket.id}`}
                    to={`/menu/support/ticket/${ticket.id}`}
                    className='Support__Ticket'
                  >
                    <div className="d-flex flex-column">
                      <span className='text-3 fw-medium'>{ ticket.summary }</span>
                      <span className='text-4'>{ day }/{ month }/{ year } - { hour }:{ minutes }</span>
                    </div>

                    <div className="Support__Ticket-Image-Container">
                      <img src={InProcessImage} />
                    </div>

                    <ArrowRightIcon/>
                  </Link>
                )}
              )}
            </div>
          ) : (
            <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-3">
              <img
                className='Support__Bubble'
                src={BubbleImage}
                alt="No tenes reclamos"
                title="No tenes reclamos"
              />
              <span className="text-5 text-gray-dark">
                No cuentas con ningún reclamo iniciado
              </span>
            </div>
          )}

          <footer className="Support__Footer">
            {/* <img
              className='Support__Image'
              src={ErrorImage}
              alt='Soporte'
              title='Soporte'
            /> */}

            <Link to='/menu/support/create-ticket' className='button-primary'>
              Iniciar nuevo ticket
            </Link>

            <button className="link-primary d-flex align-items-center gap-1" onClick={() => handleGoBack()}>
              <ArrowLeftIcon/>
              <span className="text-3">Volver</span>
            </button>
          </footer>
        </div>
      </div>
    </>
  )
}

export default Support