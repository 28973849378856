import { memo } from 'react'

const MovementsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox='0 0 16 16'
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.5 0A1.5 1.5 0 0 0 2 1.5v11.207l.146.147 3 3 .147.146H12.5a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 12.5 0h-9ZM3 1.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5H6v-1.5A1.5 1.5 0 0 0 4.5 12H3V1.5ZM3.707 13 5 14.293V13.5a.5.5 0 0 0-.5-.5h-.793ZM4.5 7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7Zm0 3a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7Zm3 3a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4Zm2.854-10.146a.5.5 0 0 0-.708-.708L7.5 4.293l-.646-.647a.5.5 0 1 0-.708.708L6.793 5a1 1 0 0 0 1.414 0l2.147-2.146Z"
      clipRule="evenodd"
    />
  </svg>
)

export default memo(MovementsIcon)
