import { memo, SVGProps } from 'react'

const BellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M4.344 1.51a5.156 5.156 0 0 1 8.801 3.646c0 2.358.504 3.808.958 4.64.229.42.449.69.598.849a1.972 1.972 0 0 0 .195.18.889.889 0 0 1-.506 1.62H1.59a.889.889 0 0 1-.494-1.629l.493.74c-.493-.74-.494-.739-.494-.739h-.001l-.002.002-.004.003-.005.003-.002.001h.002a1.968 1.968 0 0 0 .194-.18c.15-.16.37-.43.598-.85.455-.832.959-2.282.959-4.64 0-1.368.543-2.679 1.51-3.646Zm8.209 9.157H3.426l.01-.019c.613-1.123 1.175-2.872 1.175-5.492a3.378 3.378 0 0 1 6.756 0c0 2.62.563 4.37 1.175 5.492l.01.019ZM6.314 13.634a.889.889 0 0 1 1.215.322.533.533 0 0 0 .922 0 .889.889 0 1 1 1.538.893 2.311 2.311 0 0 1-3.998 0 .889.889 0 0 1 .323-1.215Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(BellIcon)