import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useAuthContext from '../../../hooks/useAuthContext'
import RegisterHeader from '../../../components/RegisterHeader'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
// import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon'
import PhoneIcon from '../../../assets/icons/PhoneIcon'
import { sendSMS } from '../../../api/auth'

const RegisterPhone = () => {
  const { token } = useAuthContext()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      phone: null ,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      phone: Yup.number().typeError('Ingresa solo números').nullable().required('El teléfono es requerido'),
    }),
    onSubmit: async ({ phone }) => {
      const response = await sendSMS(token, {
        user_phone: phone,
      })

      if(response?.error) {
        if(response?.error == 'Phone number already in use') {
          formik.setFieldError('phone', 'Este número no esta disponible')
        } else if(response?.e?.includes('Invalid parameter')) {
          formik.setFieldError('phone', 'El número ingresado no es válido o esta incompleto')
        } else {
          formik.setFieldError('phone', 'Hubo un error al enviar el código')
        }
      } else {
        navigate('/register/validate-phone', {
          replace: true,
        })
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Qaixa: Registrar Número de Celular</title>
      </Helmet>

      <section className="RegisterPhone register-container">
        <RegisterHeader title="Registro" step={3}/>

        <div className="d-flex flex-column align-items-center gap-3">
          <PhoneIcon className='RegisterPhone__Icon'/>
          <h1 className='h4 fw-semibold'>
            Ingresá tu número de celular, te enviaremos un código para validarlo
          </h1>
          <span className="text-2">Utilizaremos este medio,<br/>para una autenticación de doble factor <br/> Ejemplo: (<b>+54911XXXXXXXX</b>)</span>
          <Input
            name='phone'
            type='tel'
            mode='tel'
            placeholder='Número de celular'
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.errors.phone}
          />
        </div>

        <div className="w-100 d-flex flex-column align-items-center gap-2">
          <Button
            type='primary'
            onClick={formik.handleSubmit}
            isSubmit
            disabled={formik.isSubmitting}
          >
            Enviar código
          </Button>
        </div>
      </section>
    </>
  )
}

export default RegisterPhone