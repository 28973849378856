import { memo, SVGProps } from 'react'

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8c0-.544.398-.985.889-.985H15.11c.491 0 .889.441.889.985s-.398.985-.889.985H.89C.398 8.985 0 8.544 0 8ZM0 2.485C0 1.94.398 1.5.889 1.5H15.11c.491 0 .889.44.889.985 0 .544-.398.985-.889.985H.89C.398 3.47 0 3.029 0 2.485ZM0 13.515c0-.544.398-.985.889-.985H15.11c.491 0 .889.441.889.985s-.398.985-.889.985H.89c-.491 0-.889-.441-.889-.985Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(MenuIcon)