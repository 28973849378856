import { memo, SVGProps } from 'react'

const AddContactIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={16}
      height={16}
    >
      <path d="M15.394 0H0v16h15.394V0Z" fill="#fff" />
    </mask>
    <g mask="url(#a)" fill="currentColor">
      <path d="M10.087 8.274c.432.218.864.425 1.283.655a.634.634 0 0 1 .33.72.719.719 0 0 1-1.144.477 5.991 5.991 0 0 0-9.106 5.146.713.713 0 0 1-.947.69.692.692 0 0 1-.502-.716 7.315 7.315 0 0 1 .908-3.542A7.418 7.418 0 0 1 4.559 8.4c.035-.015.069-.033.118-.058A4.557 4.557 0 0 1 2.75 4.338 4.428 4.428 0 0 1 4.205 1.24a4.59 4.59 0 1 1 5.882 7.033Zm-5.9-3.694a3.145 3.145 0 1 0 3.197-3.13 3.13 3.13 0 0 0-3.198 3.13ZM13.223 12.315h1.413a.691.691 0 0 1 .743.58.702.702 0 0 1-.684.869c-.423.008-.848 0-1.272 0h-.199v.741c0 .241.008.475 0 .712a.724.724 0 0 1-1.447-.024c-.005-.468 0-.938 0-1.421-.063 0-.117-.008-.173-.008H10.3a.719.719 0 0 1-.12-1.43 4.95 4.95 0 0 1 .642-.022h.95v-1.411a.727.727 0 1 1 1.45 0v1.413" />
    </g>
  </svg>
)

export default memo(AddContactIcon)
