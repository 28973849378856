import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import QrScanner from 'qr-scanner'
import useAppContext from 'hooks/useAppContext'
import ScanModal from 'components/modals/ScanModal'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon'

const ScanQR = () => {
  const { currentApp } = useAppContext()
  const [ mediaStream, setMediaStream ] = useState(null)
  const [ scanner, setScanner ] = useState(null)
  const [ showModal, setShowModal ] = useState(false)
  const videoRef = useRef(null)
  const canvasRef = useRef(null)
  const navigate = useNavigate()

  // Set up the video stream
  useEffect(() => {
    const video = videoRef.current
    const canvas = canvasRef.current

    const getVideo = async () => {
      canvas.height = canvas.width

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          // video: {
          //   facingMode: 'user' // 'user' | 'environment'
          // },
          video: {
            facingMode: 'environment'
          },
          audio: false
        })
        if(stream) setMediaStream(stream)

        video.srcObject = stream
        video.play()

        const qrScanner = new QrScanner(
          video,
          () => {
            setShowModal(true)
          },
          {
            returnDetailedScanResult: true
          }
        )

        if(qrScanner) {
          qrScanner.start()
          setScanner(qrScanner)
        }
      } catch (error) {
        console.log(error.message)
        alert('No se pudo acceder a la cámara')
      }
    }

    if(video && canvas) getVideo()

    return () => {
      handleStopVideo()
    }
  }, [videoRef, canvasRef])

  const handleStopVideo = () => {
    // Get media stream track
    const track = mediaStream?.getVideoTracks()[0]

    if(track) {
      // Stop the video track and release the camera
      track.stop()
      track.enabled = false
    }

    if(mediaStream) {
    // Close the video stream
      mediaStream.getTracks().forEach(track => {
        track.stop()
      })
    }

    if(scanner) scanner.stop()
  }

  const handleGoBack = () => {
    handleStopVideo()
    navigate(-1)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    handleStopVideo()
    navigate(`/${currentApp}/exchange-points`, { replace: true })
  }

  return (
    <>
      <Helmet>
        <title>Qaixa: Escanear QR</title>
      </Helmet>

      <section className="ScanQR">
        <div className="ScanQR__GoBack">
          <button className='link-white' onClick={handleGoBack}>
            <ArrowLeftIcon/>
            Volver
          </button>
        </div>

        {/* <div className='ScanQR__Video-Background'></div> */}
        <video className='ScanQR__Video' ref={videoRef} />
        <canvas className='ScanQR__Canvas' ref={canvasRef} />
      </section>

      {showModal && (
        <ScanModal closeModal={handleCloseModal}/>
      )}
    </>
  )
}

export default ScanQR