import Modal from './Modal'
import Loader from '../Loader'
import { useEffect } from 'react'

interface Props {
  closeModal: () => void
}

const ScanModal = ({ closeModal }: Props) => {

  useEffect(() => {
    setTimeout(() => {
      closeModal()
    }, 2500)
  })

  return (
    <Modal
      className='d-flex flex-column justify-content-center align-items-center gap-4'
      closeModal={closeModal}
    >
      <h3 className='h5 my-3 fw-medium text-center'>Cargando datos para el canje</h3>

      <Loader/>
    </Modal>
  )
}

export default ScanModal