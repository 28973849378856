import { memo, SVGProps } from 'react'

const RequestTokensIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="currentColor">
      <path d="M14.336 8.015A7.172 7.172 0 0 1 .128 9.357a7.158 7.158 0 0 1 9.087-8.205.523.523 0 0 1 .378.698.468.468 0 0 1-.167.203.525.525 0 0 1-.502.068 5.74 5.74 0 0 0-1.874-.26A6.037 6.037 0 0 0 1.717 5.11a5.874 5.874 0 0 0-.692 3.63 6.156 6.156 0 1 0 12.15-1.934.51.51 0 0 1 .615-.614.498.498 0 0 1 .37.422c.07.467.118.936.175 1.404" />
      <path d="M6.61 4.939c.034-.542.183-.765.51-.763a.441.441 0 0 1 .432.256c.054.167.093.338.117.511a1.735 1.735 0 0 1 1.13.715c.18.244.288.534.31.837a.494.494 0 0 1-.42.545.507.507 0 0 1-.577-.427.75.75 0 0 0-.826-.692c-.171-.007-.342 0-.512.022a.713.713 0 0 0-.606.674.733.733 0 0 0 .553.768c.36.096.725.177 1.085.273a1.744 1.744 0 0 1-.045 3.392l-.13.033c0 .085.004.167 0 .25a.506.506 0 1 1-1.01.018c-.008-.087 0-.175 0-.273a1.8 1.8 0 0 1-.99-.535 1.66 1.66 0 0 1-.46-1.033.489.489 0 0 1 .416-.534.506.506 0 0 1 .58.421.756.756 0 0 0 .838.7c.166.004.332-.003.497-.024a.719.719 0 0 0 .608-.671.73.73 0 0 0-.548-.77c-.356-.096-.716-.176-1.07-.273a1.746 1.746 0 0 1 .01-3.394l.109-.03M13.436 2.506h2.028a.504.504 0 0 1 .526.6.485.485 0 0 1-.39.394c-.072.011-.145.016-.218.015H13.39c.173.175.32.316.458.465a.503.503 0 0 1-.289.848.458.458 0 0 1-.414-.133 129.07 129.07 0 0 1-1.335-1.336.483.483 0 0 1-.004-.689c.444-.454.892-.903 1.346-1.346a.5.5 0 0 1 .7.711c-.141.15-.297.285-.446.427l.03.042" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(RequestTokensIcon)