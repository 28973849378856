import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ErrorImage from 'assets/images/error-image.png'

const ErrorCard = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Error al Asociar Tarjeta</title>
      </Helmet>

      <section className="ErrorCard app-background">
        <img
          className='ErrorCard__Image'
          src={ErrorImage}
          alt="La tarjeta no se pudo asociar"
          title="La tarjeta no se pudo asociar"
        />

        <h1 className='h2 text-red'>
          ¡Ups!
        </h1>

        <span>Parece que hubo un problema con la asociación de tu tarjeta</span>

        <Link
          to='/transporte-cordoba/add-card'
          className='button-secondary'
          replace
        >
          Reintentar
        </Link>
        <Link
          to='/transporte-cordoba/account/profile'
          className='button-white'
          replace
        >
          Volver al perfil
        </Link>
      </section>
    </>
  )
}

export default ErrorCard