import { Helmet } from 'react-helmet'

const DeleteContact = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Eliminar Contacto</title>
      </Helmet>

      <h1>DeleteContact</h1>
    </>
  )
}

export default DeleteContact