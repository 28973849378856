import { Link, useLocation } from 'react-router-dom'
import useAppContext from 'hooks/useAppContext'
import QRIcon from 'assets/icons/QRIcon'
import AddContactIcon from 'assets/icons/AddContactIcon'
import Contacts from './Contacts'

const ContactBookContainer = () => {
  const { currentApp } = useAppContext()
  const { state } = useLocation()

  return (
    <section className="ContactBookContainer Menu__Content app-background">
      {(state != 'request-points' && state != 'send-points') && (
        <div className="d-flex gap-3">
          <Link
            to={`/${currentApp}/my-qr`}
            className='ContactBookContainer__Button--QR'
          >
            <QRIcon/>
            <span className="text-4 fw-medium">Mi A QR</span>
          </Link>

          <Link
            to='/menu/contact-book/add-contact'
            className='ContactBookContainer__Button--Contact'
          >
            <AddContactIcon/>
            <span className="text-4 fw-medium">Añadir contacto</span>
          </Link>
        </div>
      )}

      <Contacts/>
    </section>
  )
}

export default ContactBookContainer