import CameraIcon from '../assets/icons/CameraIcon'

interface Props {
  handleClick: () => void
}

const CameraButton = ({ handleClick }: Props) => {
  return (
    <button className='CameraButton' onClick={handleClick}>
      <CameraIcon />
    </button>
  )
}

export default CameraButton