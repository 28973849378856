import { getQuery, postQuery } from './apiFunctions'

export const getContacts = async (token: string) => {
  return await getQuery<any>({
    path: '/contacts/',
    token,
  })
}

export const addContact = async (token: string, alias: string) => {
  return await postQuery<any>({
    path: '/contacts/',
    token,
    body: {
      alias
    }
  })
}