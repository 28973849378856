import { memo, SVGProps } from 'react'

const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={0}
      width={15}
      height={16}
    >
      <path d="M15.4 0H1v16h14.4V0Z" fill="#fff" />
    </mask>
    <g mask="url(#a)" fill="currentColor">
      <path d="M14.6 16a.8.8 0 0 1-.8-.8v-1.6a2.4 2.4 0 0 0-2.4-2.4H5a2.4 2.4 0 0 0-2.4 2.4v1.6a.8.8 0 0 1-1.6 0v-1.6a4.005 4.005 0 0 1 4-4h6.4a4.004 4.004 0 0 1 4 4v1.6a.8.8 0 0 1-.8.8ZM8.2 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0-6.4a2.4 2.4 0 1 0 0 4.8 2.4 2.4 0 0 0 0-4.8Z" />
    </g>
  </svg>
)

export default memo(UserIcon)