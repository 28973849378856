import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { registerEmail } from '../../../api/auth'
import useAuthContext from '../../../hooks/useAuthContext'
import RegisterHeader from '../../../components/RegisterHeader'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import EmailIcon from '../../../assets/icons/EmailIcon'
import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon'

const RegisterEmail = () => {
  const { setToken } = useAuthContext()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      email: Yup.string().trim().email('Ingrese un correo valido').required('El correo es requerido'),
    }),
    onSubmit: async ({ email }) => {
      const response = await registerEmail(email)

      if(response.key) {
        setToken(response.key)
        navigate('/register/validate-email', {
          replace: true,
        })
      } else if(response?.email?.length) {
        if(response.email[0].includes('A user is already registered with this e-mail address')) {
          formik.setErrors({
            email: 'Este correo no esta disponible',
          })
        }
      }
    }
  })

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <>
      <Helmet>
        <title>Qaixa: Registrar Email</title>
      </Helmet>

      <section className="RegisterEmail register-container">
        <RegisterHeader title="Registro" step={1}/>

        <div className="d-flex flex-column align-items-center gap-2">
          <EmailIcon className='RegisterEmail__Icon'/>
          <h1 className='h4 fw-semibold mb-3'>Ingresá tu correo electrónico, te enviaremos un código para validarlo</h1>
          <Input
            name='email'
            type='email'
            mode='email'
            placeholder='Correo electrónico'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />
        </div>

        <div className="w-100 d-flex flex-column align-items-center gap-2">
          <Button
            type='primary'
            onClick={formik.handleSubmit}
            isSubmit
            disabled={formik.isSubmitting}
          >
            Enviar código
          </Button>
          <button className="link-primary d-flex align-items-center gap-2" onClick={handleGoBack}>
            <ArrowLeftIcon/>
            Volver
          </button>
        </div>
      </section>
    </>
  )
}

export default RegisterEmail