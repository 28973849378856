import { SVGProps, memo } from 'react'

const FileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#clip0_67_60)">
        <g clipPath="url(#clip1_67_60)">
          <mask
            id="mask0_67_60"
            style={{ maskType: 'luminance' }}
            width="16"
            height="16"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
          >
            <path fill="#fff" d="M15.25 0H.75v16h14.5V0z"></path>
          </mask>
          <g fill="currentColor" mask="url(#mask0_67_60)">
            <path d="M3.01 16l-.212-.047a2.516 2.516 0 01-1.481-.876 2.466 2.466 0 01-.56-1.615C.75 9.822.75 6.183.758 2.542A2.462 2.462 0 011.314.93 2.512 2.512 0 012.792.052 2.75 2.75 0 013.325 0H11a2.528 2.528 0 011.607.53c.458.356.776.858.9 1.42.046.204.068.411.065.62v3.685a.625.625 0 01-.105.432.636.636 0 01-.628.269.624.624 0 01-.427-.28.61.61 0 01-.089-.243 1.906 1.906 0 01-.013-.25V2.587a1.21 1.21 0 00-.167-.71 1.23 1.23 0 00-.546-.489 1.52 1.52 0 00-.623-.133c-2.543-.009-5.085-.009-7.628 0a1.276 1.276 0 00-.941.342 1.25 1.25 0 00-.393.912V13.376a1.299 1.299 0 00.499 1.11c.236.178.527.271.824.263h2.037a.635.635 0 01.611.42.617.617 0 01-.22.703 2.09 2.09 0 01-.23.12L3.01 16z"></path>
            <path d="M8.096 16a.313.313 0 00-.05-.031.629.629 0 01-.371-.31.615.615 0 01-.036-.479c.242-.813.492-1.623.75-2.431a.838.838 0 01.21-.322l3.394-3.357a1.906 1.906 0 012.674-.128 1.86 1.86 0 01.13 2.645c-.084.1-.18.19-.273.283-1.065 1.053-2.13 2.105-3.197 3.157-.14.14-.316.242-.51.292-.825.223-1.648.454-2.473.683L8.096 16zm1.082-1.532c.397-.11.765-.205 1.13-.313a.625.625 0 00.252-.141c.707-.69 1.41-1.385 2.111-2.081.037-.036.07-.075.085-.092l-.906-.899a.922.922 0 01-.094.12c-.638.633-1.274 1.27-1.923 1.893-.198.178-.338.41-.404.665-.068.276-.161.545-.252.84m4.519-3.556a.637.637 0 00.27-.68.584.584 0 00-.479-.465.662.662 0 00-.677.269l.886.876zM7.155 5.001H3.95a.636.636 0 01-.448-.154.622.622 0 01.466-1.09h6.38a.64.64 0 01.635.363.623.623 0 01-.594.885H7.15M7.155 7.5H3.95a.636.636 0 01-.448-.152.622.622 0 01.465-1.092h6.38a.64.64 0 01.636.363.62.62 0 01-.128.708.626.626 0 01-.466.179H7.156M5.886 10.006H3.93a.638.638 0 01-.603-.386.619.619 0 01.368-.825.638.638 0 01.25-.037H7.84a.638.638 0 01.603.386.619.619 0 01-.367.825.636.636 0 01-.25.037h-1.94z"></path>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_67_60">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
        <clipPath id="clip1_67_60">
          <path
            fill="#fff"
            d="M0 0H14.5V16H0z"
            transform="translate(.75)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default memo(FileIcon)