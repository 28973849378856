import { postQuery } from './apiFunctions'
import { PasswordChangeBody, SendSMSBody, ValidateDocumentBody, ValidateFaceBody, VerifySMSBody } from './types'

// Login
export const login = async (email: string, password: string) => {
  return await postQuery<any>({
    path: '/login/',
    body: {
      email,
      password,
    },
  })
}

// Signup
export const registerEmail = async (email: string) => {
  return await postQuery<any>({
    path: '/signup/',
    body: {
      email,
    },
  })
}

export const verifyEmail = async (token: string, code: string) => {
  return await postQuery<any>({
    path: '/users/verify-email/',
    token,
    body: {
      token: code,
    }
  })
}

export const resendEmailVerification = async (token: string) => {
  return await postQuery<any>({
    path: '/users/resend-email-verification/',
    token
  })
}

export const passwordChange = async (token: string, body: PasswordChangeBody) => {
  return await postQuery<any>({
    path: '/users/password-change/',
    token,
    body
  })
}

export const sendSMS = async (token: string, body: SendSMSBody) => {
  return await postQuery<any>({
    path: '/users/send-verification-sms/',
    token,
    body
  })
}

export const verifySMS = async (token: string, body: VerifySMSBody) => {
  return await postQuery<any>({
    path: '/users/verify-sms/',
    token,
    body
  })
}

export const validateDocument = async (token: string, body: ValidateDocumentBody) => {
  const formData = new FormData()
  formData.append('document_image', body.document_image, body.document_image.name)

  return await postQuery<any>({
    path: '/users/validate-document/',
    token,
    body: formData
  })
}

export const validateFace = async (token: string, body: ValidateFaceBody) => {
  return await postQuery<any>({
    path: '/signup/users/validate-face-with-document-image/',
    token,
    body
  })
}

// Password recovery flow
export const passwordRecovery = async (email: string) => {
  return await postQuery<any>({
    path: '/users/password-recovery/',
    body: {
      email
    },
  })
}

export const checkCode = async (email: string, token: string) => {
  return await postQuery<any>({
    path: '/users/password-recovery/check-token/',
    body: {
      email,
      token
    },
  })
}

export const passwordRecoveryConfirm = async (email: string, token: string, password: string) => {
  return await postQuery<any>({
    path: '/users/password-recovery/confirm/',
    body: {
      email,
      token,
      password
    },
  })
}