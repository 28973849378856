import { memo } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

interface Props {
  name: string;
  placeholder?: string;
  value?: any;
  onChange?: any;
  error?: string | any;
  tip?: string;
  disabled?: boolean;
}

const variants = {
  open: { height: '18px', opacity: 1 },
  closed: { height: 0, opacity: 0 },
}

const Textarea = ({
  name,
  placeholder,
  value,
  onChange,
  error,
  tip,
  disabled,
}: Props) => {
  return (
    <div className='w-100 d-flex flex-column align-items-start position-relative'>
      <div className='w-100 d-flex align-items-center position-relative'>
        <textarea
          className={classNames(
            'Textarea text-2',
            { 'Textarea--Disabled': disabled },
          )}
          name={name}
          placeholder={placeholder}
          id={name}
          value={value || ''}
          onChange={onChange}
          disabled={disabled}
          readOnly={disabled}
        ></textarea>
      </div>

      <motion.div
        className="Textarea__Error text-5"
        initial={variants.closed}
        animate={error ? 'open' : 'closed'}
        transition={{ duration: 0.1, ease: 'linear' }}
        style={{ transformStyle: 'preserve-3d' }}
        variants={variants}
        dangerouslySetInnerHTML={{ __html: error || tip }}
      >
      </motion.div>
    </div>
  )
}

export default memo(Textarea)
