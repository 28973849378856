import { useNavigate } from 'react-router-dom'
import ArrowLeftIcon from '../assets/icons/ArrowLeftIcon'

interface Props {
  title: string
}

const AuthHeader = ({ title }: Props) => {
  const navigate = useNavigate()

  return (
    <header className="AuthHeader">
      <button className="AuthHeader__Button text-2 fw-medium" onClick={() => navigate(-1)}>
        <ArrowLeftIcon/>
        { title }
      </button>
    </header>
  )
}

export default AuthHeader