import { Helmet } from 'react-helmet'

const Company = () => {
  return (
    <>
      <Helmet>
        <title>Qaixa: Empresa</title>
      </Helmet>

      <div className="Menu__Content">
        <h3 className='text-2 fw-medium'>Empresa</h3>
      </div>
    </>
  )
}

export default Company