import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import useAppContext from 'hooks/useAppContext'
import WelcomeBanner from 'components/WelcomeBanner'
import TransportLogo from 'assets/images/services/transporte-cordoba-logo.png'
import InterCordobaLogo from 'assets/images/services/intercordoba-logo.png'
import TaxesLogo from 'assets/images/services/jesus-maria-logo.png'
import MendiolazaLogo from 'assets/images/services/mendiolaza-logo.png'

const Start = () => {
  const { card } = useAppContext()

  const userServices = [
    {
      name: 'Transporte Córdoba',
      link: card ? '/transporte-cordoba' : '/transporte-cordoba/add-card',
      logo: TransportLogo
    }, 
    {
      name: 'Transporte Intercordoba',
      link: card ? '/intercordoba' : '/intercordoba/add-card',
      logo: InterCordobaLogo
    },
    { 
      name: 'Impuestos Jesús María',
      link: '/jesus-maria',
      logo: TaxesLogo
    }, {
      name: 'Impuestos Mendiolaza',
      link: '/mendiolaza',
      logo: MendiolazaLogo
    }
    // {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }, {
    //   name: 'Transporte Córdoba',
    //   link: '/transporte-cordoba/start',
    //   logo: MendiolazaLogo
    // }
  ]

  const extraServices = [
    {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }, {
      name: 'Servicio',
      link: '/start',
      logo: TransportLogo
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa</title>
      </Helmet>

      <section className='Start'>
        <WelcomeBanner/>

        <div className="Start__Content">
          <div className="Start__Card card gap-4">
            <div className="d-flex flex-column gap-3">
              <h2 className='text-1 fw-semibold'>Servicios disponibles</h2>

              {userServices.length
                ? (
                  <div className="Start__Services">
                    {userServices.map((service, index) => (
                      <Link
                        key={`User-Service-${index}`}
                        to={service.link}
                        className='Start__Service text-4'
                      >
                        <img className='Start__Service-Image' src={service.logo} alt={service.name}/>
                        <span className="text-4 fw-medium">{ service.name }</span>
                      </Link>
                    ))}
                  </div>
                )
                : <span className="text-4 fw-medium">No hay servicios disponibles</span>
              }
            </div>

            {/* <div className="d-flex flex-column gap-3">
              <h2 className='text-1 fw-semibold'>Todos los servicios</h2>

              {extraServices.length
                ? (
                  <div className="Start__Services">
                    {extraServices.map((service, index) => (
                      <Link
                        key={`Extra-Service-${index}`}
                        to={service.link}
                        className='Start__Service text-4'
                      >
                        <img className='Start__Service-Image' src={service.logo} alt={service.name}/>
                        <span className="text-4 fw-medium">{ service.name }</span>
                      </Link>
                    ))}
                  </div>
                )
                : <span className="text-4 fw-medium">No hay servicios disponibles</span>
              }
            </div> */}
          </div>
        </div>

        {/* <footer className="Start__Footer">
          <span className="text-5 text-secondary">Otros desarrollos de Qaixa</span>

          <div className="d-flex alig-items-center gap-5">
            {[0, 1, 2].map((_, index) => (
              <a
                key={`Start-Link-${index}`}
                className='Start__Link'
                href="http://"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Logo} alt="Logo Ipsum"/>
              </a>
            ))}
          </div>

          <Link to="/all-services" className='Start__Link-Services link-primary text-4'>
            Ver todos <ArrowRightIcon/>
          </Link>
        </footer> */}
      </section>
    </>
  )
}

export default Start