import { Helmet } from 'react-helmet'
import useAuthContext from 'hooks/useAuthContext'
import BubbleImage from 'assets/images/check-bubble.png'
import DownloadIcon from 'assets/icons/DownloadIcon'

const Receipt = () => {
  const { user } = useAuthContext()

  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return (
    <>
      <Helmet>
        <title>Qaixa: Recibo</title>
      </Helmet>

      <section className="app-background d-flex flex-column align-items-center gap-2">
        <div className="h-100 card text-center">
          <span className="text-3">Comprobante de operación</span>

          <div className="d-flex flex-column align-items-center gap-2">
            <img
              className='Receipt__Bubble'
              src={BubbleImage}
              alt='Message'
              title='Message'
            />
            <h2 className="text-1 fw-semibold text-green">¡Tu canje de puntos fue exitoso!</h2>
            <h1 className='h1'>PTS 100,00</h1>
          </div>

          <div className="w-100 d-flex flex-column">
            <div className="Receipt__Item">
              <span className="text-4 fw-medium">Fecha y hora</span>
              <span className="text-4">{day}/{month}/{year} - {hours}:{minutes}hs</span>
            </div>

            <div className="Receipt__Item">
              <span className="text-4 fw-medium">Tipo</span>
              <span className="text-4">Canje de puntos</span>
            </div>

            <div className="Receipt__Item">
              <span className="text-4 fw-medium">ID de operación</span>
              <span className="text-4">******0035</span>
            </div>

            <div className="Receipt__Item">
              <span className="text-4 fw-medium">Emisor</span>
              <div className="d-flex flex-column align-items-end text-end">
                <span className="text-4">Nombre de usuario</span>
                <span className="text-4">{ user.first_name } { user.last_name }</span>
              </div>
            </div>

            <div className="Receipt__Item">
              <span className="text-4 fw-medium">Receptor</span>
              <div className="d-flex flex-column align-items-end text-end">
                <span className="text-4">Nombre de comercio</span>
                <span className="text-4">Exchange Test</span>
              </div>
            </div>

            <div className="Receipt__Item">
              <span className="text-4 fw-medium">Puntos</span>
              <span className="text-3 fw-bold">PTS 100,00</span>
            </div>
          </div>
        </div>

        <button className='Receipt__Download link-primary'>
          <DownloadIcon className='me-2'/>
          Descargar
        </button>
      </section>
    </>
  )
}

export default Receipt