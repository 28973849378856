import { memo, SVGProps } from 'react'

const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M.723.75h14.55a.73.73 0 0 1 .72.822.725.725 0 0 1-.165.371L10.185 8.6v5.926a.723.723 0 0 1-.695.724.73.73 0 0 1-.358-.076l-2.91-1.45a.727.727 0 0 1-.402-.648V8.598L.172 1.943A.724.724 0 0 1 .723.75Zm12.98 1.45H2.289l4.81 5.668c.113.13.176.296.177.469v4.292l1.455.725V8.337a.724.724 0 0 1 .176-.469L13.704 2.2Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(FilterIcon)