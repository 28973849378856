import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { getNotifications } from 'api/notifications'
import useAuthContext from 'hooks/useAuthContext'

const GeneralNotifications = () => {
  const { token } = useAuthContext()
  // const { data: notifications } = useQuery(
  //   ['getGeneralNotifications'],
  //   () => getNotifications(token),
  //   {
  //     initialData: [],
  //     enabled: token !== null,
  //     select: data => data?.data
  //   }
  // )

  const notifications = [
    {
      id: 1,
      title: 'Notificación 1',
      caption: 'Lorem Ipsum',
      date: '11:32',
    }, {
      id: 2,
      title: 'Notificación 2',
      caption: 'Lorem Ipsum',
      date: 'Ayer',
    }, {
      id: 3,
      title: 'Notificación 3',
      caption: 'Lorem Ipsum',
      date: '29/03',
    }, {
      id: 4,
      title: 'Notificación 4',
      caption: 'Lorem Ipsum',
      date: '27/03',
    }, {
      id: 5,
      title: 'Notificación 5',
      caption: 'Lorem Ipsum',
      date: '25/03',
    }, {
      id: 6,
      title: 'Notificación 6',
      caption: 'Lorem Ipsum',
      date: '22/03',
    }, {
      id: 7,
      title: 'Notificación 7',
      caption: 'Lorem Ipsum',
      date: '18/03',
    }, {
      id: 8,
      title: 'Notificación 8',
      caption: 'Lorem Ipsum',
      date: '16/03',
    }, {
      id: 9,
      title: 'Notificación 9',
      caption: 'Lorem Ipsum',
      date: '15/03',
    }, {
      id: 10,
      title: 'Notificación 10',
      caption: 'Lorem Ipsum',
      date: '12/03',
    }, {
      id: 11,
      title: 'Notificación 11',
      caption: 'Lorem Ipsum',
      date: '09/03',
    }, {
      id: 12,
      title: 'Notificación 12',
      caption: 'Lorem Ipsum',
      date: '07/03',
    }, {
      id: 13,
      title: 'Notificación 13',
      caption: 'Lorem Ipsum',
      date: '05/03',
    }, {
      id: 14,
      title: 'Notificación 14',
      caption: 'Lorem Ipsum',
      date: '03/03',
    }, {
      id: 15,
      title: 'Notificación 15',
      caption: 'Lorem Ipsum',
      date: '01/03',
    }, {
      id: 16,
      title: 'Notificación 16',
      caption: 'Lorem Ipsum',
      date: '28/02',
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Notificaciones Generales</title>
      </Helmet>

      <section className='GeneralNotifications app-background'>
        <div className="GeneralNotifications__Container card">
          <h1 className='text-2 fw-semibold'>Notificaciones</h1>

          <div className="GeneralNotifications__Items">
            {notifications.map((notification) => (
              <div
                key={`GeneralNotifications-Item-${notification.id}`}
                className='GeneralNotifications__Item'
              >
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="GeneralNotifications__Item-Image"
                  // src=''
                  // alt={notification.title}
                  // title={notification.title}
                  />

                  <div className="d-flex flex-column gap-1">
                    <h4 className='text-3 fw-medium'>{ notification.title }</h4>
                    <span className="text-4 text-gray-dark">{ notification.caption }</span>
                  </div>
                </div>

                <span className="text-3 text-gray-dark">{ notification.date }</span>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default GeneralNotifications