import { Route, Routes } from 'react-router'
// Layouts
import Layout from 'layouts/Layout'
// Transport app pages
import Home from 'pages/services/transporte-cordoba/Home'
import MyCard from 'pages/services/transporte-cordoba/MyCard'
import SuccessfulCard from 'pages/services/transporte-cordoba/SuccessfulCard'
import ErrorCard from 'pages/services/transporte-cordoba/ErrorCard'
import AddCard from 'pages/services/transporte-cordoba/AddCard'
// 404 error page
import NotFound from 'pages/error/NotFound'


const TransportAppRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path="" element={<Home />} />

        {/* Add card flow */}
        <Route path="my-card" element={<MyCard />} />
        <Route path="successful-card" element={<SuccessfulCard />} />
        <Route path="error-card" element={<ErrorCard />} />
        <Route path="add-card" element={<AddCard />} />
      </Route>

      {/* 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default TransportAppRouter