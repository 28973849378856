import { useEffect, useRef, useState, memo } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import ArrowDownIcon from '../assets/icons/ArrowDownIcon'
import CrossIcon from '../assets/icons/CrossIcon'

interface Props {
  name: string;
  type?: string;
  placeholder?: string;
  mode?: 'text' | 'decimal' | 'numeric' | 'tel' | 'email' | 'url';
  value?: number | null;
  search: {
    value: number;
    label: string;
  }[];
  onChange?: (value: string) => void;
  error?: string | any;
  reset?: boolean | string;
  required?: boolean;
  disabled?: boolean;
  disabledPlaceholder?: string;
}

const selectorVariants = {
  open: { height: 'auto', borderWidth: '1px' },
  closed: { height: 0, borderWidth: 0 },
}

const errorVariants = {
  open: { height: '18px', opacity: 1 },
  closed: { height: 0, opacity: 0 },
}

const SearchInput = ({
  name,
  type = 'text',
  placeholder,
  mode = 'text',
  value,
  search,
  onChange,
  error,
  reset = false,
  required = false,
  disabled = false,
  disabledPlaceholder = 'No data',
}: Props) => {
  const [firstCharge, setFirstCharge] = useState<boolean>(true)
  const [labelSearched, setLabelSearched] = useState<string>('')
  const [searchList, setSearchList] =
    useState<{ value: number; label: string }[]>(search)
  const [showList, setShowList] = useState<boolean>(false)
  const inputRef = useRef(null)

  // Filter search list
  useEffect(() => {
    if (search) {
      if (labelSearched) {
        const filteredList = search.filter((item) =>
          item.label.toLowerCase().includes(labelSearched.toLowerCase())
        )
        const sortList = filteredList.sort((a, b) =>
          a.label.localeCompare(b.label)
        )

        setSearchList(sortList || [])

        // if(searchList?.length == 0) {
        //   onChange('')
        // }
      } else {
        setSearchList(search)
      }
    }
  }, [labelSearched, search])

  // Set default value
  useEffect(() => {
    if (typeof value === 'number' && searchList?.length > 0 && firstCharge) {
      const item = searchList.find((item) => item.value == value)
      item && setLabelSearched(item.label)
      setFirstCharge(false)
    }
  }, [value, searchList])

  useEffect(() => {
    if(value === null) {
      setLabelSearched('')
    }
  }, [value])

  // Handle reset
  useEffect(() => {
    if (reset) {
      setShowList(false)
      setLabelSearched('')
      onChange('')
    }
  }, [reset])

  // Hide list when click outside
  const handleBlur = (e) => {
    // const isMobile = window.matchMedia('(max-width: 768px)').matches
    const needClose = inputRef?.current?.contains(e.relatedTarget) ? false : true

    if(needClose) {
      setTimeout(() => {
        setShowList(false)
      }, 150)
    }
  }

  const selectSearchValue = (item) => {
    setShowList(false)
    setLabelSearched(`${item.label}`)
    onChange(item.value)
  }

  return (
    <div className='w-100 d-flex flex-column align-items-start position-relative'>
      <div
        className="w-100 position-relative"
        onBlur={handleBlur}
        tabIndex={0}
        ref={inputRef}
      >
        <input
          className={classNames('SearchInput text-6', {
            'SearchInput--Disabled': disabled,
          })}
          type={type}
          name={name}
          inputMode={mode}
          placeholder={
            disabled
              ? disabledPlaceholder
              : placeholder
                ? placeholder
                : undefined
          }
          title={
            disabled
              ? disabledPlaceholder
              : placeholder
                ? placeholder
                : undefined
          }
          id={name}
          value={labelSearched}
          onChange={(e) => {
            setLabelSearched(e.target.value)
          }}
          onFocus={() => setShowList(true)}
          required={required}
          disabled={disabled}
          readOnly={disabled}
          autoComplete="nope"
        />

        {searchList?.length > 0 && !labelSearched && !disabled ? (
          <button
            className={
              showList
                ? 'SearchInput__List-Button--Active'
                : 'SearchInput__List-Button'
            }
            onMouseDown={() => setShowList(!showList)}
          >
            <ArrowDownIcon/>
          </button>
        ) : (
          !disabled && (
            <button
              className="SearchInput__List-Button"
              onMouseDown={() => {
                setLabelSearched('')
                onChange('')
              }}
            >
              <CrossIcon/>
            </button>
          )
        )}

        {searchList?.length > 0 && (
          <motion.div
            className="SearchInput__List-Container"
            animate={showList ? 'open' : 'closed'}
            initial={selectorVariants.closed}
            transition={{ duration: 0.2 }}
            variants={selectorVariants}
          >
            <div className="SearchInput__List">
              {searchList.map((item, index) => (
                <span
                  key={`SearchInput__List-${item}-${index}`}
                  className={
                    item.label === labelSearched
                      ? 'SearchInput__List-Item--Active'
                      : 'SearchInput__List-Item'
                  }
                  onClick={() => selectSearchValue(item)}
                  tabIndex={0}
                >
                  {item.label}
                </span>
              ))}
            </div>
          </motion.div>
        )}
      </div>

      <motion.div
        className="Input__Error text-4"
        initial={errorVariants.closed}
        animate={error ? 'open' : 'closed'}
        transition={{ duration: 0.1, ease: 'linear' }}
        style={{ transformStyle: 'preserve-3d' }}
        variants={errorVariants}
        dangerouslySetInnerHTML={{ __html: error }}
      >
      </motion.div>
    </div>
  )
}

export default memo(SearchInput)
