import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import AuthHeader from 'containers/AuthHeader'
import Button from 'components/Button'
import SuccessModal from 'components/modals/SuccessModal'
import ErrorModal from 'components/modals/ErrorModal'
import UserImage from 'assets/images/user.png'
import AddContactIcon from 'assets/icons/AddContactIcon'
import UserIcon from 'assets/icons/UserIcon'
import EmailIcon from 'assets/icons/EmailIcon'

const AddContactDetail = () => {
  const [ showSuccessModal, setShowSuccessModal ] = useState(false)
  const [ showErrorModal, setShowErrorModal ] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation()

  console.log(state)

  const handleGoToContactBook = () => {
    setShowSuccessModal(false)
    setShowErrorModal(false)
    navigate('/menu/contact-book', { replace: true })
  }

  return (
    <>
      <Helmet>
        <title>Qaixa: Agregar Contacto</title>
      </Helmet>

      <AuthHeader title='Añadir Contacto'/>

      <section className="AddContactDetail Menu__Content app-background">
        <div className="AddContactDetail__Container card">
          <div className="AddContactDetail__Icon">
            <AddContactIcon/>
            <span className="text-4 fw-medium">Añadir contacto</span>
          </div>

          <div className="AddContactDetail__Content">
            <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center gap-3">
              <h1 className='h5 fw-semibold'>
                Vas a añadir a tu lista<br/>
                de contactos a:
              </h1>

              <img
                src={state?.contact?.profile_image ? `${process.env.REACT_APP_BACK_URL}${state?.contact?.profile_image}` : UserImage}
                alt="Contacto"
                className="AddContactDetail__Image"
              />

              <div className="w-100 d-flex flex-column justify-content-center align-items-center gap-1">
                <div className="AddContactDetail__Field">
                  <UserIcon/>
                  <span className="text-2 fw-medium">{ state?.contact?.name || 'Nombre' }</span>
                </div>
                <div className="AddContactDetail__Field">
                  <EmailIcon/>
                  <span className="text-2 fw-medium">{ state?.contact?.email || 'Email' }</span>
                </div>
              </div>
            </div>

            <Button
              type='secondary'
              isSubmit
              onClick={() => setShowSuccessModal(true)}
            >
              Añadir
            </Button>
          </div>
        </div>
      </section>

      {showSuccessModal && (
        <SuccessModal
          closeModal={() => setShowSuccessModal(false)}
          message={`¡Haz añadido a ${state?.contact?.name}!`}
          buttonLabel='Volver a la agenda'
          handleButtonClick={handleGoToContactBook}
        />
      )}

      {showErrorModal && (
        <ErrorModal
          closeModal={() => setShowErrorModal(false)}
          message='Algo anduvo mal, inténtalo de nuevo más tarde'
          buttonLabel='Volver a la agenda'
          handleButtonClick={handleGoToContactBook}
        />
      )}
    </>
  )
}

export default AddContactDetail