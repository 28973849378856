import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { validateDocument } from 'api/auth'
import useAuthContext from 'hooks/useAuthContext'
import CameraButton from 'components/CameraButton'
import CameraErrorModal from 'components/modals/CameraErrorModal'
import CameraLoaderModal from 'components/modals/CameraLoaderModal'
import RegisterSuccess from 'containers/RegisterSuccess'

const ValidateDocument = () => {
  const { token } = useAuthContext()
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [ step, setStep ] = useState<number>(1)
  const [ mediaStream, setMediaStream ] = useState<MediaStream>(null)
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ error, setError ] = useState<boolean>(false)
  const [ responseState, setResponseState ] = useState<boolean>(false)

  // Set up the video stream
  useEffect(() => {
    const video = videoRef.current
    const canvas = canvasRef.current

    if(video && canvas) handleGetVideo()

    return () => handleCloseCamera()
  }, [videoRef, canvasRef])

  const handleGetVideo = async () => {
    const video = videoRef.current
    const canvas = canvasRef.current

    try {
      const stream = await navigator.mediaDevices?.getUserMedia({
        video: {
          facingMode: 'environment',
        },
        audio: false
      })

      setMediaStream(stream)

      video.srcObject = stream
      video.play()

      canvas.height = canvas.width * 0.628
    } catch (error) {
      setError(true)
    }
  }

  const handleCloseCamera = () => {
    // Get media stream track
    const track = mediaStream?.getVideoTracks()[0]

    if(track) {
      // Stop the video track and release the camera
      track.stop()
      track.enabled = false

    }

    if(mediaStream) {
      // Close the video stream
      mediaStream.getTracks().forEach(track => {
        track.stop()
      })
    }
  }

  const handleCaptureImage = async () => {
    const video = videoRef.current
    const canvas = canvasRef.current

    const context = canvas.getContext('2d')
    const videoWidth = video.videoWidth
    const videoHeight = video.videoHeight

    const sourceX = (videoWidth / 2) - (canvas.clientWidth / 2)
    const sourceY = (videoHeight / 2) - (canvas.clientHeight / 2)

    context.drawImage(
      video,
      sourceX,
      sourceY,
      canvas.clientWidth,
      canvas.clientHeight,
      0,
      0,
      canvas.width,
      canvas.height,
    )

    const dataURI = canvas.toDataURL('image/png')

    // Transform base64 to File
    const base64Data = dataURI.split(',')[1]
    const bytes = atob(base64Data)
    const byteArray = new Uint8Array(bytes.length)

    for (let i = 0; i < bytes.length; i++) {
      byteArray[i] = bytes.charCodeAt(i)
    }

    const file = new File([byteArray], 'imagen.png', { type: 'image/png' })

    handleSendDocument(file, context)
  }

  const handleSendDocument = async (image, context) => {
    setLoading(true)
    const canvas = canvasRef.current

    const response = await validateDocument(
      token,
      {
        document_image: image,
      }
    )

    // console.log(response)

    if(response?.message?.includes('Document validated successfully')) {
      setTimeout(() => {
        context.clearRect(0, 0, canvas.width, canvas.height)
        setLoading(false)
        handleCloseCamera()
        setResponseState(true)
      }, 1500)
    }

    // setTimeout(() => {
    //   context.clearRect(0, 0, canvas.width, canvas.height)

    // }, 3500)

    // if(step == 1) {
    //   setTimeout(() => {
    //     context.clearRect(0, 0, canvas.width, canvas.height)
    //     setLoading(false)
    //     setStep(2)
    //   }, 3500)
    // } else {
    //   setTimeout(() => {
    //     context.clearRect(0, 0, canvas.width, canvas.height)
    //     setLoading(false)
    //     navigate('/register/name', {
    //       replace: true
    //     })
    //   }, 3500)
    // }
  }

  return (
    <>
      <Helmet>
        <title>Qaixa: Validar Documento</title>
      </Helmet>

      <section className="ValidateDocument">
        <video className='ValidateDocument__Video' ref={videoRef}/>

        <div className="ValidateDocument__Canvas-Container">
          {step === 1 ? (
            <span className="ValidateDocument__Title h5 fw-medium">
            Toma una foto del frente de tu DNI
            </span>
          ) : (
            <span className="ValidateDocument__Title h5 fw-medium">
            Toma una foto del dorso de tu DNI
            </span>
          )}
          <canvas ref={canvasRef} className='ValidateDocument__Canvas'/>
        </div>

        {loading && (
          <CameraLoaderModal/>
        )}

        {error && (
          <CameraErrorModal/>
        )}

        <div className="ValidateDocument__Button-Container">
          {mediaStream && (
            <CameraButton handleClick={handleCaptureImage}/>
          )}
        </div>
      </section>

      <RegisterSuccess
        show={responseState}
        message='Excelente'
        nextStep='/register/pin'
      >
        <span className="text-2 fw-medium">
          Hemos escaneado tu DNI con éxito
        </span>
      </RegisterSuccess>
    </>
  )
}

export default ValidateDocument