import { Dispatch } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import StoreImage from '../assets/images/default-store-dropdown.png'
import ArrowDownIcon from '../assets/icons/ArrowDownIcon'

interface Props {
  id: number;
  name: string;
  image: string;
  categoryDetail: {
    id: number | null;
    show: boolean;
  };
  setCategoryDetail: Dispatch<any>;
}

const arrowVariants = {
  open: { rotateX: 180 },
  closed: { rotateX: 0 }
}

const StoreCategory = ({ id, name, image, categoryDetail, setCategoryDetail }: Props) => {

  const handleClick = () => {
    if(categoryDetail.id == id) {
      if(categoryDetail.show) {
        setCategoryDetail({
          id: id,
          show: false
        })
      } else {
        setCategoryDetail({
          id,
          show: true
        })
      }
    } else {
      setCategoryDetail({
        id,
        show: true
      })
    }
  }

  return (
    <button className="StoreCategory" onClick={handleClick} title="Show Category">
      <div className={classNames(
        'StoreCategory__Image-Container',
        { 'StoreCategory__Image-Container--Active': categoryDetail.show && categoryDetail.id == id }
      )}>
        <img
          src={image ? `${process.env.REACT_APP_BACK_URL}${image}` : StoreImage}
          alt={name}
          title={name}
        />
      </div>

      <div className="StoreCategory__Text">
        <span className="text-4 fw-semibold">{ name }</span>
        <motion.div
          animate={categoryDetail.show && categoryDetail.id == id ? 'open' : 'closed'}
          initial={arrowVariants.closed}
          variants={arrowVariants}
        >
          <ArrowDownIcon className='StoreCategory__Icon'/>
        </motion.div>
      </div>
    </button>
  )
}

export default StoreCategory