import { Helmet } from 'react-helmet'
import { normalizeColor, normalizeValue } from 'utils/normalizers'
import DownloadIcon from 'assets/icons/DownloadIcon'
import AddContactIcon from 'assets/icons/AddContactIcon'
import ArrowTokensIcon from 'assets/icons/ArrowTokensIcon'
import SearchIcon from 'assets/icons/SearchIcon'
import FilterIcon from 'assets/icons/FilterIcon'

const ServiceMovements = () => {

  const activities = [
    {
      id: 1,
      type: 'request-points',
      user: 'Agustin Pedano',
      points: 250
    }, {
      id: 2,
      type: 'request-points',
      user: 'Agustin Pedano',
      points: 250
    }, {
      id: 3,
      type: 'add-contact',
      user: 'Agustin Pedano',
    }, {
      id: 4,
      type: 'add-contact',
      user: 'Agustin Pedano',
    }, {
      id: 5,
      type: 'add-contact',
      user: 'Agustin Pedano',
    }
  ]

  const movements = [
    {
      id: 1,
      title: 'Pollería/Pollajería',
      amount: -100,
    }, {
      id: 2,
      title: 'Ferretería',
      amount: +100,
    }, {
      id: 3,
      title: 'RED bus',
      amount: 0,
    }, {
      id: 4,
      title: 'Pollería/Pollajería',
      amount: -100,
    }, {
      id: 5,
      title: 'Ferretería',
      amount: +100,
    }, {
      id: 6,
      title: 'RED bus',
      amount: 0,
    }, {
      id: 7,
      title: 'Pollería/Pollajería',
      amount: -100,
    }, {
      id: 8,
      title: 'Ferretería',
      amount: +100,
    }, {
      id: 9,
      title: 'RED bus',
      amount: 0,
    }, {
      id: 10,
      title: 'Pollería/Pollajería',
      amount: -100,
    }, {
      id: 11,
      title: 'Ferretería',
      amount: +100,
    }, {
      id: 12,
      title: 'RED bus',
      amount: 0,
    }, {
      id: 13,
      title: 'Pollería/Pollajería',
      amount: -100,
    }, {
      id: 14,
      title: 'Ferretería',
      amount: +100,
    }, {
      id: 15,
      title: 'RED bus',
      amount: 0,
    }, {
      id: 16,
      title: 'Pollería/Pollajería',
      amount: -100,
    }, {
      id: 17,
      title: 'Ferretería',
      amount: +100,
    }
  ]

  return (
    <>
      <Helmet>
        <title>Qaixa: Movimientos</title>
      </Helmet>

      <section className="ServiceMovements ">
        <div className="ServiceMovements__Activity-Container text-white">
          <h2 className='text-1 fw-semibold'>Actividad</h2>

          {activities.length ? (
            <div className="ServiceMovements__Activity-Slider">
              {activities.map(({ id, type, user, points }) => {
                const icon = type == 'add-contact' ? <AddContactIcon/> : <ArrowTokensIcon/>

                return (
                  <button
                    key={`Service-Movements-Activity-${id}`}
                    className="ServiceMovements__Activity-Item card text-3"
                  >
                    { icon }
                    {type == 'add-contact' ? (
                      <span className="text-app">Haz añadido a <span className='text-primary'>{ user }</span> a tu agenda</span>
                    ) : (
                      <div className='d-flex flex-column'>
                        <span className="text-app"><span className='text-primary'>{ user }</span> te solicito { points } PTS</span>
                        <span className="text-2 fw-medium text-app">VER</span>
                      </div>
                    )}
                  </button>
                )})}
            </div>
          ) : (
            <div className="card py-4">
              <span className="text-2 text-center fw-medium">No tienes actividades recientes</span>
            </div>
          )}
        </div>

        {/* <Activity value='Mar 2023'/> */}

        <div className="ServiceMovements__Container app-background">
          <div className="card">
            <h2 className='text-1 fw-semibold'>Historial</h2>

            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="ServiceStores__Search-Container">
                <SearchIcon/>
                <input
                  className='ServiceStores__Search text-4'
                  type="text"
                  placeholder='Buscar'
                />
              </div>

              <button className='ServiceStores__Button'>
                <span className="text-4 fw-medium">Filtrar</span>
                <FilterIcon/>
              </button>
            </div>

            <div className="ServiceMovements__Items">
              {movements.map(item => (
                <div
                  key={`Service-Movements-Item-${item.id}`}
                  className="ServiceMovements__Item"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="ServiceMovements__Item-Image"></div>
                    <div className="d-flex flex-column">
                      <h4 className={`text-2 fw-medium ${normalizeColor(item.amount)}`}>{ normalizeValue(item.amount) }</h4>
                      <span className="text-4 fw-medium">{ item.title }</span>
                      <span className="text-5 fw-medium text-gray">ID #######{ item.id }</span>
                    </div>
                  </div>

                  <button className='ServiceMovements__Item-Button'>
                    <DownloadIcon/>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServiceMovements