import { getQuery, postQuery } from './apiFunctions'
import { CreateTicketBody } from './types'

export const getTickets = async (token: string) => {
  return await getQuery<any>({
    token,
    path: '/transport-app/claims/',
    callback: (data) => data.issues,
  })
}

export const createTicket = async (token: string, body: CreateTicketBody) => {
  return await postQuery<any>({
    token,
    path: '/transport-app/claims/',
    body,
  })
}