import { memo, SVGProps } from 'react'

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M2.4 16A2.404 2.404 0 0 1 0 13.6v-3.2a.8.8 0 0 1 1.6 0v3.2a.8.8 0 0 0 .8.8h11.2a.8.8 0 0 0 .8-.8v-3.2a.8.8 0 1 1 1.6 0v3.2a2.4 2.4 0 0 1-2.4 2.4H2.4Zm5.56-4.8h-.015a.07.07 0 0 1-.024 0 .053.053 0 0 1-.02 0 .064.064 0 0 1-.019 0h-.039c-.006 0-.012 0-.016-.005s-.015 0-.022-.005a.026.026 0 0 0-.012 0c-.01 0-.017-.007-.027-.01a.02.02 0 0 1-.01 0 .805.805 0 0 1-.32-.195l-4-4A.8.8 0 0 1 4.57 5.854l2.633 2.633V.8a.8.8 0 0 1 1.6 0v7.666l2.634-2.633a.8.8 0 0 1 1.13 1.133l-4 4a.801.801 0 0 1-.287.185h-.005a.182.182 0 0 1-.033.01.01.01 0 0 1-.004 0c-.01 0-.02.007-.027.01h-.012c-.008 0-.017 0-.025.004l-.014.005h-.038a.073.073 0 0 1-.022 0 .053.053 0 0 1-.02 0 .068.068 0 0 1-.02 0h-.018a.182.182 0 0 1-.039 0 .189.189 0 0 1-.043.02Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(DownloadIcon)
